import { gql } from '@apollo/client';

import {
  AutoTopUpAccordionFormBillingProfileData,
  AUTO_TOP_UP_ACCORDION_FORM_BILLING_PROFILE_FRAGMENT,
} from './AutoTopUpAccordionForm/fragments';
import {
  AutoTopUpSwitchBillingProfileData,
  AUTO_TOP_UP_SWITCH_BILLING_PROFILE_FRAGMENT,
} from './AutoTopUpSwitch/fragments';

export type WalletAutoTopupBillingProfileData = AutoTopUpAccordionFormBillingProfileData &
  AutoTopUpSwitchBillingProfileData;

export const WALLET_AUTO_TOPUP_BILLING_PROFILE_FRAGMENT = gql`
  fragment walletAutoTopUpAccordionBillingProfileFragment on BillingProfileNode {
    ...autoTopUpSwitchBillingProfileFragment
    ...autoTopUpAccordionFormBillingProfileFragment
  }
  ${AUTO_TOP_UP_SWITCH_BILLING_PROFILE_FRAGMENT}
  ${AUTO_TOP_UP_ACCORDION_FORM_BILLING_PROFILE_FRAGMENT}
`;
