const enUSMessages: Record<string, string> = {
  'error-loading-page.text':
    "Uh-oh, it seems that we aren't able to load this section. We're sorry, can you please try again?",
  'error-loading-page.button': 'Try again',
  'shared.something-went-wrong-message': 'Ooops!{br}Something went wrong!',
  'shared.something-went-wrong-description':
    'We couldn’t find that link on the app.{br}Please check for any typos or go back to the campaign’s dashboard.',
};

export default enUSMessages;
