import { APIPermission } from 'typeDeclarations/enums';
import { makeVar, InMemoryCache, InMemoryCacheConfig, PossibleTypesMap } from '@apollo/client/cache';
import { cacheRedirect, orderedNonNormalizedObjectList } from './cacheUtils';
import {
  CAMPAIGNS_DEFAULT_ORDER,
  CAMPAIGNS_DEFAULT_LAYOUT,
  CAMPAIGNS_DEFAULT_SEARCH_VALUE,
  CAMPAIGNS_DEFAULT_ADVERTISER_ID,
  CAMPAIGNS_DEFAULT_FILTER_VALUES,
  CAMPAIGNS_DEFAULT_LABEL_VALUES,
  CAMPAIGNS_TABLE_DEFAULT_ROWS_PER_PAGE,
} from 'pages/AdvertisersAndCampaigns/Campaigns/constants';
import { CampaignsFilterAndSortOptions } from 'pages/AdvertisersAndCampaigns/Campaigns/types';
import {
  ADVERTISERS_DEFAULT_ORDER,
  ADVERTISERS_DEFAULT_LAYOUT,
  ADVERTISERS_DEFAULT_SEARCH_VALUE,
  ADVERTISERS_TABLE_DEFAULT_ROWS_PER_PAGE,
} from 'pages/AdvertisersAndCampaigns/Advertisers/constants';
import { AdvertiserFilterAndSortOptions } from 'pages/AdvertisersAndCampaigns/Advertisers/types';
import possibleTypes from './possibleTypes.json';
import { AppSettingsMap } from 'utils/Settings/types';
import { concatPagination, relayStylePagination } from '@apollo/client/utilities';
import { ThemeName } from 'appConfig';

/*
 **********************
 * Reactive Variables *
 **********************
 */
export const darkThemeVar = makeVar<boolean>(false);
export const partnerThemeVar = makeVar<ThemeName>('default');
export const permissionsVar = makeVar<PartialRecord<APIPermission, boolean>>({});

export const advertiserFilterAndSortOptionsVar = makeVar<AdvertiserFilterAndSortOptions>({
  layout: ADVERTISERS_DEFAULT_LAYOUT,
  sortValue: ADVERTISERS_DEFAULT_ORDER,
  labelValues: CAMPAIGNS_DEFAULT_LABEL_VALUES,
  searchInput: ADVERTISERS_DEFAULT_SEARCH_VALUE,
  table: {
    rowsPerPage: ADVERTISERS_TABLE_DEFAULT_ROWS_PER_PAGE,
  },
});

export const campaignFilterAndSortOptionsVar = makeVar<CampaignsFilterAndSortOptions>({
  layout: CAMPAIGNS_DEFAULT_LAYOUT,
  sortValue: CAMPAIGNS_DEFAULT_ORDER,
  labelValues: CAMPAIGNS_DEFAULT_LABEL_VALUES,
  searchInput: CAMPAIGNS_DEFAULT_SEARCH_VALUE,
  advertiserId: CAMPAIGNS_DEFAULT_ADVERTISER_ID,
  filterValues: CAMPAIGNS_DEFAULT_FILTER_VALUES,
  table: {
    rowsPerPage: CAMPAIGNS_TABLE_DEFAULT_ROWS_PER_PAGE,
  },
});

export const settingsVar = makeVar<AppSettingsMap>({});

/*
 ***********************
 * Apollo Cache Config *
 ***********************
 */

export const cacheConfig: InMemoryCacheConfig = {
  possibleTypes: possibleTypes as PossibleTypesMap,
  typePolicies: {
    Mutation: {
      fields: {
        suggestAdFields: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        searchUnsplashImages: concatPagination(),
        darkTheme: {
          read() {
            return darkThemeVar();
          },
        },
        partnerTheme: {
          read() {
            return partnerThemeVar();
          },
        },
        advertiserFiltersAndSortsOptions: {
          read() {
            return advertiserFilterAndSortOptionsVar();
          },
        },
        campaignFilterAndSortOptions: {
          read() {
            return campaignFilterAndSortOptionsVar();
          },
        },
        permissions: {
          read() {
            return permissionsVar();
          },
        },
        budget: cacheRedirect(['BudgetNode']),
        adPool: cacheRedirect(['AdPoolNode']),
        bingAd: cacheRedirect(['BingAdNode']),
        campaign: cacheRedirect(['CampaignNode']),
        advertiser: cacheRedirect(['AdvertiserNode']),
        facebookAd: cacheRedirect(['FacebookAdNode']),
        instagramAd: cacheRedirect(['InstagramAdNode']),
        googleTextAd: cacheRedirect(['GoogleTextAdNode']),
        targetingGroup: cacheRedirect(['TargetingGroupNode']),
        imageExtension: cacheRedirect(['ImageExtensionNode']),
        sitelinkExtension: cacheRedirect(['SitelinkExtensionNode']),
        locationExtension: cacheRedirect(['LocationExtensionNode']),
        facebookCarouselAd: cacheRedirect(['FacebookCarouselAdNode']),
        googleResponsiveAd: cacheRedirect(['GoogleResponsiveAdNode']),
        instagramCarouselAd: cacheRedirect(['InstagramCarouselAdNode']),
        googleDynamicSearchAd: cacheRedirect(['GoogleDynamicSearchAdNode']),
        bingResponsiveSearchAd: cacheRedirect(['BingResponsiveSearchAdNode']),
        areaOfInterestSelection: cacheRedirect(['AreaOfInterestSelectionNode']),
        googleResponsiveSearchAd: cacheRedirect(['GoogleResponsiveSearchAdNode']),
        areaOfInterestInSelection: cacheRedirect(['AreaOfInterestInSelectionNode']),
        structuredSnippetExtension: cacheRedirect(['StructuredSnippetExtensionNode']),
        estimatedCostPerGoalUsingUrl: cacheRedirect(['EstimatedCostPerGoalUsingUrlNode']),
        targetingLocation: cacheRedirect(['GeocoderTargetingLocationNode', 'RadiusLocationNode']),
        googleTextAdRestrictions: {
          merge: true,
        },
        googleResponsiveAdRestrictions: {
          merge: true,
        },
        bingResponsiveSearchAdRestrictions: {
          merge: true,
        },
        bingResponsiveSearchAdHeadlineRestrictions: {
          merge: true,
        },
        bingResponsiveSearchAdDescriptionRestrictions: {
          merge: true,
        },
        googleResponsiveSearchAdRestrictions: {
          merge: true,
        },
        googleResponsiveSearchAdHeadlineRestrictions: {
          merge: true,
        },
        googleResponsiveSearchAdDescriptionRestrictions: {
          merge: true,
        },
        googleDynamicSearchAdRestrictions: {
          merge: true,
        },
        facebookAdRecommendations: {
          merge: true,
        },
        facebookAdRestrictions: {
          merge: true,
        },
        facebookCarouselAdRecommendations: {
          merge: true,
        },
        facebookCarouselAdRestrictions: {
          merge: true,
        },
        instagramAdRecommendations: {
          merge: true,
        },
        instagramAdRestrictions: {
          merge: true,
        },
        bingAdRestrictions: {
          merge: true,
        },
        settings: {
          merge: true,
        },
        budgetAmountLimits: {
          merge: true,
        },
        sitelinkRestrictions: {
          merge: true,
        },
        imageExtensionRestrictions: {
          merge: true,
        },
        adPoolRestrictions: {
          merge: true,
        },
      },
    },
    BillingProfileNode: {
      fields: {
        billingProfileMovements: relayStylePagination(['first']),
        otherStripeCardPaymentSources: relayStylePagination(['first']),
        autoTopupThresholdAmountLimits: {
          merge: true,
        },
        topupAmountLimits: {
          merge: true,
        },
      },
    },
    AdvertiserNode: {
      fields: {
        labels: relayStylePagination(['first']),
        imageExtensions: relayStylePagination(['first']),
        calloutExtensions: relayStylePagination(['first']),
        locationExtensions: relayStylePagination(['first']),
        sitelinkExtensions: relayStylePagination(['first']),
        structuredSnippetExtensions: relayStylePagination(['first']),
        budgets: relayStylePagination(['first', 'isShared', 'isDraft', 'canBeUsedByCampaignId']),
        campaigns: relayStylePagination([
          'first',
          'search',
          'orderBy',
          'labelTexts',
          'canChangeBudget',
          'hasSharedBudget',
          'advertisingPlatformState__in',
        ]),
        imageFiles: relayStylePagination(['first', 'widthMin', 'heightMin', 'aspectRatioMin', 'aspectRatioMax']),
        draftIssues: {
          merge: false,
        },
      },
    },
    BudgetNode: {
      fields: {
        suggestedBudgetAmounts: orderedNonNormalizedObjectList(),
        scheduledBudgetConfigurations: relayStylePagination(['first', 'isPresent', 'isPast', 'isFuture']),
        campaigns: relayStylePagination([
          'first',
          'search',
          'orderBy',
          'labelTexts',
          'canChangeBudget',
          'hasSharedBudget',
          'advertisingPlatformState__in',
        ]),
        draftIssues: {
          merge: false,
        },
      },
    },
    AdPoolNode: {
      fields: {
        ads: relayStylePagination(['first']),
        canAutoGenerateAdsAndKeywordsIssues: {
          merge: false,
        },
      },
    },
    KeywordGroupNode: {
      fields: {
        keywordRelations: relayStylePagination(['first']),
        negativeKeywords: relayStylePagination(['first']),
      },
    },
    CampaignNode: {
      fields: {
        labels: relayStylePagination(['first']),
        adPools: relayStylePagination(['first']),
        imageExtensions: relayStylePagination(['first']),
        calloutExtensions: relayStylePagination(['first']),
        targetingLocations: relayStylePagination(['first']),
        sitelinkExtensions: relayStylePagination(['first']),
        locationExtensions: relayStylePagination(['first']),
        negativeGeocoderLocations: relayStylePagination(['first']),
        allLegacyNegativeKeywords: relayStylePagination(['first']),
        negativeKeywords: relayStylePagination(['first']),
        allNegativeKeywords: relayStylePagination(['first']),
        keywordRelations: relayStylePagination(['first', 'hasAdPool']),
        targetingGroups: relayStylePagination(['first', 'hasAdPool']),
        ads: relayStylePagination(['first', 'advertisingPlatform', 'subtype', 'hasAdPool']),
        performanceBudgetLimits: {
          merge: true,
        },
        canAutoGenerateAdPoolsIssues: {
          merge: false,
        },
        canAutoGenerateTargetingGroupsIssues: {
          merge: false,
        },
        canAutoGenerateTargetingLocationsWithLocationScrapeIssues: {
          merge: false,
        },
        draftIssues: {
          merge: false,
        },
        aggregateCampaignReports: {
          merge: true,
        },
        imageFiles: relayStylePagination(['first', 'widthMin', 'heightMin', 'aspectRatioMin', 'aspectRatioMax']),
        campaignReports: orderedNonNormalizedObjectList([
          'end',
          'start',
          'reportPeriod',
          'reportAggregations',
          'reportTimeAggregation',
        ]),
        adPoolKeywordReports: orderedNonNormalizedObjectList([
          'end',
          'start',
          'reportAggregations',
          'reportTimeAggregation',
        ]),
        searchTermReports: orderedNonNormalizedObjectList([
          'end',
          'start',
          'reportAggregations',
          'reportTimeAggregation',
        ]),
        adReports: orderedNonNormalizedObjectList(['end', 'start', 'reportAggregations', 'reportTimeAggregation']),
        perNetworkAdPoolReports: orderedNonNormalizedObjectList([
          'end',
          'start',
          'reportAggregations',
          'reportTimeAggregation',
        ]),
      },
    },
    AdInterface: {
      fields: {
        draftIssues: {
          merge: false,
        },
      },
    },
    FacebookCarouselAdCardInterface: {
      fields: {
        draftIssues: {
          merge: false,
        },
      },
    },
    TeamNode: {
      fields: {
        nonOwnerUsers: relayStylePagination(['first']),
        budgets: relayStylePagination(['first', 'search', 'id__in']),
        advertisers: relayStylePagination(['first', 'labelTexts', 'search', 'orderBy']),
        estimatedCostPerGoalUsingUrlResults: relayStylePagination(['first', 'search']),
        labels: relayStylePagination(['first', 'usedByCampaigns', 'usedByAdvertisers']),
        campaigns: relayStylePagination([
          'first',
          'search',
          'orderBy',
          'labelTexts',
          'hasTrackerInstalled',
          'goalRequiresTracking',
          'advertisingPlatformState__in',
        ]),
        teamReports: orderedNonNormalizedObjectList([
          'reportTimeAggregation',
          'reportAggregations',
          'start',
          'end',
          'goal__in',
        ]),
        budgetFulfilledTeamReports: orderedNonNormalizedObjectList(['reportTimeAggregation', 'start', 'end']),
        numActiveCampaignsReports: orderedNonNormalizedObjectList(['reportTimeAggregation', 'start', 'end']),
        budgetFulfilledBudgetReportDistributions: orderedNonNormalizedObjectList([
          'start',
          'end',
          'budgetFulfillmentIntervalSeparators',
        ]),
      },
    },
    TargetingGroupNode: {
      fields: {
        targetingLocations: relayStylePagination(['first']),
        areaOfInterestSelections: relayStylePagination(['first']),
        negativeGeocoderLocations: relayStylePagination(['first']),
      },
    },
    AreaOfInterestInSelectionNode: {
      fields: {
        childColumns: relayStylePagination(['first']),
      },
    },
    UserNode: {
      fields: {
        imageFiles: relayStylePagination(['first']),
        teams: relayStylePagination(['first', 'search']),
      },
    },
  },
};

/*
 ****************
 * Apollo Cache *
 ****************
 */
export const cache = new InMemoryCache(cacheConfig);
