import { useApolloClient, gql, ApolloError } from '@apollo/client';

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';

import { useDefaultOnError } from './useDefaultOnError';

/**
 * Fragment and type created for the optimization purpose during the login process
 */

export const SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY_FRAGMENT = gql`
  fragment shouldShowFirstBusinessPromptQueryFragment on Query {
    sessionUser {
      id
      modified
      ddtId
    }
    sessionTeam {
      id
      modified
      shouldShowFirstBusinessPrompt
    }
  }
`;

export interface ShouldShowFirstBusinessPromptQueryFragmentData {
  sessionUser: Pick<UserNode, 'id' | 'modified' | 'ddtId'>;
  sessionTeam: Pick<TeamNode, 'id' | 'modified' | 'shouldShowFirstBusinessPrompt'>;
}

const SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY = gql`
  query shouldShowFirstBusinessPromptQuery {
    ...shouldShowFirstBusinessPromptQueryFragment
  }
  ${SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY_FRAGMENT}
`;

type ShouldShowFirstBusinessPromptQueryData = ShouldShowFirstBusinessPromptQueryFragmentData;

interface UseShouldShowFirstBusinessPromptReturnValue {
  loading: boolean;
  error?: Error;
}

export function useShouldShowFirstBusinessPrompt(): UseShouldShowFirstBusinessPromptReturnValue {
  const history = useHistory();
  const client = useApolloClient();
  const onError = useDefaultOnError();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    client
      .query<ShouldShowFirstBusinessPromptQueryData>({
        query: SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY,
      })
      .then(({ data, errors }) => {
        if (errors) throw new ApolloError({ graphQLErrors: errors });

        const { sessionUser, sessionTeam } = data;

        if (!sessionUser.ddtId && sessionTeam.shouldShowFirstBusinessPrompt) {
          history.replace('/first-business');
        }
      })
      .catch((err: Error) => {
        onError(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [client, history, onError]);

  return { loading, error };
}
