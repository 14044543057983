import { isUndefined } from 'typeDeclarations/typeGuards';
import { DraftIssueFragmentData } from 'graphql/fragments';

type Filter = 'blocking' | 'nonBlocking';

/**
 * Returns a dictionary containing the field names of draft issues segregated
 * by the impact on the action of publishing a campaign (preventPublishIfIsPerformance).
 * @param draftIssues A list of draft issues.
 * @returns Record<Filter, string[]>
 */
export function getDraftIssueFieldNamesDict(draftIssues: DraftIssueFragmentData[]): Record<Filter, string[]> {
  const fieldNamesDictionary: Record<Filter, string[]> = {
    blocking: [],
    nonBlocking: [],
  };

  draftIssues.forEach(({ fieldNames, preventPublishIfIsPerformance }) => {
    fieldNames.forEach((fieldName) => {
      const filter: Filter = preventPublishIfIsPerformance ? 'blocking' : 'nonBlocking';

      fieldNamesDictionary[filter].push(fieldName);
    });
  });

  return fieldNamesDictionary;
}

interface FlattenDraftIssuesFieldNamesArgs {
  filter?: Filter;
  draftIssues: DraftIssueFragmentData[];
}

/**
 * Returns a list containing the field names of a list of draft issues.
 * @param draftIssues A list of draft issues.
 * @param onlyPerformanceInhibitingIssues if true, only consider draft issues that would prevent
 * a performance campaign from being published
 */
export function flattenDraftIssuesFieldNames(args: FlattenDraftIssuesFieldNamesArgs): string[] {
  const { draftIssues, filter } = args;

  const fieldNamesDict = getDraftIssueFieldNamesDict(draftIssues);

  if (filter) {
    return fieldNamesDict[filter];
  }

  return Object.values(fieldNamesDict).flat();
}

interface HasDraftIssuesArgs {
  draftIssues: DraftIssueFragmentData[];
  onlyPerformanceInhibitingIssues?: boolean;
}

/**
 * Returns `true` if it finds any draft issue
 * @param draftIssues A list of draft issues.
 * @param onlyPerformanceInhibitingIssues if true, only consider draft issues that would prevent a
 * performance campaign from being published
 */
export function hasDraftIssues(args: HasDraftIssuesArgs): boolean {
  const { draftIssues, onlyPerformanceInhibitingIssues } = args;

  if (isUndefined(onlyPerformanceInhibitingIssues)) {
    return draftIssues.length > 0;
  }

  if (onlyPerformanceInhibitingIssues) {
    return draftIssues.some((issue) => issue.preventPublishIfIsPerformance);
  }

  return draftIssues.some((issue) => !issue.preventPublishIfIsPerformance);
}
