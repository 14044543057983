import { gql } from '@apollo/client';

import { PAGE_INFO_FRAGMENT } from 'graphql/fragments';

import { WALLET_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT } from './WalletPaymentSource/fragments';

export const WALLET_PAYMENT_SOURCES_STRIPE_PAYMENT_SOURCES_CONNECTION_FRAGMENT = gql`
  fragment walletPaymentSourcesStripePaymentSourcesConnectionFragment on StripePaymentSourceNodeConnection {
    edges {
      cursor
      node {
        ...walletPaymentSourceStripePaymentSourceFragment
      }
    }
    pageInfo {
      ...pageInfoFragment
    }
  }
  ${WALLET_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
