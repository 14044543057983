import { shouldPolyfill as shouldPolyfillIntlNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { SupportedLocale } from 'i18n/constants';

// TODO: Default polyfill to DEFAULT_LOCALE, like we do in other places.
// TODO: Use dynamic imports, instead of a switch-case.
export async function polyfillLocale(locale: SupportedLocale): Promise<void> {
  /**
   * Intl.getCanonicalLocales isn't supported by some known browsers
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/getCanonicalLocales}
   * This functionality is also required by Intl.ListFormat and Intl.NumberFormat
   */
  if (shouldPolyfillIntlGetCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }

  /**
   * This polyfill requires Intl.getCanonicalLocales
   * {@link https://formatjs.io/docs/polyfills/intl-locale/}
   */
  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }

  /**
   * This polyfill requires both Intl.getCanonicalLocales and Intl.Locale,
   * {@link https://formatjs.io/docs/polyfills/intl-pluralrules/}
   */
  if (shouldPolyfillIntlPluralRules(locale)) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-pluralrules/polyfill');

    switch (locale) {
      case 'en-US':
        await import('@formatjs/intl-pluralrules/locale-data/en');
        break;
      case 'fr-BE':
        await import('@formatjs/intl-pluralrules/locale-data/fr');
        break;
      case 'nl-NL':
        await import('@formatjs/intl-pluralrules/locale-data/nl');
        break;
      case 'pt-PT':
        await import('@formatjs/intl-pluralrules/locale-data/pt-PT');
        break;
      case 'es-ES':
        await import('@formatjs/intl-pluralrules/locale-data/es');
        break;
      case 'de-DE':
        await import('@formatjs/intl-pluralrules/locale-data/de');
        break;
      default:
        // if we reach this, there's no guarantee that ${locale} is of type 'string'.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unsupported locale: ${locale as unknown}`);
    }
  }

  /**
   * This polyfill requires Intl.getCanonicalLocales, Intl.Locale and Intl.PluralRules
   * Intl.NumberFormat isn't fully supported by safari
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat}
   */
  if (shouldPolyfillIntlNumberFormat(locale)) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-numberformat/polyfill');

    switch (locale) {
      case 'en-US':
        await import('@formatjs/intl-numberformat/locale-data/en');
        break;
      case 'fr-BE':
        await import('@formatjs/intl-numberformat/locale-data/fr-BE');
        break;
      case 'nl-NL':
        await import('@formatjs/intl-numberformat/locale-data/nl');
        break;
      case 'pt-PT':
        await import('@formatjs/intl-numberformat/locale-data/pt-PT');
        break;
      case 'es-ES':
        await import('@formatjs/intl-numberformat/locale-data/es');
        break;
      case 'de-DE':
        await import('@formatjs/intl-numberformat/locale-data/de');
        break;
      default:
        // if we reach this, there's no guarantee that ${locale} is of type 'string'.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unsupported locale: ${locale as unknown}`);
    }
  }

  /**
   * This polyfill requires Intl.getCanonicalLocales, Intl.Locale and Intl.PluralRules. Also needs Intl.NumberFormat
   * if using formatToParts.
   * @see {@link https://formatjs.io/docs/polyfills/intl-relativetimeformat}
   */
  if (shouldPolyfillIntlRelativeTimeFormat(locale)) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-relativetimeformat/polyfill');

    switch (locale) {
      case 'en-US':
        await import('@formatjs/intl-relativetimeformat/locale-data/en');
        break;
      case 'fr-BE':
        await import('@formatjs/intl-relativetimeformat/locale-data/fr-BE');
        break;
      case 'nl-NL':
        await import('@formatjs/intl-relativetimeformat/locale-data/nl');
        break;
      case 'pt-PT':
        await import('@formatjs/intl-relativetimeformat/locale-data/pt-PT');
        break;
      case 'es-ES':
        await import('@formatjs/intl-relativetimeformat/locale-data/es');
        break;
      case 'de-DE':
        await import('@formatjs/intl-relativetimeformat/locale-data/de');
        break;
      default:
        // if we reach this, there's no guarantee that ${locale} is of type 'string'.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unsupported locale: ${locale}`);
    }
  }
}
