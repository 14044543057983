import { ElementType } from 'react';
import { isUndefined } from 'typeDeclarations/typeGuards';

import {
  Tooltip,
  SvgIcon,
  SvgIconTypeMap,
  IconButton,
  IconButtonTypeMap,
  TooltipProps,
  SvgIconProps,
  IconButtonProps,
} from '@mui/material';

type TooltipProsType = Omit<TooltipProps, 'title' | 'children'> & {
  title?: TooltipProps['title'];
};

export type DefaultSVGIconProps<
  C extends ElementType = SvgIconTypeMap['defaultComponent'],
  IB extends ElementType = IconButtonTypeMap['defaultComponent'],
> = SvgIconProps<C, { component?: C }> & {
  tooltipProps?: TooltipProsType;
  iconButtonProps?: IconButtonProps<IB, { component?: IB }>;
};

export function DefaultSVGIcon<
  C extends ElementType = SvgIconTypeMap['defaultComponent'],
  IB extends ElementType = IconButtonTypeMap['defaultComponent'],
>({ tooltipProps, iconButtonProps, ...rest }: DefaultSVGIconProps<C, IB>) {
  if (!tooltipProps || isUndefined(tooltipProps.title)) {
    return <SvgIcon {...rest} />;
  }

  return (
    <Tooltip placement="top" {...tooltipProps} title={tooltipProps.title}>
      <IconButton size="small" {...iconButtonProps}>
        <SvgIcon fontSize="inherit" {...rest} />
      </IconButton>
    </Tooltip>
  );
}
