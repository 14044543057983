import { FunctionComponent } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { PAGE_INFO_FRAGMENT } from 'graphql/fragments';
import { gql, NetworkStatus, useQuery } from '@apollo/client';

import { FiberManualRecordRounded as FiberManualRecordRoundedIcon } from '@mui/icons-material';

import { Connection } from 'typeDeclarations/graphql/base-types';
import { UserNode, TeamNode } from 'typeDeclarations/graphql/nodes';
import { LoadingBlock } from 'shared/LoadingBlock';
import { ErrorMessage } from 'shared/ErrorMessage';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import { FetchMoreWrapper } from 'shared/FetchMoreWrapper';
import { hashNumberFromString, teamsColorPalette } from '../utils';

import { Box, MenuItem, MenuList, ListItemText, ListItemIcon } from '@mui/material';

const NUM_INITIAL_TEAMS = 10;

interface TeamsListQueryData {
  sessionUser: Pick<UserNode, 'id' | 'modified'> & {
    teams: Connection<Pick<TeamNode, '__typename' | '_id' | 'id' | 'modified' | 'name'>>;
  };
}

interface TeamsListQueryVariables {
  teamsAmount: number;
  after: string | null;
  search: string | null;
}

interface TeamsListProps {
  search: string | null;
  onTeamClick: (teamId: string) => void;
}

const TEAMS_SUBMENU_QUERY = gql`
  query TeamsSubmenuUserTeamsQuery($teamsAmount: Int!, $search: String, $after: String) {
    sessionUser {
      id
      modified
      teams(after: $after, orderBy: "name", search: $search, first: $teamsAmount) {
        edges {
          cursor
          node {
            id
            _id
            name
            modified
          }
        }
        pageInfo {
          ...pageInfoFragment
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const TeamsList: FunctionComponent<TeamsListProps> = ({ search, onTeamClick }) => {
  const { formatMessage } = useExtendedIntl();
  const { data, error, loading, variables, fetchMore, networkStatus } = useQuery<
    TeamsListQueryData,
    TeamsListQueryVariables
  >(TEAMS_SUBMENU_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      after: null,
      teamsAmount: NUM_INITIAL_TEAMS,
    },
  });

  if (loading && networkStatus !== NetworkStatus.fetchMore) {
    return (
      <Box
        sx={{
          pt: '1rem',
          minHeight: '5rem',
        }}
      >
        <LoadingBlock />
      </Box>
    );
  }

  if (error || !data) {
    return <ErrorMessage />;
  }

  const {
    edges: teamEdges,
    pageInfo: { endCursor, hasNextPage: hasMoreTeams },
  } = data.sessionUser.teams;

  if (!teamEdges.length) {
    return null;
  }

  const fetchMoreTeams = () => {
    return fetchMore({
      variables: {
        ...variables,
        after: endCursor,
      },
    });
  };

  return (
    <MenuList
      disablePadding
      sx={{
        overflowY: 'auto',
        minHeight: '10rem',
        maxHeight: '27rem',
        '> li > div > svg': {
          transform: 'scale(0.5)',
        },
      }}
    >
      {teamEdges.map(({ node: { id, _id, name } }) => (
        <MenuItem
          key={id}
          onClick={(e) => {
            e.preventDefault();
            onTeamClick(id);
          }}
        >
          <ListItemIcon>
            <FiberManualRecordRoundedIcon
              htmlColor={teamsColorPalette[hashNumberFromString(_id) % teamsColorPalette.length]}
            />
          </ListItemIcon>
          <ListItemText primary={name} primaryTypographyProps={{ noWrap: true }} />
        </MenuItem>
      ))}
      {hasMoreTeams && (
        <FetchMoreWrapper>
          <ProgressButton variant="text" onClick={fetchMoreTeams} loading={networkStatus === NetworkStatus.fetchMore}>
            {formatMessage({ id: 'shared.load-more' })}
          </ProgressButton>
        </FetchMoreWrapper>
      )}
    </MenuList>
  );
};
