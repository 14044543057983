import { gql, useQuery, QueryHookOptions, QueryResult } from '@apollo/client';
import { config } from 'appConfig';
import { SessionContextQueryFragmentData, SESSION_CONTEXT_QUERY_FRAGMENT } from 'components/App/session/SessionContext';
import {
  NavigationMenuHeaderQueryFragmentData,
  NAVIGATION_MENU_HEADER_QUERY_FRAGMENT,
} from 'components/Header/HeaderMenuToggle/HeaderMenuContent/NavigationMenu/NavigationMenuHeader/fragments';
import {
  NUM_INITIAL_TEAMS,
  TeamsMenuQueryFragmentData,
  TEAMS_MENU_QUERY_FRAGMENT,
} from 'components/Header/HeaderMenuToggle/HeaderMenuContent/TeamsMenu/TeamsMenu';
import {
  ReferralAnnouncementQueryFragmentData,
  REFERRAL_ANNOUNCEMENT_QUERY_FRAGMENT,
} from 'shared/ReferralAnnouncement';
import { UserTeamAvatarQueryFragmentData, USER_TEAM_AVATAR_QUERY_FRAGMENT } from 'shared/UserTeamAvatar';
import { FrontendSettingConnectionFragmentData, FRONTEND_SETTING_CONNECTION_FRAGMENT } from 'graphql/fragments';
import { AppSessionLocaleQueryFragmentData, APP_SESSION_LOCALE_QUERY_FRAGMENT } from 'hooks/useAppSessionLocale';
import {
  RedirectUserThemePartnerQueryFragmentData,
  REDIRECT_USER_THEME_PARTNER_QUERY_FRAGMENT,
} from 'hooks/useRedirectUserThemePartner';
import {
  ShouldShowFirstBusinessPromptQueryFragmentData,
  SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY_FRAGMENT,
} from 'hooks/useShouldShowFirstBusinessPrompt';
import { UserUtilsBootQueryFragmentData, USER_UTILS_BOOT_QUERY_FRAGMENT } from 'hooks/useUtilsBoot';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { APIPermission } from 'typeDeclarations/enums';
import { PermissionNode, UserTeamsNode } from 'typeDeclarations/graphql/nodes';
import { MAX_SETTINGS } from 'utils/Settings/constants';
import { UserTrackingQueryFragmentData, USER_TRACKING_QUERY_FRAGMENT } from 'userTracking/useUserTracking';

/**
 * Query, fragment and respective types created for the optimization purpose
 * during the login process
 * WIP
 * In the future we might want to fetch more "simple" data from the session user and team such as:
 * 1) billing profile info (much needed on the wallet and billing and payments section)
 * 2) user info needed e.g on the account settings page
 */

export type SessionQueryFragmentData = TeamsMenuQueryFragmentData &
  UserTrackingQueryFragmentData &
  UserUtilsBootQueryFragmentData &
  UserTeamAvatarQueryFragmentData &
  SessionContextQueryFragmentData &
  AppSessionLocaleQueryFragmentData &
  NavigationMenuHeaderQueryFragmentData &
  ReferralAnnouncementQueryFragmentData &
  RedirectUserThemePartnerQueryFragmentData &
  ShouldShowFirstBusinessPromptQueryFragmentData & {
    isLoggedIn: boolean;
    getContextPermissions: Array<Pick<PermissionNode, 'id' | '_id'>>;
    sessionUserTeamRelation:
      | null
      | (Pick<UserTeamsNode, 'id'> & {
          frontendSettings: FrontendSettingConnectionFragmentData;
        });
  };

export const SESSION_QUERY_FRAGMENT = gql`
  fragment sessionQueryFragment on Query {
    ...teamsMenuQueryFragment
    ...userTrackingQueryFragment
    ...userUtilsBootQueryFragment
    ...userTeamAvatarQueryFragment
    ...sessionContextQueryFragment
    ...appSessionLocaleQueryFragment
    ...referralAnnouncementQueryFragment
    ...navigationMenuHeaderQueryFragment
    ...redirectUserThemePartnerQueryFragment
    ...shouldShowFirstBusinessPromptQueryFragment
    isLoggedIn
    getContextPermissions(permissions: $permissions) {
      id
      _id
    }
    sessionUserTeamRelation {
      id
      frontendSettings(first: ${MAX_SETTINGS}, frontend: $frontend) {
        ...frontendSettingConnectionFragment
      }
    }
  }
  ${TEAMS_MENU_QUERY_FRAGMENT}
  ${USER_TRACKING_QUERY_FRAGMENT}
  ${USER_UTILS_BOOT_QUERY_FRAGMENT}
  ${SESSION_CONTEXT_QUERY_FRAGMENT}
  ${USER_TEAM_AVATAR_QUERY_FRAGMENT}
  ${APP_SESSION_LOCALE_QUERY_FRAGMENT}
  ${FRONTEND_SETTING_CONNECTION_FRAGMENT}
  ${REFERRAL_ANNOUNCEMENT_QUERY_FRAGMENT}
  ${NAVIGATION_MENU_HEADER_QUERY_FRAGMENT}
  ${REDIRECT_USER_THEME_PARTNER_QUERY_FRAGMENT}
  ${SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY_FRAGMENT}
`;

export interface SessionQueryVariables {
  frontend: string;
  teamsAmount: number;
  permissions: APIPermission[];
}

type SessionQueryData = SessionQueryFragmentData;

const SESSION_QUERY = gql`
  query sessionQuery($teamsAmount: Int!, $frontend: String!, $permissions: [String!]!) {
    ...sessionQueryFragment
  }
  ${SESSION_QUERY_FRAGMENT}
`;

export function useSessionQuery(
  options?: QueryHookOptions<SessionQueryData, SessionQueryVariables>,
): QueryResult<SessionQueryFragmentData, SessionQueryVariables> {
  const onError = useDefaultOnError();

  const {
    ViewAds,
    ViewBudgets,
    ViewKeywords,
    ViewAdIssues,
    PerformChanges,
    PublishCampaigns,
    ViewTargetingGroups,
    RequestFacebookPageApproval,
    EstimatedCostPerGoalWithUrl,
  } = APIPermission;

  return useQuery<SessionQueryData, SessionQueryVariables>(SESSION_QUERY, {
    ...options,
    onError,
    variables: {
      frontend: config.name,
      teamsAmount: NUM_INITIAL_TEAMS,
      permissions: [
        ViewAds,
        ViewBudgets,
        ViewKeywords,
        ViewAdIssues,
        PerformChanges,
        PublishCampaigns,
        ViewTargetingGroups,
        RequestFacebookPageApproval,
        EstimatedCostPerGoalWithUrl,
      ],
    },
  });
}
