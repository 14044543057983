import { SeverityLevel, captureException, withScope } from '@sentry/react';
import { config } from 'appConfig';

export interface ScopeOptions {
  extras?: Record<string, unknown>;
  tags?: Record<string, string>;
  level?: SeverityLevel;
  fingerprint?: string[];
}

/**
 * default error logging handler.
 * handles logs to the console and to the remote logging service
 * @param error error instance to log
 * @param scopeOptions if provided, will setup a new scope with these options
 */
export function logError(error: Error, scopeOptions?: ScopeOptions): void {
  if (config.isLocalhost) {
    console.error(error);
    return;
  }

  if (!scopeOptions || !Object.keys(scopeOptions).length) {
    captureException(error);
    return;
  }

  withScope(({ setTags, setExtras, setLevel, setFingerprint }) => {
    const { extras, level, tags, fingerprint } = scopeOptions;

    if (extras) {
      setExtras(extras);
    }

    if (level) {
      setLevel(level);
    }

    if (tags) {
      setTags(tags);
    }

    if (fingerprint?.length) {
      setFingerprint(fingerprint);
    }

    captureException(error);
  });
}
