/**
 * ## Global Formik Dirty Awareness
 * #### Use this to prompt users if they wanna leave Dialog with Formik ####
 * On a Formik component, call for `FormikState.setDirty(dirty)`
 *
 * You can then call `FormikState.dirty` from wherever you want
 * to know what's the dirty state of the Formik.
 *
 * @example
 * FormikState.setDirty(true) // In the Formik Form you invoked it to change global dirty state
 *
 * FormikState.dirty // In a Dialog component you call for dirty property to get the actual state
 */
export const GlobalFormikState = {
  /** Boolean - Formik dirty state */
  dirty: false,
  /** Function to change the FormikState dirty property */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setDirty(d: boolean) {
    this.dirty = d;
  },
};
