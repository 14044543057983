import { FunctionComponent } from 'react';
import { Error as ErrorIcon, Warning as WarningIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { DefaultSVGIcon, DefaultSVGIconProps } from 'shared/DefaultSVGIcon';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { StepStatus } from './CampaignContext/CampaignContext';
import { SvgIconProps } from '@mui/material';

const AGGREGATE_STATUS_TO_ICON_COLOR: Record<
  AggregateStepStatus,
  {
    color: 'error' | 'warning' | 'success';
    icon: FunctionComponent<SvgIconProps>;
  }
> = {
  error: { color: 'error', icon: ErrorIcon },
  warning: { color: 'warning', icon: WarningIcon },
  success: { color: 'success', icon: CheckCircleIcon },
};

export type AggregateStepStatus = 'error' | 'warning' | 'success';

export function getAggregateStepStatus({
  touched,
  completed,
  hasBlockingIssues,
  hasNonBlockingIssues,
}: StepStatus): null | AggregateStepStatus {
  let aggregateStatus: null | AggregateStepStatus = null;

  if (completed) {
    if (hasBlockingIssues) {
      aggregateStatus = 'error';
    } else if (hasNonBlockingIssues) {
      aggregateStatus = 'warning';
    } else {
      aggregateStatus = 'success';
    }
  } else if (touched) {
    if (hasBlockingIssues) {
      aggregateStatus = 'error';
    } else if (hasNonBlockingIssues || !completed) {
      aggregateStatus = 'warning';
    }
  }

  return aggregateStatus;
}

export interface StepStatusIconProps extends Omit<DefaultSVGIconProps, 'iconButtonProps'> {
  aggregateStatus: AggregateStepStatus;
}

export const StepStatusIcon: FunctionComponent<StepStatusIconProps> = ({ aggregateStatus, ...rest }) => {
  const { formatMessage } = useExtendedIntl();
  const { color, icon } = AGGREGATE_STATUS_TO_ICON_COLOR[aggregateStatus];

  return (
    <DefaultSVGIcon
      {...rest}
      color={color}
      component={icon}
      iconButtonProps={{ color, size: 'medium' }}
      tooltipProps={{
        title: formatMessage({ id: `campaign-step-status.error.description-${color}` }),
        ...rest.tooltipProps,
      }}
    />
  );
};
