import { createBreakpoints } from '@mui/system';

import createTypography, { Typography, TypographyOptions } from '@mui/material/styles/createTypography';
import createPalette from '@mui/material/styles/createPalette';
import type { Components, PaletteMode, ThemeOptions, PaletteOptions } from '@mui/material';

import { deepmerge } from '@mui/utils';
import { config, ThemeName, themeNames } from 'appConfig';

import { Breakpoints, Palette, Theme, alpha, createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material';

const BASE_FONTS: string[] = [
  '-apple-system',
  'BlinkMacSystemFont',
  "'Segoe UI'",
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  "'Helvetica Neue'",
  'sans-serif',
];

const THEME_TO_FONT_FAMILY_MAPPING: Record<string, string> = {
  [themeNames.default]: ['Arial', ...BASE_FONTS].join(','),
  [themeNames.fcr]: ['Lato', ...BASE_FONTS].join(','),
  [themeNames.youvia]: ['Krub', ...BASE_FONTS].join(','),
  [themeNames.thryv]: ['Arial', ...BASE_FONTS].join(','),
  [themeNames['yp-ca']]: ['Arial', ...BASE_FONTS].join(','),
  [themeNames.mediamag]: ['Arial', ...BASE_FONTS].join(','),
  [themeNames.directel]: ['Roboto', ...BASE_FONTS].join(','),
  [themeNames.puxenegc]: ['Ubuntu', ...BASE_FONTS].join(','),
  [themeNames.bizay]: ['Montserrat', ...BASE_FONTS].join(','),
  [themeNames.inovcorp]: ['TT Commons', ...BASE_FONTS].join(','),
  [themeNames.paginasAmarelas]: ['Roboto', ...BASE_FONTS].join(','),
  [themeNames['publicar-estimation']]: ['Arial', ...BASE_FONTS].join(','),
  [themeNames.fonecta]: ['Arial', ...BASE_FONTS].join(','),
  [themeNames.newmed21]: ['Ubuntu', ...BASE_FONTS].join(','),
};

// README: DO NOT USE THIS OBJECT DIRECTLY. FEED THIS TO THE `createPalette` FACTORY FUNCTION
// AND THEN USE THE RETURNED PALETTE OBJECT
const THEME_TO_PALETTE_OPTIONS_MAPPING: Record<string, PaletteOptions> = {
  [themeNames.default]: {
    primary: {
      main: '#6423CC',
      light: '#834FD6',
      dark: '#46188E',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#6423CC',
      light: '#834FD6',
      dark: '#46188E',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
    },
    warning: {
      main: '#FF9800',
      dark: '#C77700',
      light: '#FFB547',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
    },
    action: {
      hoverOpacity: 0.04,
      hover: alpha('#6800CB', 0.04),
    },
    dashboard: {
      firstColor: '#695EDE',
      secondColor: '#36A1AF',
      thirdColor: '#3ABD9F',
      fourthColor: '#9CBBFF',
    },
  },
  [themeNames.paginasAmarelas]: {
    primary: {
      main: '#685BC7',
      light: '#B2AAD4',
      dark: '#221E42',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#685BC7',
      light: '#B2AAD4',
      dark: '#221E42',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FAA61A',
    },
    error: {
      main: '#B00020',
      dark: '#E31B0C',
      light: '#F88078',
    },
    action: {
      hover: alpha('#685BC7', 0.04),
      hoverOpacity: 0.04,
    },
    dashboard: {
      firstColor: '#685BC7',
      secondColor: '#E1508C',
      thirdColor: '#ED874D',
      fourthColor: '#BAC75B',
    },
  },
  [themeNames.fcr]: {
    primary: {
      main: '#00ABC2',
      light: '#DDFBFF',
      dark: '#00899C',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#E86877',
      light: '#F3B3BB',
      dark: '#00899C',
    },
    text: {
      primary: '#003A42',
      secondary: '#39676C',
      disabled: '#808080',
    },
    action: {
      hover: alpha('#E86877', 0.04),
      hoverOpacity: 0.04,
    },
    dashboard: {
      firstColor: '#00ABC2',
      secondColor: '#5E9BE2',
      thirdColor: '#C17CC6',
      fourthColor: '#E86877',
    },
  },
  [themeNames.sitee]: {
    primary: {
      main: '#407BFF',
      light: '#2C56B2',
      dark: '#6695FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#407BFF',
      light: '#2C56B2',
      dark: '#6695FF',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#407BFF',
      secondColor: '#FF58C8',
      thirdColor: '#FF7867',
      fourthColor: '#FFC813',
    },
  },
  [themeNames.bizay]: {
    primary: {
      main: '#009CDF',
      light: '#006F9F',
      dark: '#3EC5FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#009CDF',
      light: '#006F9F',
      dark: '#3EC5FF',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#009CDF',
      secondColor: '#40B2A8',
      thirdColor: '#C17CC6',
      fourthColor: '#FFF100',
    },
  },
  [themeNames['yp-ca']]: {
    primary: {
      main: '#FFCD00',
      light: '#FFE680',
      dark: '#DBB000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#000000',
      light: '#808080',
      dark: '#DBB000',
      contrastText: '#FFFFFF',
    },
    action: {
      hover: alpha('#FFCD00', 0.1),
      hoverOpacity: 0.04,
    },
    dashboard: {
      firstColor: '#FFCD00',
      secondColor: '#FF8482',
      thirdColor: '#F386EE',
      fourthColor: '#00A2FF',
    },
  },
  [themeNames.inovcorp]: {
    primary: {
      main: '#000',
      light: '#808080',
      dark: '#000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#212121',
      light: '#808080',
      dark: '#212121',
      contrastText: '#FFFFFF',
    },
    action: {
      hover: alpha('#000', 0.1),
      hoverOpacity: 0.04,
    },
    dashboard: {
      firstColor: '#36A1AF',
      secondColor: '#695EDE',
      thirdColor: '#3ABD9F',
      fourthColor: '#9CBBFF',
    },
  },
  [themeNames.directel]: {
    primary: {
      main: '#FFCD00',
      light: '#FFE680',
      dark: '#DBB000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#000000',
      light: '#808080',
      dark: '#DBB000',
      contrastText: '#FFFFFF',
    },
    action: {
      hover: alpha('#FFCD00', 0.1),
      hoverOpacity: 0.04,
    },
    dashboard: {
      firstColor: '#FFCD00',
      secondColor: '#FF8482',
      thirdColor: '#F386EE',
      fourthColor: '#00A2FF',
    },
  },
  [themeNames.youvia]: {
    primary: {
      main: '#131D3A',
      light: '#F7D346',
      dark: '#131D3A',
      contrastText: '#FEFCF8',
    },
    secondary: {
      main: '#F7D346',
      light: '#808080',
      dark: '#131D3A',
      contrastText: '#FEFCF8',
    },
    action: {
      hover: alpha('#131D3A', 0.1),
      hoverOpacity: 0.04,
    },
    dashboard: {
      firstColor: '#F7D346',
      secondColor: '#131D3A',
      thirdColor: '#F8DAD5',
      fourthColor: '#FEFCF8',
    },
  },
  [themeNames.thryv]: {
    primary: {
      main: '#FF5000',
      light: '#FF905E',
      dark: '#BB3B00',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF5000',
      light: '#FF905E',
      dark: '#BB3B00',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#695EDE',
      secondColor: '#36A1AF',
      thirdColor: '#3ABD9F',
      fourthColor: '#9CBBFF',
    },
  },
  [themeNames['publicar-estimation']]: {
    primary: {
      main: '#426DA4',
      light: '#779AC8',
      dark: '#2E4B6F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#426DA4',
      light: '#779AC8',
      dark: '#2E4B6F',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#695EDE',
      secondColor: '#36A1AF',
      thirdColor: '#3ABD9F',
      fourthColor: '#9CBBFF',
    },
  },
  [themeNames.mediamag]: {
    primary: {
      main: '#E9B83E',
      light: '#F6EDDE',
      dark: '#595447',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#E9B83E',
      light: '#F6EDDE',
      dark: '#595447',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#E9B83E',
      secondColor: '#595447',
      thirdColor: '#F6EDDE',
      fourthColor: '#7F95AC',
    },
  },
  [themeNames.fonecta]: {
    primary: {
      main: '#11158E',
      light: '#EEF6F2',
      dark: '#11158E',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#11158E',
      light: '#EEF6F2',
      dark: '#11158E',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#38C976',
      secondColor: '#FFE539',
      thirdColor: '#FFABE5',
      fourthColor: '#11158E',
    },
  },
  // New Media 21
  [themeNames.newmed21]: {
    primary: {
      main: '#124C4E',
      light: '#518889',
      dark: '#124C4E',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#124C4E',
      light: '#518889',
      dark: '#124C4E',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#124C4E',
      secondColor: '#518889',
      thirdColor: '#8E8D8D',
      fourthColor: '#BAB8B8',
    },
  },
  [themeNames.puxenegc]: {
    primary: {
      main: '#1260CC',
      light: '#EEF6F2',
      dark: '#11158E',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#695EDE',
      light: '#EEF6F2',
      dark: '#11158E',
      contrastText: '#FFFFFF',
    },
    dashboard: {
      firstColor: '#1260CC',
      secondColor: '#695EDE',
      thirdColor: '#3ABD9F',
      fourthColor: '#FFE539',
    },
  },
};

const TYPOGRAPHY_OPTIONS: TypographyOptions = {
  fontFamily: THEME_TO_FONT_FAMILY_MAPPING[config.theme],
  h4: {
    fontWeight: 700,
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.1,
  },
  subtitle2: {
    lineHeight: 1.5,
  },
  button: {
    textTransform: 'none',
    lineHeight: 1.714,
  },
  caption: {
    lineHeight: 1.17167,
  },
  overline: {
    lineHeight: 2.583,
  },
};

export function createThemeOptions(palette: Palette, typography: Typography, breakpoints: Breakpoints): ThemeOptions {
  const baseComponentsSettings: Components = {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        indeterminate: {
          color: palette.primary.main,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          width: '30vw',
          [breakpoints.down('lg')]: {
            width: '35vw',
          },
          [breakpoints.down('md')]: {
            width: '45vw',
          },
          [breakpoints.down('sm')]: {
            width: '65vw',
          },
          [breakpoints.down('xs')]: {
            width: '100vw',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$error': {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minHeight: 'max-content',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&.MuiTouchRipple-root': {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
        TransitionProps: {
          mountOnEnter: true,
        },
        PaperProps: {
          style: {
            minWidth: 175,
            boxShadow: '2px 8px 16px 0px rgba(24, 50, 115, 0.20)',
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          transformOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        },
      },
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
        label: {
          '&.MuiStepLabel-completed': {
            fontWeight: typography.fontWeightRegular,
            '&.MuiStepLabel-active': {
              color: palette.primary.main,
              fontWeight: typography.fontWeightMedium,
            },
          },
          '&.MuiStepLabel-active': {
            color: palette.primary.main,
            fontWeight: typography.fontWeightMedium,
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        switchBase: {
          color: palette.mode === 'light' ? '#FAFAFA' : undefined,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // same font size and weight as h6
          fontSize: typography.h6.fontSize,
          fontWeight: typography.h6.fontWeight,
          '&&.Mui-selected': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '4px',
          borderRadius: '8px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 5,
          marginLeft: 2,
          marginRight: 2,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: 'center',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        SelectProps: {
          MenuProps: {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            transformOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        },
      },
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 30px ${palette.background.paper} inset`,
            },
          },
        },
      },
    },
    // TODO: This shouldn't be set, as it overrides the text color for components such as Alerts.
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary',
      },
    },
  };

  const appThemeToComponentsSettingsMap: PartialRecord<ThemeName, Components> = {
    'yp-ca': {
      MuiButton: {
        styleOverrides: {
          containedSecondary: {
            color: palette.secondary.main,
            backgroundColor: palette.primary.main,
            '&.MuiTouchRipple-root': {
              color: palette.secondary.main,
            },
            '&:hover': {
              backgroundColor: palette.primary.main,
            },
          },
          outlinedSecondary: {
            border: `1px solid ${palette.primary.main}`,
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
            '&.MuiTouchRipple-root': {
              color: palette.primary.main,
            },
            '&:hover': {
              border: `1px solid ${palette.primary.main}`,
              backgroundColor: palette.action.hover,
            },
          },
          textSecondary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
            '&:hover': {
              backgroundColor:
                palette.mode === 'light' ? alpha(palette.secondary.main, 0.1) : alpha(palette.primary.main, 0.1),
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: palette.secondary.main,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            // WIP: not sure if this will work on root styles
            // colorPrimary and colorSecondary were deprecated in v5
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.MuiTouchRipple-root': {
              color: palette.primary.main,
            },
          },
          outlinedPrimary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          button: {
            '&.MuiTouchRipple-root': {
              color: palette.primary.main,
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          colorPrimary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
          colorSecondary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            color: palette.secondary.main,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            '&.MuiStepLabel-completed': {
              '&.MuiStepLabel-active': {
                color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
              },
            },
            '&.MuiStepLabel-active': {
              color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              '&.MuiStepIcon-text': {
                fill: palette.secondary.main,
              },
            },
          },
        },
      },
    },
    directel: {
      MuiButton: {
        styleOverrides: {
          containedSecondary: {
            color: palette.secondary.main,
            backgroundColor: palette.primary.main,
            '&.MuiTouchRipple-root': {
              color: palette.secondary.main,
            },
            '&:hover': {
              backgroundColor: palette.primary.main,
            },
          },
          outlinedSecondary: {
            border: `1px solid ${palette.primary.main}`,
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
            '&.MuiTouchRipple-root': {
              color: palette.primary.main,
            },
            '&:hover': {
              border: `1px solid ${palette.primary.main}`,
              backgroundColor: palette.action.hover,
            },
          },
          textSecondary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
            '&:hover': {
              backgroundColor:
                palette.mode === 'light' ? alpha(palette.secondary.main, 0.1) : alpha(palette.primary.main, 0.1),
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: palette.secondary.main,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            // WIP: not sure if this will work on root styles
            // colorPrimary and colorSecondary were deprecated in v5
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.MuiTouchRipple-root': {
              color: palette.primary.main,
            },
          },
          outlinedPrimary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          button: {
            '&.MuiTouchRipple-root': {
              color: palette.primary.main,
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          colorPrimary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
          colorSecondary: {
            color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            color: palette.secondary.main,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            '&.MuiStepLabel-completed': {
              '&.MuiStepLabel-active': {
                color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
              },
            },
            '&.MuiStepLabel-active': {
              color: palette.mode === 'light' ? palette.secondary.main : palette.primary.main,
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              '&.MuiStepIcon-text': {
                fill: palette.secondary.main,
              },
            },
          },
        },
      },
    },
    youvia: {
      MuiButton: {
        styleOverrides: {
          containedSecondary: {
            fontWeight: 700,
            color: palette.primary.main,
            backgroundColor: palette.secondary.main,
            '&.MuiTouchRipple-root': {
              color: palette.secondary.main,
            },
            '&:hover': {
              backgroundColor: palette.secondary.main,
            },
          },
          outlinedSecondary: {
            border: `1px solid ${palette.primary.main}`,
            color: palette.mode === 'light' ? palette.primary.main : palette.secondary.main,
            '&.MuiTouchRipple-root': {
              color: palette.primary.main,
            },
            '&:hover': {
              border: `1px solid ${palette.primary.main}`,
              backgroundColor: palette.action.hover,
            },
          },
          textSecondary: {
            color: palette.mode === 'light' ? palette.primary.main : palette.secondary.main,
            '&:hover': {
              backgroundColor:
                palette.mode === 'light' ? alpha(palette.primary.main, 0.1) : alpha(palette.secondary.main, 0.1),
            },
          },
        },
      },
    },
    mediamag: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: 'white',
          },
          root: {
            color: palette.text.primary,
            borderBottom: `1px solid ${palette.grey['200']}`,
          },
        },
      },
    },
    // New Media 21 overrides
    newmed21: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: 'white',
          },
          root: {
            color: palette.text.primary,
            borderBottom: `1px solid ${palette.grey['200']}`,
          },
        },
      },
    },
  };

  const componentCurrentThemeSettings = appThemeToComponentsSettingsMap[config.theme] ?? {};
  const components = deepmerge(baseComponentsSettings, componentCurrentThemeSettings);

  return {
    palette,
    breakpoints,
    typography,
    components,
  };
}

/**
 * Creates a MUI theme
 * @param paletteMode type PaletteMode = "light" | "dark"
 * @param additionalOptions any additional option when creating a Mui Theme
 * @param partnerTheme In case you want to override the default theme defined in the env vars
 * @returns {Theme}
 */
export function createTheme(
  paletteMode: PaletteMode,
  additionalOptions: object[] = [],
  partnerTheme: null | ThemeName = null,
): Theme {
  const palette = createPalette({
    mode: paletteMode,
    background: {
      default: '#F8F9FB',
    },
    ...THEME_TO_PALETTE_OPTIONS_MAPPING[partnerTheme ?? config.theme],
  });

  const typography = createTypography(palette, TYPOGRAPHY_OPTIONS);

  const breakpoints = createBreakpoints({
    values: {
      xs: 0,
      sm: 767,
      md: 1044,
      lg: 1280,
      xl: 1920,
    },
  });

  const themeOptions = createThemeOptions(palette, typography, breakpoints);
  const muiTheme = createMuiTheme(themeOptions, ...additionalOptions);
  const responsiveTypographyTheme = responsiveFontSizes(muiTheme);

  return responsiveTypographyTheme;
}
