import { isString } from 'typeDeclarations/typeGuards';

/* ************ */
/* Environments */
/* ************ */

export type BuildEnvironment = 'dev' | 'local' | 'prod' | 'release';

// The release environment is omitted for the app environment.
export type AppEnvironment = 'dev' | 'local' | 'prod';

const buildEnvironment: BuildEnvironment = (import.meta.env.REACT_APP_ENVIRONMENT || 'dev') as BuildEnvironment;
// Hack so that the app thinks the 'release' environment is production
const appEnvironment: AppEnvironment = buildEnvironment !== 'release' ? buildEnvironment : 'prod';

type EnvironmentString = 'true' | 'false';

const environmentStringToBooleanMap: Record<EnvironmentString, boolean> = {
  true: true,
  false: false,
};

export function isEnvironmentString(variable: string): variable is EnvironmentString {
  return variable in environmentStringToBooleanMap;
}

export function getEnvironmentBool(environmentVariable: unknown, defaultValue = false): boolean {
  if (!isString(environmentVariable)) {
    return defaultValue;
  }

  const lowercaseEnvironmentVariable = environmentVariable.toLowerCase();
  if (!isEnvironmentString(lowercaseEnvironmentVariable)) {
    return defaultValue;
  }

  return environmentStringToBooleanMap[lowercaseEnvironmentVariable];
}

/* ***************** */
/* Local development */
/* ***************** */

const isLocalhost = getEnvironmentBool(import.meta.env.REACT_APP_IS_LOCALHOST, true);

/* ******** */
/* API Keys */
/* ******** */

// TODO: .env files should be cleaned up, so we can start placing these API keys there,
//  instead of laying them around the code.

const STRIPE_PROD_KEY = 'pk_live_ZLgQkUhdkH2UjqTZ2I0rj1ah';
const STRIPE_DEV_KEY = 'pk_test_eJpsQOLQr3ONNnYJW1C60IA5';
const GOOGLE_MAPS_DEV_KEY = 'AIzaSyA9FTO-R2MHjQsR2KVnq7xO4Ptjh_ClDu8&libraries=places';
const GOOGLE_MAPS_PROD_KEY = 'AIzaSyAp81WhapBNr5Fi150jh7_JD5BJJZDStoA&libraries=places';
const GOOGLE_MAPS_LOCALHOST_KEY = 'AIzaSyBAbT8dl0QmMZSv3uM39_8P6PIv9_VU6Rk&libraries=places';
const SENTRY_DSN = 'https://2a5ebe1a1c6446289f270b286dfd757e@o382847.ingest.sentry.io/5212350';
//Facebook APP ID localhost and dev are the same for now
const FACEBOOK_APP_LOCALHOST_KEY = '184289095711299';
const FACEBOOK_APP_DEV_KEY = '184289095711299';
const FACEBOOK_APP_PROD_KEY = '201636377071028';
const INTERCOM_APP_ID = 't7gqvt2v';

const stripeKey = buildEnvironment === 'prod' && !isLocalhost ? STRIPE_PROD_KEY : STRIPE_DEV_KEY;

const googleMapsApiKey = isLocalhost
  ? GOOGLE_MAPS_LOCALHOST_KEY
  : buildEnvironment === 'prod'
  ? GOOGLE_MAPS_PROD_KEY
  : GOOGLE_MAPS_DEV_KEY;

const facebookAppID = isLocalhost
  ? FACEBOOK_APP_LOCALHOST_KEY
  : buildEnvironment === 'prod'
  ? FACEBOOK_APP_PROD_KEY
  : FACEBOOK_APP_DEV_KEY;

/* ********** */
/* App Themes */
/* ********** */

/**
 * Possible themes from the REACT_APP_THEME env var
 */
export type ThemeName =
  | 'default'
  | 'yp-ca'
  | 'sitee'
  | 'bizay'
  | 'thryv'
  | 'fcr-be'
  | 'youvia'
  | 'directel'
  | 'puxenegc'
  | 'inovcorp'
  | 'mediamag'
  | 'paginas-amarelas'
  | 'publicar-estimation'
  | 'fonecta'
  | 'newmed21';

export const themeNames: Record<string, ThemeName> = {
  default: 'default',
  fcr: 'fcr-be',
  bizay: 'bizay',
  sitee: 'sitee',
  thryv: 'thryv',
  youvia: 'youvia',
  'yp-ca': 'yp-ca',
  directel: 'directel',
  puxenegc: 'puxenegc',
  inovcorp: 'inovcorp',
  paginasAmarelas: 'paginas-amarelas',
  'publicar-estimation': 'publicar-estimation',
  mediamag: 'mediamag',
  fonecta: 'fonecta',
  newmed21: 'newmed21',
};

interface ThemeConfig {
  title: string;
  altLogo: boolean;
}

export const themeConfig: Record<ThemeName, ThemeConfig> = {
  default: {
    title: 'leadzai app - performance based advertising',
    altLogo: false,
  },
  'fcr-be': {
    title: 'Omni by FCR Media',
    altLogo: false,
  },
  bizay: {
    title: '360imprimir',
    altLogo: false,
  },
  sitee: {
    title: 'Sitee',
    altLogo: false,
  },
  thryv: {
    title: 'Thryv',
    altLogo: false,
  },
  youvia: {
    title: 'Youvia',
    altLogo: false,
  },
  'yp-ca': {
    title: 'Omni by Yellow Pages',
    altLogo: false,
  },
  directel: {
    title: 'Directel',
    altLogo: false,
  },
  puxenegc: {
    title: 'Puxe Negócios',
    altLogo: false,
  },
  inovcorp: {
    title: 'Inovcorp',
    altLogo: false,
  },
  'paginas-amarelas': {
    title: 'EasyAds - go-online.pt',
    altLogo: false,
  },
  'publicar-estimation': {
    title: 'Publicar',
    altLogo: false,
  },
  mediamag: {
    title: 'MediaMagneten',
    altLogo: false,
  },
  fonecta: {
    title: 'Fonecta',
    altLogo: true,
  },
  newmed21: {
    title: 'New Media 21',
    altLogo: false,
  },
};

// FIXME: Redundant code
const backendThemeNamesMap: PartialRecord<ThemeName, boolean> = {};

Object.values(themeNames).forEach((themeName) => {
  backendThemeNamesMap[themeName] = true;
});

// FIXME: Move this utility function out of appConfig.ts
export function isThemeName(themeName: unknown): themeName is ThemeName {
  return isString(themeName) && themeName in backendThemeNamesMap;
}

const reactAppTheme = import.meta.env.REACT_APP_THEME;
const theme: ThemeName = isThemeName(reactAppTheme) ? reactAppTheme : 'default';

// FIXME: Move this utility function out of appConfig.ts
export function isCurrentAppTheme(themeName: ThemeName): boolean {
  return theme === themeName;
}

const version = 'v1';
const name = `web-app:${version}`;

/* ************* */
/* Error logging */
/* ************* */

const enableErrorReporting = !isLocalhost || getEnvironmentBool(import.meta.env.REACT_APP_ENABLE_ERROR_REPORTING);

/* ********** */
/* App Config */
/* ********** */

export const config = {
  name,
  theme,
  version,
  stripeKey,
  isLocalhost,
  facebookAppID,
  appEnvironment,
  buildEnvironment,
  googleMapsApiKey,
  enableErrorReporting,
  sentryDSN: SENTRY_DSN,
  intercomAppId: INTERCOM_APP_ID,
  release: import.meta.env.REACT_APP_RELEASE || 'local',
  muiXProLicenseKey: 'f89fe125cf4c2f4a0b48ea6227f4ea3cTz01NzgyMixFPTE3MDU1MDg1NzgwNjgsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
  whitelabel_url:
    import.meta.env.REACT_APP_WHITELABELS_URL ||
    'https://storage.googleapis.com/advio-app-prod-master-image-storage/themes/',
  externalLinks: {
    help: 'https://advertio.com/support',
    termsOfUse: 'https://www.leadzai.com/terms',
    privacyPolicy: 'https://www.leadzai.com/privacy',
  },
  routes: {
    api: '/api/',
    atomic: '/api/atomic/',
    login: '/auth/login',
    logout: '/auth/logout',
  },
  cookies: {
    csrf: 'csrftoken',
  },
};
