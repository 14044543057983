// WIP Imo these defaults should depend on the domain (PAs, FCRs, Advertio...)
export const DEFAULT_LANGUAGE = 'en';

export type SupportedLocale = 'en-US' | 'fr-BE' | 'nl-NL' | 'pt-PT' | 'es-ES' | 'de-DE';

// TODO: this default should depend on the domain (PAs, FCRs, Advertio...)
// FIXME: this default locale should not be the same as the default locale used for testing purposes
export type DefaultLocale = 'en-US';
export const DEFAULT_LOCALE: DefaultLocale = 'en-US';

export const SUPPORTED_LOCALES: SupportedLocale[] = ['en-US', 'fr-BE', 'nl-NL', 'pt-PT', 'es-ES', 'de-DE'];

export function isSupportedLocale(locale: string): locale is SupportedLocale {
  return SUPPORTED_LOCALES.includes(locale as SupportedLocale); // the cast is necessary
}
