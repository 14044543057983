import { useReactiveVar } from '@apollo/client';
import { permissionsVar } from 'client/cache';
import { FunctionComponent, ReactElement } from 'react';
import { APIPermission } from 'typeDeclarations/enums';

interface PermissionsViewDeciderProps {
  fallback?: ReactElement;
  requiredPermissions: APIPermission[];
}

export const PermissionsViewDecider: FunctionComponent<PermissionsViewDeciderProps> = ({
  fallback,
  children,
  requiredPermissions,
}) => {
  const permissions = useReactiveVar(permissionsVar);

  const isAllowed = requiredPermissions.every((permission) => Boolean(permissions[permission]));

  if (isAllowed) {
    return <>{children}</>;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};
