import { FunctionComponent } from 'react';
import { StepStatusIcon, StepStatusIconProps } from '../StepStatusIcon';

import { StepIcon, StepIconProps } from '@mui/material';

interface CampaignStepIconProps extends StepIconProps {
  stepStatusIconProps?: StepStatusIconProps;
}

export const CampaignStepIcon: FunctionComponent<CampaignStepIconProps> = ({
  error,
  active,
  completed,
  stepStatusIconProps,
  ...rest
}) => {
  const aggregateStepStatus = stepStatusIconProps?.aggregateStatus;

  if (active || !aggregateStepStatus) {
    return <StepIcon {...rest} active={active} />;
  }

  return (
    <StepStatusIcon
      {...stepStatusIconProps}
      aggregateStatus={aggregateStepStatus}
      sx={{ ...stepStatusIconProps?.sx, fontSize: '1.8rem' }}
      tooltipProps={{ ...stepStatusIconProps?.tooltipProps, title: undefined }}
    />
  );
};
