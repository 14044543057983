import { ApolloError, gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

import { BillingProfileNode } from 'typeDeclarations/graphql/nodes';
import { Connection } from 'typeDeclarations/graphql/base-types';
import {
  PaymentSourceUnionNode,
  PaymentSourceStripePaymentSourceData,
  PAYMENT_SOURCE_MUTATION_BILLING_PROFILE_FRAGMENT,
} from 'shared/PaymentSources/sharedFragments';
import { STRIPE_PAYMENT_SOURCES_PAGE_SIZE } from 'shared/PaymentSources/constants';
import { CachedBillingProfileSessionTeamData, CACHED_BILLING_PROFILE_QUERY } from 'shared/PaymentSources/sharedQueries';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

type UpdatePrimaryPaymentSourceData = Pick<BillingProfileNode, '__typename' | 'id' | 'modified'> & {
  otherStripeCardPaymentSources: Connection<PaymentSourceUnionNode>;
  primaryPaymentSource:
    | null
    | (Node & {
        stripePaymentSource: PaymentSourceStripePaymentSourceData | null;
      });
};

const UPDATE_BILLING_PROFILE_PRIMARY_PAYMENT_SOURCE_MUTATION = gql`
  mutation updateBillingProfilePrimaryPaymentSource(
    $input: UpdateBillingProfileInput!
    $amount: Int!
    $cursor: String
  ) {
    updateBillingProfile(input: $input) {
      billingProfile {
        ...paymentSourceMutationBillingProfileFragment
      }
    }
  }
  ${PAYMENT_SOURCE_MUTATION_BILLING_PROFILE_FRAGMENT}
`;

interface UpdateBillingProfileMutationResponse {
  updateBillingProfile: {
    billingProfile: UpdatePrimaryPaymentSourceData;
  };
}

export interface UpdateBillingProfileMutationInputVariables {
  primaryPaymentSourceId: string;
}

interface UpdateBillingProfileMutationVariables {
  amount: number;
  cursor?: string | null;
  input: UpdateBillingProfileMutationInputVariables;
}

interface UseSetPrimaryPaymentSourceReturnValue {
  called: boolean;
  data: UpdateBillingProfileMutationResponse | null | undefined;
  error?: ApolloError;
  loading: boolean;
  setPrimaryPaymentSource: (primaryPaymentSourceId: string) => void;
}

export function useSetPrimaryPaymentSource(options?: {
  onCompleted?: () => void;
}): UseSetPrimaryPaymentSourceReturnValue {
  const { formatMessage } = useExtendedIntl();
  const { enqueueSnackbar } = useSnackbar();
  const onError = useDefaultOnError();

  const onCompleted = options?.onCompleted;

  const handleOnCompleted = () => {
    enqueueSnackbar(formatMessage({ id: 'set-primary-payment-source.success' }), { variant: 'success' });
    onCompleted?.();
  };

  const [updatePrimaryPaymentSourceMutation, { called, data, error, loading, client }] = useMutation<
    UpdateBillingProfileMutationResponse,
    UpdateBillingProfileMutationVariables
  >(UPDATE_BILLING_PROFILE_PRIMARY_PAYMENT_SOURCE_MUTATION, {
    onError: (err) => onError(err),
    onCompleted: handleOnCompleted,
  });

  const setPrimaryPaymentSource = (primaryPaymentSourceId: string) => {
    const cachedSessionTeamBillingProfileData = client.readQuery<CachedBillingProfileSessionTeamData>({
      query: CACHED_BILLING_PROFILE_QUERY,
    });

    if (!cachedSessionTeamBillingProfileData) {
      throw new Error('No cached data for the updatePrimaryPaymentSourceMutation mutation');
    }

    updatePrimaryPaymentSourceMutation({
      variables: {
        amount: STRIPE_PAYMENT_SOURCES_PAGE_SIZE,
        cursor: null,
        input: {
          primaryPaymentSourceId,
        },
      },
    });
  };

  return {
    called,
    data,
    error,
    loading,
    setPrimaryPaymentSource,
  };
}
