import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { isUndefined } from 'typeDeclarations/typeGuards';

export type SearchParameterValue = string | string[];
export type SearchParametersDictionary = Record<string, SearchParameterValue>;

export type UncleanSearchParameterValue = SearchParameterValue | undefined;
export type UncleanSearchParametersDictionary = Record<string, UncleanSearchParameterValue>;

/**
 * sanitizes an unclean search param value into an expected string value.
 * if there are multiple entries for the param, will default to the first entry found.
 * @param value the unclean value
 * @param defaultValue the default to be used if the search param no longer exists
 */
export function sanitizeToSingleValue(value: UncleanSearchParameterValue, defaultValue: string): string;
export function sanitizeToSingleValue(value: UncleanSearchParameterValue, defaultValue?: string): string | undefined;
export function sanitizeToSingleValue(
  value: UncleanSearchParameterValue,
  defaultValue?: string | undefined,
): string | undefined {
  if (isUndefined(value)) {
    return defaultValue;
  }

  if (Array.isArray(value)) {
    return value[0];
  }

  return value;
}

/**
 * sanitizes an unclean search param value into an expected string array value.
 * if there is only one entry for the param, will default to an array of a single element,
 * which will be the value of the entry.
 * @param value the unclean value
 * @param defaultValue the default to be used if the search param no longer exists
 */
export function sanitizeToArrayValue(value: UncleanSearchParameterValue, defaultValue: string[]): string[];
export function sanitizeToArrayValue(value: UncleanSearchParameterValue, defaultValue?: string[]): string[] | undefined;
export function sanitizeToArrayValue(
  value: UncleanSearchParameterValue,
  defaultValue: string[] | undefined,
): string[] | undefined {
  if (isUndefined(value)) {
    return defaultValue;
  }

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
}

/**
 * returns a URLSearchParams instance from the current search string
 */
export function useSearchParams(): URLSearchParams {
  const { search } = useLocation();

  // only create a new instance if the search string changes
  const memoizedSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  return memoizedSearchParams;
}

/**
 * helper function that consolidates the values from a given list of pairs into a dictionary.
 * if the entry doesn't exist in the dictionary, it will be created with the value.
 * if the entry already exists, a new list will be created with the new value appended.
 * @param pairs the `key: value` pairs to consolidate
 */
function consolidateValues(pairs: Array<[string, string]>): UncleanSearchParametersDictionary {
  const dict: UncleanSearchParametersDictionary = {};

  pairs.forEach(([key, value]) => {
    const dictValue = dict[key];
    if (isUndefined(dictValue)) {
      dict[key] = value;
    } else if (Array.isArray(dictValue)) {
      dict[key] = [...dictValue, value];
    } else {
      dict[key] = [dictValue, value];
    }
  });

  return dict;
}

/**
 * Generates a dictionary from a given `URLSearchParams` instance.
 *
 * Collapses all entries with the same key into `key: value[]` pairs.
 *
 * If there are no entries for a search param, the value will be `undefined`
 *
 * @param searchParams the `URLSearchParams` instance
 */
export function generateSearchParamsDictionary(searchParams: URLSearchParams): UncleanSearchParametersDictionary {
  const pairs = Array.from(searchParams);
  return consolidateValues(pairs);
}

/**
 * Returns a dictionary of the current search params.
 */
export function useSearchParamsDictionary(): UncleanSearchParametersDictionary {
  const searchParams = useSearchParams();

  // only generate a new dictionary if the searchParams change
  const memoizedSearchParamsDict = useMemo(() => generateSearchParamsDictionary(searchParams), [searchParams]);

  return memoizedSearchParamsDict;
}

export type SearchParameterSetter = (searchParamsDictionary: SearchParametersDictionary) => void;

/**
 * Returns a callback that converts a dictionary of search params into a search string
 * and then redirects.
 */
export function useSearchParamsSetter(): SearchParameterSetter {
  const history = useHistory();

  return useCallback<SearchParameterSetter>(
    (searchParamsDictionary) => {
      // can't use these from the router hooks
      // they retain old values when calling this function in rapid succession
      const { search, pathname } = window.location;

      // grab current search params
      const searchParams = new URLSearchParams(search);

      // set new params or replace existing param values
      Object.entries(searchParamsDictionary).forEach(([key, value]) => {
        // doesn't matter if it's a string or array.
        // if it doesn't have a length, delete the entry.
        if (!value.length) {
          searchParams.delete(key);
          return;
        }

        if (Array.isArray(value)) {
          // delete all current entries for that key
          searchParams.delete(key);
          // for each item in the list, append an entry for the same key.
          value.forEach((item) => searchParams.append(key, item));
        } else {
          // if it's not an array, replace the entry
          searchParams.set(key, value);
        }
      });

      // let the platform automatically encode the search string
      const searchString = searchParams.toString();

      // redirect to current path with a new search string
      history.push(`${pathname}?${searchString}`);
    },
    [history],
  );
}

/**
 * Utility that aggregates both a search params dictionary and setter
 * to mimic state hooks for ease of use. Does not use initial state (intentional).
 * Returns a tuple of `[currentSearchParamsDictionary, searchParamsSetter]`
 */
export function useSearchParamsTuple(): [UncleanSearchParametersDictionary, SearchParameterSetter] {
  const searchParamsDictionary = useSearchParamsDictionary();
  const searchParamsSetter = useSearchParamsSetter();

  return useMemo(() => [searchParamsDictionary, searchParamsSetter], [searchParamsDictionary, searchParamsSetter]);
}
