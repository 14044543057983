import { FunctionComponent } from 'react';
import { DefaultDialog, DefaultDialogProps } from 'components/shared/DefaultDialog/DefaultDialog';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { makeVar, useReactiveVar } from '@apollo/client';
import { WalletDialogContent } from './WalletDialogContent';
import { useUserTracking } from 'userTracking/useUserTracking';
import { BudgetIssue } from 'components/Budget/hooks/useCampaignBudgetWalletIssues';

type WalletVar = {
  isOpen: boolean;
  issue?: BudgetIssue;
};

const CLEAN_STATE: WalletVar = {
  isOpen: false,
  issue: undefined,
};

export const walletVar = makeVar(CLEAN_STATE);

type WalletDialogProps = Omit<DefaultDialogProps, 'open'>;

export const WalletDialog: FunctionComponent<WalletDialogProps> = ({ ...dialogProps }) => {
  const { captureEvent } = useUserTracking();
  const { formatMessage } = useExtendedIntl();
  const walletReactiveVar = useReactiveVar(walletVar);

  return (
    <DefaultDialog
      open={walletReactiveVar.isOpen}
      content={<WalletDialogContent />}
      onClose={() => walletVar(CLEAN_STATE)}
      aria-labelledby="wallet-dialog-title"
      aria-describedby="wallet-dialog-description"
      title={formatMessage({ id: 'shared.wallet' })}
      TransitionProps={{
        onEntered: () => captureEvent({ eventName: 'openWallet' }),
      }}
      {...dialogProps}
    />
  );
};
