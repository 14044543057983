import { ComponentType } from 'react';
import { Route as ReactRouterRoute, RouteProps } from 'react-router-dom';
import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import { ErrorLoadingPage } from 'shared/ErrorLoadingPage';

const errorBoundaryWrapper =
  <T extends object>(Component: ComponentType<T>, errorElement: ErrorBoundaryProps['fallback']) =>
  (props: T) => (
    <ErrorBoundary fallback={errorElement ?? <ErrorLoadingPage />}>
      <Component {...props} />
    </ErrorBoundary>
  );

interface RoutePropsWithBoundary extends RouteProps {
  errorElement?: ErrorBoundaryProps['fallback'];
}

export const Route = ({ children, errorElement, component: Component, ...rest }: RoutePropsWithBoundary) => {
  if (Component) {
    const componentWithBoundary = errorBoundaryWrapper(Component, errorElement);
    return <ReactRouterRoute render={componentWithBoundary} {...rest} />;
  }

  if (children) {
    return (
      <ReactRouterRoute {...rest}>
        <ErrorBoundary fallback={errorElement ?? <ErrorLoadingPage />}>{children}</ErrorBoundary>
      </ReactRouterRoute>
    );
  }

  return <ReactRouterRoute component={Component} children={children} {...rest} />;
};
