import { gql } from '@apollo/client';

import {
  BillingProfileNode,
  BillingProfileAutoTopupThresholdAmountLimitsNode,
  BillingProfileTopupAmountLimitsNode,
} from 'typeDeclarations/graphql/nodes';

export type AutoTopUpAccordionFormBillingProfileData = Pick<
  BillingProfileNode,
  'autoTopupAmount' | 'autoTopupThresholdAmount' | 'currency'
> & {
  autoTopupThresholdAmountLimits: null | Pick<BillingProfileAutoTopupThresholdAmountLimitsNode, 'suggestedAmounts'>;
} & {
  topupAmountLimits: null | Pick<BillingProfileTopupAmountLimitsNode, 'minimumAmountWithVat' | 'maximumAmountWithVat'>;
};

export const AUTO_TOP_UP_ACCORDION_FORM_BILLING_PROFILE_FRAGMENT = gql`
  fragment autoTopUpAccordionFormBillingProfileFragment on BillingProfileNode {
    autoTopupAmount
    autoTopupThresholdAmount
    currency
    autoTopupThresholdAmountLimits {
      suggestedAmounts
    }
    topupAmountLimits {
      minimumAmountWithVat
      maximumAmountWithVat
    }
  }
`;
