import { isString, isUndefined } from 'typeDeclarations/typeGuards';

import { APIErrorCode } from './APIErrorCode';
import { DataObject } from './types';

export let API_ERROR_CODES_MAP: undefined | Record<string, boolean>;

/**
 * Type guard that checks if a provided string is an APIErrorCode
 */
export function isAPIErrorCode(errorCode: string): errorCode is APIErrorCode {
  if (isUndefined(API_ERROR_CODES_MAP)) {
    const errorCodeMap: Record<string, boolean> = {};

    Object.values(APIErrorCode).forEach((ec) => {
      errorCodeMap[ec] = true;
    });

    API_ERROR_CODES_MAP = errorCodeMap;
  }

  return errorCode in API_ERROR_CODES_MAP;
}

/**
 * Extensions data can sometimes be a stringified JSON object.
 * This function is an util that ensures we always retrieve a DataObject from extensions data.
 * @param data data from an extensions object
 * @returns DataObject instance
 */
export function getDataObject<T extends DataObject>(data: string | T): T {
  return isString(data) ? (JSON.parse(data) as T) : data;
}
