import { exists } from 'typeDeclarations/typeGuards';
import { getObjectKeys } from 'utils/getObjectKeys';

import { APIErrorCode } from './APIErrorCode';
import { IssueLikeObject, DataObject, Extensions } from './types';
import { getDataObject } from './utils';

/**
 * A structure to make lists of issue-like objects easier to handle, by mapping the ocurred error
 * codes to error data.
 */
export class APIErrorCodesCatalog<I extends IssueLikeObject> {
  /**
   * The supporting data structure (dictionary). Establishes a relation between error codes and
   * data.
   */
  private errorData: PartialRecord<APIErrorCode | string, DataObject> = {};

  constructor(issueLikeObjects: I[] | readonly I[]) {
    issueLikeObjects.forEach(({ extensions }) => {
      if (!extensions) return;
      this.storeErrorData(extensions);
    });
  }

  /**
   * Stores the issue-like object's data according to it's extensions.
   * Creates an entry in the dictionary with the extension's error_code as key and data as value.
   * Is called recursively for multi errors.
   * Note: it is assumed that there will no multiple objects with the same error code.
   * If that happens, data will be overwritten.
   * @param extensions the object's extensions
   */
  private storeErrorData = (extensions: Extensions): void => {
    const { data, error_code: errorCode } = extensions;

    if (!exists(errorCode) || !exists(data)) return;

    const parsedData = getDataObject(data);

    if (errorCode === APIErrorCode.MultiError) {
      // we know at this point that this is a MultiError
      const { errors } = parsedData as unknown as DataObject<APIErrorCode.MultiError>;
      // recursively call storeErrorData for each error inside the MultiError
      errors?.forEach(this.storeErrorData);
    } else {
      this.errorData[errorCode] = parsedData;
    }
  };

  /**
   * @returns the list of error codes that were cataloged
   */
  public getErrorCodes = (): Array<APIErrorCode | string> => getObjectKeys(this.errorData);

  /**
   * Checks if a given error code exists in the catalog instance.
   */
  public hasErrorCode = (errorCode: APIErrorCode): boolean => errorCode in this.errorData;

  /**
   * Retrieves the error data for a given error code or null if the error doesn't exist.
   * @param errorCode the error for which to retrieve data
   * @returns the error data or null
   */
  public getErrorData = <E extends APIErrorCode>(errorCode: E): DataObject<E> | null => {
    if (this.hasErrorCode(errorCode)) {
      // we know at this point that the error code exists in the dictionary
      return this.errorData[errorCode] as DataObject<E>;
    }

    return null;
  };
}
