import { FunctionComponent } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useQuery, gql } from '@apollo/client';

import { ReactComponent as AdvertioCreditLine } from 'images/credit-line.svg';
import { Node } from 'typeDeclarations/graphql/base-types';
import { ErrorMessage } from 'shared/ErrorMessage';
import { LoadingBlock } from 'shared/LoadingBlock';

import { CREDIT_LINE_CARD_BILLING_PROFILE_FRAGMENT, CreditLineCardBillingProfileData } from './fragments';
import { ExtensionCard } from 'shared/ExtensionsList/extension-card';
import { Typography, Stack } from '@mui/material';

interface CreditLineCardQueryData {
  sessionTeam: Node & {
    modified: string;
    billingProfile: Node & { modified: string } & CreditLineCardBillingProfileData;
  };
}

const CREDIT_LINE_CARD_QUERY = gql`
  query creditLineCardQuery {
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        ...creditLineCardBillingProfileFragment
      }
    }
  }
  ${CREDIT_LINE_CARD_BILLING_PROFILE_FRAGMENT}
`;

export const CreditLineCard: FunctionComponent = () => {
  const { data, error, loading } = useQuery<CreditLineCardQueryData>(CREDIT_LINE_CARD_QUERY);

  const { formatMessage, formatNumber } = useExtendedIntl();

  if (loading) return <LoadingBlock />;
  if (error || !data) return <ErrorMessage />;

  const { creditAvailable, creditUsed, currency } = data.sessionTeam.billingProfile;

  return (
    <ExtensionCard
      contentProps={{
        sx: {
          columnGap: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      }}
      content={
        <>
          <AdvertioCreditLine />
          <Stack>
            <Typography>{formatMessage({ id: 'shared.total' })}</Typography>
            <Typography fontWeight="bold">
              {formatNumber(Number(creditAvailable) + Number(creditUsed), { style: 'currency', currency })}
            </Typography>
          </Stack>
          <Stack>
            <Typography>{formatMessage({ id: 'credit-line-card.remaining-amount-heading' })}</Typography>
            <Typography fontWeight="bold">
              {formatNumber(Number(creditAvailable), { style: 'currency', currency })}
            </Typography>
          </Stack>
        </>
      }
    />
  );
};
