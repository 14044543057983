import { FunctionComponent, useEffect } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { gql, useMutation } from '@apollo/client';
import { GraphQLObject } from 'typeDeclarations/graphql/base-types';
import { BillingProfileNode } from 'typeDeclarations/graphql/nodes';
import { AutoTopUpSwitchBillingProfileData, AUTO_TOP_UP_SWITCH_BILLING_PROFILE_FRAGMENT } from './fragments';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { Switch, Typography, Stack, SwitchProps } from '@mui/material';

interface UpdateAutoTopupEnabledData {
  updateBillingProfile: GraphQLObject & {
    billingProfile: Pick<BillingProfileNode, 'id' | 'modified'> & AutoTopUpSwitchBillingProfileData;
  };
}

interface UpdateAutoTopupEnabledVariables {
  input: {
    isAutoTopupEnabled: boolean;
  };
}

const UPDATE_AUTO_TOPUP_ENABLED = gql`
  mutation updateAutoTopupEnabled($input: UpdateBillingProfileInput!) {
    updateBillingProfile(input: $input) {
      billingProfile {
        id
        modified
        ...autoTopUpSwitchBillingProfileFragment
      }
    }
  }
  ${AUTO_TOP_UP_SWITCH_BILLING_PROFILE_FRAGMENT}
`;

interface AutoTopUpSwitchProps {
  expanded: boolean;
  billingProfile: AutoTopUpSwitchBillingProfileData;
}

export const AutoTopUpSwitch: FunctionComponent<AutoTopUpSwitchProps & SwitchProps> = ({
  expanded,
  billingProfile,
  ...rest
}) => {
  const onError = useDefaultOnError();
  const { formatMessage } = useExtendedIntl();

  const { isAutoTopupEnabled } = billingProfile;
  const enabled = isAutoTopupEnabled ?? false;

  // TODO: This should be done on the parent component (consumer) to avoid tight coupling.
  //  In fact, at that point, all of this could be moved to the parent component,
  //  marking this component as redundant.
  const [updateAutoTopupEnabled] = useMutation<UpdateAutoTopupEnabledData, UpdateAutoTopupEnabledVariables>(
    UPDATE_AUTO_TOPUP_ENABLED,
    {
      onError: (err) => onError(err),
      variables: {
        input: {
          isAutoTopupEnabled: !enabled,
        },
      },
    },
  );

  useEffect(() => {
    if (enabled !== expanded) {
      updateAutoTopupEnabled();
    }
  }, [enabled, expanded, updateAutoTopupEnabled]);

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Typography variant="subtitle2" fontWeight="bold">
        {formatMessage({ id: 'wallet.recurring-payment' })}
      </Typography>
      <Switch
        {...rest}
        edge="end"
        size="small"
        color="primary"
        checked={enabled}
        value="recurringPayment"
        onChange={() => updateAutoTopupEnabled()}
      />
    </Stack>
  );
};
