import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import { isApolloError } from '@apollo/client/errors';
import { APIErrorCode } from 'utils/APIErrorCodes/APIErrorCode';
import { APIErrorCodesCatalog } from 'utils/APIErrorCodes/APIErrorCodesCatalog';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { isAPIErrorCode } from 'utils/APIErrorCodes/utils';
import { logError } from 'utils/logging';
import { DataObject } from 'utils/APIErrorCodes/types';
import { SessionContext } from 'components/App/session/SessionContext';

type ErrorHandlingFunction<E extends APIErrorCode> = (data: DataObject<E>) => void;

type ErrorHandlersMap = {
  [K in APIErrorCode]?: ErrorHandlingFunction<K>;
};

type UseDefaultOnErrorCallback = (
  error: Error,
  opts?: {
    defaultErrorMessage?: string;
    errorHandlers?: ErrorHandlersMap;
  },
) => void;

export function useDefaultOnError(): UseDefaultOnErrorCallback {
  const { enqueueSnackbar } = useSnackbar();
  const {
    user: { isStaff },
  } = useContext(SessionContext);
  const { formatMessage, formatAPIErrorCode } = useExtendedIntl();

  const onError = useCallback<UseDefaultOnErrorCallback>(
    (error, opts) => {
      let defaultErrMessage = opts?.defaultErrorMessage ?? formatMessage({ id: 'shared.default-error-message' });
      const defaultErrorHandler = (errorMessage: string) => enqueueSnackbar(errorMessage, { variant: 'error' });

      if (!isApolloError(error)) {
        return defaultErrorHandler(defaultErrMessage);
      }

      const { graphQLErrors, networkError } = error;

      if (!graphQLErrors.length || networkError) {
        return defaultErrorHandler(defaultErrMessage);
      }

      const errorsCatalog = new APIErrorCodesCatalog(graphQLErrors);
      const catalogedErrorCodes = errorsCatalog.getErrorCodes();

      catalogedErrorCodes.forEach((errorCode) => {
        defaultErrMessage = isStaff ? errorCode : defaultErrMessage;

        if (!isAPIErrorCode(errorCode)) {
          defaultErrorHandler(defaultErrMessage);
          logError(new Error(`Unknown error code '${errorCode}'`));
        } else {
          const errorData = errorsCatalog.getErrorData(errorCode);

          if (!errorData) {
            defaultErrorHandler(defaultErrMessage);
          } else {
            if (opts?.errorHandlers) {
              const errorHandler = opts.errorHandlers[errorCode] as ErrorHandlingFunction<typeof errorCode> | undefined;

              if (errorHandler) {
                errorHandler(errorData);
              } else {
                defaultErrorHandler(
                  formatAPIErrorCode({
                    errorCode,
                    data: errorData,
                    fallbackMessage: defaultErrMessage,
                  }),
                );
              }
            } else {
              defaultErrorHandler(
                formatAPIErrorCode({
                  errorCode,
                  data: errorData,
                  fallbackMessage: defaultErrMessage,
                }),
              );
            }
          }
        }
      });
    },
    [enqueueSnackbar, formatMessage, formatAPIErrorCode, isStaff],
  );

  return onError;
}
