import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

type RefreshWindowCallback = (message: string, timeoutLength: number) => void;

export function useRefreshWindow(): RefreshWindowCallback {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback<RefreshWindowCallback>(
    (message, timeoutLength) => {
      enqueueSnackbar(message, { variant: 'error' });

      function reload(): void {
        window.location.reload();
      }

      setTimeout(reload, timeoutLength);
    },
    [enqueueSnackbar],
  );
}
