const deDEMessages: Record<string, string> = {
  'error-loading-page.text':
    'Oh-oh, es scheint, dass wir diesen Abschnitt nicht laden können. Es tut uns leid, können Sie es bitte noch einmal versuchen?',
  'error-loading-page.button': 'Erneut versuchen',
  'shared.something-went-wrong-message': 'Ups!{br}Etwas ist schief gelaufen!',
  'shared.something-went-wrong-description':
    'Wir konnten diesen Link in der App nicht finden.{br}Bitte prüfen Sie auf Tippfehler oder gehen Sie zurück zum Dashboard der Kampagne.',
};

export default deDEMessages;
