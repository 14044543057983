import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { Route } from 'shared/Route';
import { PrivateRoute } from 'shared/PrivateRoute';
import { PrivateAppRouting } from './session/PrivateAppRouting';
import { useUserTracking } from 'userTracking/useUserTracking';
import { useIntercom } from 'react-use-intercom';
import { shouldEnableLeadzaiBoot } from 'shared/shouldEnableLeadzaiBoot';

const LazyDdtDashboard = lazy(() => import('ddt-dashboard').then(({ Index }) => ({ default: Index })));

const LazyAuthPage = lazy(() => import('pages/Auth/AuthRouting').then(({ AuthRouting }) => ({ default: AuthRouting })));

const LazyManageFBPages = lazy(() =>
  import('pages/ManageFBPages/ManageFBPages').then(({ ManageFBPages }) => ({ default: ManageFBPages })),
);

const LazyManageFBPagesV2 = lazy(() => // TODO temporary. Don't forget to change this to default after we have access to the pages_manage_metadata
  import('pages/ManageFBPagesV2/ManageFBPages').then(({ ManageFBPages }) => ({ default: ManageFBPages })),
);

export const MANAGE_FB_PAGE_PERMISSIONS_ROUTE_URL = 'manage-facebook-page-permissions';
export const MANAGE_FB_PAGE_PERMISSIONS_ROUTE_URLV2 = 'manage-facebook-permissions';

// handler for when external login pages redirect to /login_success
function handleSocialLoginSuccess(): void {
  window.localStorage.setItem('loginSuccessFlag', 'true');

  window.close();
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const Loading: FunctionComponent = () => (
  <StyledDiv>
    <CircularProgress size="5rem" thickness={2.5} />
  </StyledDiv>
);

export const AppRouting: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { captureEvent } = useUserTracking();
  const { boot: bootIntercom } = useIntercom();

  if (shouldEnableLeadzaiBoot()) {
    bootIntercom();
  }

  /**
   * This is a posthog event.
   *
   * @see {@link https://posthog.com/docs/integrate/client/js#one-page-apps-and-page-views}
   */
  useEffect(() => {
    captureEvent({
      anonymous: true,
      eventName: 'pageview',
      data: { url: pathname },
    });
  }, [pathname, captureEvent]);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <PrivateRoute exact path="/" render={() => <Redirect to="/campaigns" />} />
        <Route path="/auth" component={LazyAuthPage} />
        <Route path="/login_success" render={handleSocialLoginSuccess} />
        <Route path={`/${MANAGE_FB_PAGE_PERMISSIONS_ROUTE_URL}`} component={LazyManageFBPages} />
        <Route path={`/${MANAGE_FB_PAGE_PERMISSIONS_ROUTE_URLV2}`} component={LazyManageFBPagesV2} />
        <Route path="/direct" component={LazyDdtDashboard} />
        <PrivateRoute exact path="*" component={PrivateAppRouting} />
      </Switch>
    </Suspense>
  );
};
