import { gql, useLazyQuery } from '@apollo/client';
import posthog from 'posthog-js';
import { useCallback } from 'react';

import { config } from 'appConfig';
import { captureEvent as captureGTMEvent } from './gtm';
import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';
import { API_KEY as POSTHOG_API_KEY, CONFIGS as POSTHOG_CONFIG, isLoaded } from './posthog';
import { sanitizeToSingleValue, useSearchParamsDictionary } from 'utils/searchParams';

const USER_BLACKLIST = new Set([
  'gbn@leadzai.com',
  'gbn+staff@leadzai.com',
  'gbn+pa-pt@leadzai.com',
  'gbn+sitee@leadzai.com',
  'gbn+fcr-be@leadzai.com',
  'gbn+bizay@leadzai.com',
  'probely_scanner@advertio.com',
]);

export interface UserTrackingQueryFragmentData {
  sessionTeam: Pick<TeamNode, 'id' | '_id' | 'name' | 'isDemo'>;
  sessionUser: Pick<UserNode, 'id' | '_id' | 'name' | 'email' | 'theme' | 'created' | 'isStaff' | 'ddtId'>;
}

export const USER_TRACKING_QUERY_FRAGMENT = gql`
  fragment userTrackingQueryFragment on Query {
    sessionTeam {
      id
      _id
      name
      isDemo
      modified
    }
    sessionUser {
      id
      _id
      name
      email
      theme
      isStaff
      modified
    }
  }
`;

type UserTrackingQueryData = UserTrackingQueryFragmentData;

const USER_TRACKING_QUERY = gql`
  query userTrackingQuery {
    ...userTrackingQueryFragment
  }
  ${USER_TRACKING_QUERY_FRAGMENT}
`;

function shouldTrackEmail(email: string) {
  return !USER_BLACKLIST.has(email);
}

function shouldTrack() {
  return config.buildEnvironment === 'prod';
}

export function initTracking() {
  if (!shouldTrack()) return;

  posthog.init(POSTHOG_API_KEY, POSTHOG_CONFIG);
}

export function resetTracking() {
  if (!shouldTrack() || !isLoaded()) return;

  posthog.reset();
}

export function stopTracking() {
  if (!isLoaded()) return;

  posthog.opt_out_capturing();
}

interface UseUserTrackingReturnValue {
  identifyUser: () => Promise<void>;
  captureEvent: (args: { eventName: string; anonymous?: boolean; data?: Record<string, unknown> }) => Promise<void>;
}

export const useUserTracking = (): UseUserTrackingReturnValue => {
  const searchParams = useSearchParamsDictionary();
  const [fetchUserTrackingData] = useLazyQuery<UserTrackingQueryData>(USER_TRACKING_QUERY);

  // Should only be invoked when the user is known to be logged-in
  const identifyUser = useCallback<UseUserTrackingReturnValue['identifyUser']>(async () => {
    if (!shouldTrack()) {
      stopTracking();
      return;
    }

    const { data } = await fetchUserTrackingData();
    if (!data) return;

    const {
      sessionTeam: { isDemo, name: teamName, _id: teamPrettyId },
      sessionUser: { email, theme, isStaff, name: userName, _id: userPrettyID, ddtId },
    } = data;

    if (!shouldTrackEmail(email)) {
      stopTracking();
      return;
    }

    const userIdentity = {
      email,
      theme,
      ddtId,
      isStaff,
      name: userName,
    };

    const teamIdentity = {
      teamName,
      isDemoTeam: isDemo,
      teamId: teamPrettyId,
    };

    const posthogIdParam = sanitizeToSingleValue(searchParams.pi);

    if (posthogIdParam && ddtId) {
      posthog.alias(ddtId, posthogIdParam);
      return;
    }

    if (ddtId) {
      posthog.identify(ddtId, userIdentity);
      return;
    }

    posthog.register(teamIdentity);
    posthog.identify(userPrettyID, userIdentity);
    captureGTMEvent('identifyUser', { ...userIdentity, ...teamIdentity });
  }, [fetchUserTrackingData, searchParams.pi]);

  const captureEvent = useCallback<UseUserTrackingReturnValue['captureEvent']>(
    async ({ data, eventName, anonymous = false }) => {
      if (!shouldTrack()) {
        stopTracking();
        return;
      }

      /**
       * The user is considered anonymous if they're not logged-in. This is useful on auth-related pages.
       * Note: GQL operations executed on logged-out users always redirect to an auth page
       */
      // FIXME: We should request the query isLoggedIn to know this
      if (!anonymous) {
        // TODO: Can't we just fetch this data once, upon login?
        //  And, in the future, update it when the user changes their settings?
        const { data: queryData } = await fetchUserTrackingData();
        if (!queryData) return;

        const { sessionUser } = queryData;
        if (!shouldTrackEmail(sessionUser.email)) {
          stopTracking();
          return;
        }
      }

      if (eventName === 'pageview') {
        posthog.capture(`$${eventName}`);
      } else {
        posthog.capture(eventName, data);
      }

      captureGTMEvent(eventName, data);
    },
    [fetchUserTrackingData],
  );

  return {
    identifyUser,
    captureEvent,
  };
};
