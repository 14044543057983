import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { useDefaultOnError } from './useDefaultOnError';

const LOGOUT_MUTATION = gql`
  mutation logoutMutation {
    logout(input: {}) {
      clientMutationId
    }
  }
`;

export function useLogoutUser(options?: MutationHookOptions<unknown, unknown>): MutationTuple<unknown, unknown> {
  const onError = useDefaultOnError();

  return useMutation(LOGOUT_MUTATION, {
    onError: (err) => onError(err),
    ...options,
  });
}
