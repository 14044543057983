import { gql } from '@apollo/client';

import { StripePaymentSourceNode } from 'typeDeclarations/graphql/nodes';
import {
  DeletePaymentSourceStripePaymentSourceData,
  DELETE_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT,
} from 'shared/PaymentSources/DeletePaymentSourceButton/fragments';

export type WalletPaymentSourceStripePaymentSourceData = Pick<
  StripePaymentSourceNode,
  'brand' | 'expirationDate' | 'lastDigits' | 'isChargeable'
> &
  DeletePaymentSourceStripePaymentSourceData;

export const WALLET_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT = gql`
  fragment walletPaymentSourceStripePaymentSourceFragment on StripePaymentSourceNode {
    brand
    expirationDate
    lastDigits
    isChargeable
    ...deletePaymentSourceStripePaymentSourceFragment
  }
  ${DELETE_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT}
`;
