import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useGetWalletBalance } from 'hooks/useGetWalletBalance';
import { FunctionComponent } from 'react';
import { ErrorMessage } from 'shared/ErrorMessage';

import { CircularProgress, Typography } from '@mui/material';

export const WalletBalance: FunctionComponent = () => {
  const { formatMoneyValue, formatMessage } = useExtendedIntl();
  const { transformedData, loading, error } = useGetWalletBalance();

  if (loading) {
    return <CircularProgress />;
  }
  if (error || !transformedData) {
    return <ErrorMessage />;
  }

  return (
    <Typography variant="h6" color="primary.contrastText">
      {formatMessage(
        { id: 'invoices-balance.balance-text' },
        { balance: formatMoneyValue(transformedData.balance, transformedData.currency) },
      )}
    </Typography>
  );
};
