const frBEMessages: Record<string, string> = {
  'error-loading-page.text':
    "Uh-oh, il semble que nous ne soyons pas en mesure de charger cette section. Nous sommes désolés, pouvez-vous réessayer s'il vous plaît?",
  'error-loading-page.button': 'Essayer à nouveau',
  'shared.something-went-wrong-message': 'Ooops!{br}Something went wrong!',
  'shared.something-went-wrong-description':
    'Nous n’avons pas pu trouver ce lien sur l’application.{br}Veillez à vérifier les éventuelles fautes de frappe ou retournez au tableau de bord de la campagne.',
};

export default frBEMessages;
