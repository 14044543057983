import { QueryHookOptions, QueryResult, useQuery, gql } from '@apollo/client';
import { TeamNode, BillingProfileNode } from 'typeDeclarations/graphql/nodes';
import { useDefaultOnError } from './useDefaultOnError';

const WALLET_BALANCE_QUERY = gql`
  query walletBalanceQuery {
    sessionTeam {
      id
      modified
      billingProfile {
        id
        balance
        modified
        currency
        hasCreditLine
        creditAvailable
      }
    }
  }
`;

type WalletBalanceQueryData = {
  sessionTeam: Pick<TeamNode, 'id' | 'modified'> & {
    billingProfile: Pick<
      BillingProfileNode,
      'id' | 'balance' | 'modified' | 'currency' | 'hasCreditLine' | 'creditAvailable'
    >;
  };
};

type TransformedData = Pick<BillingProfileNode, 'balance' | 'currency'>;

export function useGetWalletBalance(
  options?: QueryHookOptions<WalletBalanceQueryData>,
): QueryResult<WalletBalanceQueryData> & { transformedData: undefined | TransformedData } {
  const onError = useDefaultOnError();

  const { data, ...rest } = useQuery<WalletBalanceQueryData>(WALLET_BALANCE_QUERY, {
    onError,
    ...options,
  });

  // Apollo doesn't offer a way to modify incoming data similar to how React Query does
  // https://tkdodo.eu/blog/react-query-data-transformations#data-transformation
  let transformedData: TransformedData | undefined;

  if (data) {
    const { balance, currency, hasCreditLine, creditAvailable } = data.sessionTeam.billingProfile;

    if (hasCreditLine) {
      transformedData = {
        currency,
        balance: creditAvailable,
      };
    } else {
      transformedData = {
        balance,
        currency,
      };
    }
  }

  return {
    data, // shouldn't be used at all from the outside
    transformedData,
    ...rest,
  };
}
