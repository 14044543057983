import { FunctionComponent } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Bullhorn: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12.5 8H4.5C3.39543 8 2.5 8.8954 2.5 10V14C2.5 15.1046 3.39543 16 4.5 16H5.5V20C5.5 20.5523 5.94772 21 6.5 21H8.5C9.05229 21 9.5 20.5523 9.5 20V16H12.5L17.5 20V4L12.5 8ZM22 12C22.0121 13.7051 21.038 15.2637 19.5 16V8C21.0279 8.7475 21.9976 10.299 22 12Z" />
  </SvgIcon>
);

export default Bullhorn;
