import { CSSProperties, FunctionComponent } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

import { Typography, TypographyProps } from '@mui/material';

interface ErrorMessageProps {
  className?: string;
  id?: string;
  style?: CSSProperties;
  values?: Record<string, string>;
}

export const ErrorMessage: FunctionComponent<ErrorMessageProps & TypographyProps> = ({
  className,
  id = 'shared.default-error-message',
  style,
  values,
  ...rest
}) => {
  const { formatMessage } = useExtendedIntl();

  return (
    <Typography className={className} style={style} {...rest}>
      {formatMessage({ id }, { values })}
    </Typography>
  );
};
