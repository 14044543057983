import { FunctionComponent } from 'react';
import { Menu, MenuProps } from '@mui/material';
import { ActionsMenuItem, Option } from 'shared/ActionsMenu/ActionsMenuItem';

export interface ActionsMenuProps extends Omit<MenuProps, 'open'> {
  options: Option[];
}

export const ActionsMenu: FunctionComponent<ActionsMenuProps> = ({ options, anchorEl, ...remainingMenuProps }) => {
  const open = Boolean(anchorEl);
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      TransitionProps={{
        ...remainingMenuProps.TransitionProps,
      }}
      {...remainingMenuProps}
    >
      {options.map((option, i) => (
        <ActionsMenuItem key={i} {...option} />
      ))}
    </Menu>
  );
};
