import { ReactElement, ReactNode } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

import {
  TextField,
  Autocomplete,
  AutocompleteRenderInputParams,
  TextFieldProps,
  AutocompleteProps,
} from '@mui/material';

export type DefaultAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'openText' | 'closeText' | 'clearText' | 'noOptionsText' | 'renderInput'
> & {
  textFieldProps?: TextFieldProps;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
};

export function DefaultAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: DefaultAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): ReactElement {
  const { textFieldProps, ...remainingAutocompleteProps } = props;

  const { formatMessage } = useExtendedIntl();

  return (
    <Autocomplete
      id=""
      fullWidth
      clearOnBlur
      autoComplete
      autoHighlight
      clearOnEscape
      handleHomeEndKeys
      openText={formatMessage({ id: 'shared.open' })}
      clearText={formatMessage({ id: 'shared.clear' })}
      closeText={formatMessage({ id: 'default-autocomplete.close-text' })}
      noOptionsText={formatMessage({ id: 'default-autocomplete.no-options-text' })}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          variant="outlined"
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
          }}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps?.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
      {...remainingAutocompleteProps}
    />
  );
}
