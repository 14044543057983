export enum APIPermission {
  ViewAds = 'view_ads',
  ViewBudgets = 'view_budgets',
  ViewKeywords = 'view_keywords',
  ViewAdIssues = 'view_ad_issues',
  PerformChanges = 'perform_changes',
  PublishCampaigns = 'publish_campaigns',
  ViewTargetingGroups = 'view_targeting_groups',
  RequestFacebookPageApproval = 'request_facebook_page_approval',
  EstimatedCostPerGoalWithUrl = 'estimate_cost_per_goal_with_url',
}
