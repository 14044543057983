import { GraphQLObject } from 'typeDeclarations/graphql/base-types';
import { isNull } from 'typeDeclarations/typeGuards';
import { getNumberOfDaysInBetweenDates, DAYS_PER_YEAR, DAYS_PER_MONTH } from 'utils/date';
import { Breakdown } from './types';

/**
 * if startDate or endDate are null it means no limit exists and thus
 * the broadest breakdown is applied ('year')
 */
export function getBreakdown(startDate: Date | null, endDate: Date | null): Breakdown {
  if (isNull(startDate) || isNull(endDate)) return 'year';

  const numberOfDaysBetweenDates = getNumberOfDaysInBetweenDates({
    start: startDate,
    end: endDate,
  });

  if (numberOfDaysBetweenDates <= 2 * DAYS_PER_MONTH) return 'day';

  if (numberOfDaysBetweenDates > 2 * DAYS_PER_YEAR) return 'year';

  return 'month';
}

interface ReportPerHourNode extends GraphQLObject {
  segmentHour: number;
}

interface ReportPerDayNode extends GraphQLObject {
  segmentDate: string;
}

interface ReportPerMonthNode extends GraphQLObject {
  segmentMonth: number;
  segmentYear: number;
}

interface ReportPerYearNode extends GraphQLObject {
  segmentYear: number;
}

type ReportPerPeriodUnionNode =
  | GraphQLObject
  | ReportPerHourNode
  | ReportPerDayNode
  | ReportPerMonthNode
  | ReportPerYearNode;

export function reportToDateInMillis(report: ReportPerPeriodUnionNode): number {
  if ('segmentHour' in report) {
    const date = new Date();
    date.setHours(report.segmentHour + 1);

    return date.getTime();
  }

  if ('segmentDate' in report) {
    const dateString = report.segmentDate;
    const dateInMillis = new Date(dateString).getTime();

    return dateInMillis;
  }

  if ('segmentMonth' in report) {
    const monthNumber = report.segmentMonth;
    const yearNumber = report.segmentYear;

    // blame safari for not liking months with a single digit
    const date = new Date();
    date.setDate(1);
    date.setMonth(monthNumber - 1);
    date.setFullYear(yearNumber);

    const dateInMillis = date.getTime();

    return dateInMillis;
  }

  if ('segmentYear' in report) {
    const yearNumber = report.segmentYear;

    const dateInMillis = new Date(`${yearNumber}`).getTime();

    return dateInMillis;
  }

  throw new Error(`Provided report object ${JSON.stringify(report)} has no period segment.`);
}
