import { ApolloCache } from '@apollo/client';
import { Modifiers, FieldPolicy, FieldReadFunction, Reference, StoreObject } from '@apollo/client/cache';
import { isString, isUndefined } from 'typeDeclarations/typeGuards';

export function cacheRedirect(__typenames: string[]): FieldReadFunction<unknown> {
  return (existing, { args, toReference, ...rest }) => {
    if (!__typenames.length) {
      return undefined;
    }

    if (!isUndefined(existing)) {
      return existing;
    }

    if (args !== null && isString(args.id)) {
      if (__typenames.length === 1) {
        return toReference({
          id: args.id,
          __typename: __typenames[0],
        });
      }

      const { readField } = rest;

      for (let i = 0; i < __typenames.length; i++) {
        const ref = toReference({
          id: args.id,
          __typename: __typenames[i],
        });

        const __typename = readField('__typename', ref);

        if (__typename === __typenames[i]) {
          return ref;
        }
      }
    }

    return undefined;
  };
}

export function orderedNonNormalizedObjectList(keyArgs?: string[]): FieldPolicy<Array<StoreObject | Reference>> {
  return {
    keyArgs,
    merge: (existing, incoming, { mergeObjects }) => {
      if (!existing) {
        return incoming;
      }

      const result = new Array<StoreObject | Reference>(incoming.length);

      incoming.forEach((incomingItem, index) => {
        const existingItem = existing[index];

        if (!existingItem) {
          result[index] = incomingItem;
        } else {
          const merged = mergeObjects(existingItem, incomingItem);

          if (!merged) {
            const existingItemString = JSON.stringify(existingItem);
            const incomingItemString = JSON.stringify(incomingItem);
            throw new Error(
              `Could not merge existing item ${existingItemString} with incoming item ${incomingItemString}`,
            );
          }

          result[index] = merged;
        }
      });

      return result;
    },
  };
}

interface DeleteCacheFieldArgs {
  fieldNames: string[];
  cache: ApolloCache<unknown>;
  node: StoreObject | Reference;
}

export function deleteCacheField({ node, cache, fieldNames }: DeleteCacheFieldArgs) {
  const modifiers: Modifiers = {};

  fieldNames.forEach((fieldName) => {
    modifiers[fieldName] = (_, { DELETE }) => DELETE as unknown;
  });

  cache.modify({
    fields: modifiers,
    id: cache.identify(node),
  });
}

interface DeleteCacheFieldByIdArgs {
  id: string;
  fieldNames: string[];
  cache: ApolloCache<unknown>;
}

export function deleteCacheFieldById({ id, cache, fieldNames }: DeleteCacheFieldByIdArgs) {
  const modifiers: Modifiers = {};

  fieldNames.forEach((fieldName) => {
    modifiers[fieldName] = (_, { DELETE }) => DELETE as unknown;
  });

  cache.modify({
    id,
    fields: modifiers,
  });
}
