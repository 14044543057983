import { FunctionComponent, useState } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import styled from '@emotion/styled';
import { useQuery, gql } from '@apollo/client';

import {
  People as PeopleIcon,
  ExpandLess,
  ExpandMore,
  AddCircle as AddCircleIcon,
  FiberManualRecordRounded as FiberManualRecordRoundedIcon,
} from '@mui/icons-material';

import { APIPermission } from 'typeDeclarations/enums';
import { PermissionsViewDecider } from 'shared/PermissionsViewDecider';
import { hashNumberFromString, teamsColorPalette } from './utils';
import { CreateTeamDialog } from './CreateTeamDialog/CreateTeamDialog';
import { ErrorMessage } from 'shared/ErrorMessage';
import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';
import { NonPaginatedConnection } from 'typeDeclarations/graphql/base-types';

import { List, ListItem as MenuItem, Collapse, Typography, useTheme, ListItemText, ListItemIcon } from '@mui/material';

export const NUM_INITIAL_TEAMS = 3;

const StyledFiberManualRecordRoundedIcon = styled(FiberManualRecordRoundedIcon)`
  transform: scale(0.5);
`;

/**
 * Fragment and type created for the optimization purpose during the login process
 */
export interface TeamsMenuQueryFragmentData {
  sessionUser: Pick<UserNode, 'id' | 'modified' | 'numTeams' | 'isStaff'> & {
    teams: NonPaginatedConnection<Pick<TeamNode, '__typename' | '_id' | 'id' | 'modified' | 'name'>>;
  };
}

export const TEAMS_MENU_QUERY_FRAGMENT = gql`
  fragment teamsMenuQueryFragment on Query {
    sessionUser {
      id
      modified
      numTeams
      isStaff
      teams(first: $teamsAmount, orderBy: "name") {
        edges {
          node {
            id
            _id
            modified
            name
          }
        }
      }
    }
  }
`;

type TeamsMenuQueryData = TeamsMenuQueryFragmentData;

interface TeamsMenuQueryVariables {
  teamsAmount: number;
}

const TEAMS_MENU_QUERY = gql`
  query teamsMenuQuery($teamsAmount: Int!) {
    ...teamsMenuQueryFragment
  }
  ${TEAMS_MENU_QUERY_FRAGMENT}
`;

interface TeamsMenuProps {
  onMoreTeamsClick: () => void;
  onTeamClick: (teamId: string) => void;
}

export const TeamsMenu: FunctionComponent<TeamsMenuProps> = ({ onMoreTeamsClick, onTeamClick }) => {
  const { formatMessage } = useExtendedIntl();
  const [open, setOpen] = useState(false);
  const [openCreateNewTeamDialog, setOpenCreateNewTeamDialog] = useState(false);

  const handleCreateNewTeamDialogClose = () => setOpenCreateNewTeamDialog(false);

  const handleCreateNewTeamDialogOpen = () => setOpenCreateNewTeamDialog(true);

  const theme = useTheme();

  const handleClick = () => setOpen(!open);

  const { loading, error, data } = useQuery<TeamsMenuQueryData, TeamsMenuQueryVariables>(TEAMS_MENU_QUERY, {
    variables: {
      teamsAmount: NUM_INITIAL_TEAMS,
    },
  });

  if (loading) {
    return null;
  }

  if (error || !data) {
    return <ErrorMessage />;
  }

  const { teams, isStaff, numTeams } = data.sessionUser;

  if (!numTeams || numTeams < 2) {
    return null;
  }

  return (
    <>
      <MenuItem button onClick={handleClick}>
        <ListItemIcon>
          <PeopleIcon htmlColor={theme.palette.text.primary} />
        </ListItemIcon>
        <ListItemText>{formatMessage({ id: 'teams-menu.list-item-text' })}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <PermissionsViewDecider requiredPermissions={[APIPermission.PerformChanges]}>
            {isStaff && (
              <>
                <MenuItem button onClick={handleCreateNewTeamDialogOpen}>
                  <ListItemIcon>
                    <AddCircleIcon htmlColor={theme.palette.text.primary} />
                  </ListItemIcon>
                  <Typography variant="body1">{formatMessage({ id: 'create-team-button.title' })}</Typography>
                </MenuItem>
                <CreateTeamDialog open={openCreateNewTeamDialog} onClose={handleCreateNewTeamDialogClose} />
              </>
            )}
          </PermissionsViewDecider>
          {teams.edges.map(({ node: team }) => {
            const teamColorIndex = hashNumberFromString(team._id) % teamsColorPalette.length;
            const teamColor = teamsColorPalette[teamColorIndex];

            return (
              <MenuItem
                button
                key={team.id}
                onClick={(e) => {
                  e.preventDefault();
                  onTeamClick(team.id);
                }}
              >
                <ListItemIcon>
                  <StyledFiberManualRecordRoundedIcon htmlColor={teamColor} />
                </ListItemIcon>
                <ListItemText primary={team.name} />
              </MenuItem>
            );
          })}
          {/* Is the total number of teams greater than number of listed teams? */}
          {numTeams !== null && numTeams > NUM_INITIAL_TEAMS && (
            <MenuItem button onClick={onMoreTeamsClick}>
              <ListItemIcon />
              <ListItemText
                primary={`${numTeams - NUM_INITIAL_TEAMS}
                      ${formatMessage({ id: 'teams-submenu.menu-item.primary-text' })}
                      `}
                secondary={formatMessage({ id: 'teams-submenu.menu-item.secondary-text' })}
              />
            </MenuItem>
          )}
        </List>
      </Collapse>
    </>
  );
};
