import { init as sentryInit, BrowserOptions } from '@sentry/react';

import { config } from 'appConfig';

const BREADCRUMB_MESSAGE_BLACKLIST: string[] = [
  'You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS.',
];

const SENTRY_CONFIG: BrowserOptions = {
  environment: config.buildEnvironment,
  dsn: config.sentryDSN,
  maxValueLength: 250000,
  release: config.release,
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  ignoreErrors: [
    'Error: GraphQL error: You must login to access this endpoint',
    'Error: GraphQL error: CSRF verification failed. Request aborted. CSRF cookie not set.',
    'Network error: Response not successful: Received status code 401',
    'Network error: Response not successful: Received status code 403',
    'Network error: NetworkError when attempting to fetch resource.',
    'Network error: Failed to fetch',
    'Network error: cancelled',
    'cancelled',
    'Load failed',
    'Failed to fetch',
    'Fetch is aborted',
    'The operation was aborted.',
    'The network connection was lost.',
    'Importing a module script failed.',
    'AbortError: The user aborted a request.',
    'error loading dynamically imported module',
    'Failed to fetch dynamically imported module',
    "Can't find variable: __AutoFillPopupClose__",
    'NetworkError when attempting to fetch resource.',
  ],
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.message && BREADCRUMB_MESSAGE_BLACKLIST.includes(breadcrumb.message)) {
      return null;
    }

    return breadcrumb;
  },
};

/**
 * initializes the sentry.io logging service with a default config (if error reporting is enabled)
 */
export function configureSentry(): void {
  if (config.enableErrorReporting) {
    sentryInit(SENTRY_CONFIG);
  }
}
