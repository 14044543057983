import { gql } from '@apollo/client';

import { Node } from 'typeDeclarations/graphql/base-types';
import { PAGE_INFO_FRAGMENT } from 'graphql/fragments';
import {
  PaymentSourceCardStripePaymentSourceData,
  PAYMENT_METHODS_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT,
} from 'pages/TeamManagement/PaymentMethods/PaymentSourceCard/fragments';
import {
  WALLET_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT,
  WalletPaymentSourceStripePaymentSourceData,
} from 'shared/Wallet/WalletPaymentSources/WalletPaymentSource/fragments';

export type PaymentSourceUnionNode = Node &
  (WalletPaymentSourceStripePaymentSourceData | PaymentSourceCardStripePaymentSourceData);

export type PaymentSourceStripePaymentSourceData = WalletPaymentSourceStripePaymentSourceData &
  PaymentSourceCardStripePaymentSourceData;

export const PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT = gql`
  fragment paymentSourceStripePaymentSourceFragment on StripePaymentSourceNode {
    ...walletPaymentSourceStripePaymentSourceFragment
    ...paymentMethodsPaymentSourceStripePaymentSourceFragment
  }
  ${PAYMENT_METHODS_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT}
  ${WALLET_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT}
`;

export const OTHER_PAYMENT_SOURCES_STRIPE_PAYMENT_SOURCES_CONNECTION_FRAGMENT = gql`
  fragment otherPaymentSourcesStripePaymentSourceConnectionFragment on StripePaymentSourceNodeConnection {
    edges {
      cursor
      node {
        id
        modified
        ...paymentSourceStripePaymentSourceFragment
      }
    }
    pageInfo {
      ...pageInfoFragment
    }
  }
  ${PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const PAYMENT_SOURCE_MUTATION_BILLING_PROFILE_FRAGMENT = gql`
  fragment paymentSourceMutationBillingProfileFragment on BillingProfileNode {
    id
    modified
    primaryPaymentSource {
      id
      modified
      stripePaymentSource {
        id
        modified
        ...paymentSourceStripePaymentSourceFragment
      }
    }
    otherStripeCardPaymentSources(first: $amount, after: $cursor, orderBy: "-created") {
      ...otherPaymentSourcesStripePaymentSourceConnectionFragment
    }
  }
  ${PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT}
  ${OTHER_PAYMENT_SOURCES_STRIPE_PAYMENT_SOURCES_CONNECTION_FRAGMENT}
`;
