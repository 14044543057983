import { gql } from '@apollo/client';
import { Edge, PageInfo } from 'typeDeclarations/graphql/base-types';
import { UserNode, ImageFileNode, TeamNode } from 'typeDeclarations/graphql/nodes';

export const TEAM_FORM_USER_FRAGMENT = gql`
  fragment teamFormUserFragment on UserNode {
    id
    name
    email
    picture {
      id
      url
    }
  }
`;

export type TeamFormUserFragmentData = Pick<UserNode, 'id' | 'name' | 'email'> & {
  picture: null | Pick<ImageFileNode, 'id' | 'url'>;
};

export const TEAM_FORM_TEAM_FRAGMENT = gql`
  fragment teamFormTeamFragment on TeamNode {
    id
    name
    owner {
      ...teamFormUserFragment
    }
    paginatedNonOwnerUsers: nonOwnerUsers(
      first: $nonOwnerUsersAmount
      after: $nonOwnerUsersCursor
      orderBy: $nonOwnerUsersOrderBy
      isStaff: $nonOwnerUsersIsStaff
    ) {
      edges {
        node {
          ...teamFormUserFragment
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${TEAM_FORM_USER_FRAGMENT}
`;

export type TeamFormTeamFragmentData = Pick<TeamNode, 'id' | 'name'> & {
  owner: TeamFormUserFragmentData;
  paginatedNonOwnerUsers: {
    edges: Array<Edge<TeamFormUserFragmentData & Pick<UserNode, '__typename'>>>;
    pageInfo: Pick<PageInfo, 'hasNextPage'>;
  };
};
