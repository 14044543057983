import { FunctionComponent } from 'react';
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { TeamNode } from 'typeDeclarations/graphql/nodes';
import { sanitizeInput } from 'utils/sanitizeInput';
import { LoadingBlock } from 'shared/LoadingBlock';
import { ErrorMessage } from 'shared/ErrorMessage';
import { TeamForm, TeamFormValues } from 'shared/TeamForm/TeamForm';
import { TeamFormUserFragmentData, TEAM_FORM_USER_FRAGMENT } from 'shared/TeamForm/fragment';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { DefaultDialogContent } from 'shared/DefaultDialog/DefaultDialog';

const StyledLoadingBlock = styled(LoadingBlock)`
  min-height: 50rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-height: unset;
  }
`;

export const CREATE_TEAM_FORM_QUERY = gql`
  query createTeamFormQuery {
    sessionUser {
      id
      modified
      ...teamFormUserFragment
    }
  }
  ${TEAM_FORM_USER_FRAGMENT}
`;

interface CreateTeamFormQueryData {
  sessionUser: Pick<TeamNode, 'id' | 'modified'> & TeamFormUserFragmentData;
}

const CREATE_TEAM_MUTATION = gql`
  mutation createTeamMutation($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
      }
    }
  }
`;

export interface CreateTeamMutationData {
  createTeam: {
    team: Pick<TeamNode, 'id'>;
  };
}

interface CreateTeamMutationVariables {
  input: {
    name: string;
    ownerId: string;
    userIds: string[];
  };
}

interface CreateTeamFormProps {
  onCancelled?: () => void;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: CreateTeamMutationData) => void;
}

export const CreateTeamForm: FunctionComponent<CreateTeamFormProps> = ({ onError, onCompleted, onCancelled }) => {
  const defaultOnError = useDefaultOnError();

  const {
    data: createTeamFormQueryData,
    error: createTeamFormQueryError,
    loading: createTeamFormQueryLoading,
  } = useQuery<CreateTeamFormQueryData>(CREATE_TEAM_FORM_QUERY, { onError: onError ?? defaultOnError });

  const [createTeam] = useMutation<CreateTeamMutationData, CreateTeamMutationVariables>(CREATE_TEAM_MUTATION, {
    onCompleted,
    fetchPolicy: 'no-cache',
    onError: (err) => onError?.(err) ?? defaultOnError(err),
  });

  if (createTeamFormQueryLoading) {
    return (
      <DefaultDialogContent>
        <StyledLoadingBlock />
      </DefaultDialogContent>
    );
  }

  if (createTeamFormQueryError || !createTeamFormQueryData) {
    return (
      <DefaultDialogContent>
        <ErrorMessage />
      </DefaultDialogContent>
    );
  }

  async function onSubmit(formValues: TeamFormValues) {
    const { teamName, teamOwner, teamUsers } = sanitizeInput(formValues);

    createTeam({
      variables: {
        input: {
          name: teamName,
          ownerId: teamOwner.id,
          userIds: Object.keys(teamUsers),
        },
      },
    });
  }

  const { sessionUser } = createTeamFormQueryData;

  return <TeamForm team={null} user={sessionUser} onSubmit={onSubmit} onCancelled={onCancelled} />;
};
