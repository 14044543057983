import { FunctionComponent } from 'react';
import { Wrapper } from './Wrapper';

import { Button, CircularProgress, ButtonProps, CircularProgressProps } from '@mui/material';

const DEFAULT_SPINNER_SIZE = 24;

export interface ProgressButtonProps extends ButtonProps {
  loading?: boolean;
  spinnerProps?: CircularProgressProps;
}

export const ProgressButton: FunctionComponent<ProgressButtonProps> = (props) => {
  const { style, loading, disabled, className, spinnerProps, ...rest } = props;

  let spinnerSize = DEFAULT_SPINNER_SIZE;

  if (spinnerProps?.size) {
    spinnerSize = Number(spinnerProps.size);
  }

  return (
    <Wrapper style={style} className={className} spinnerSize={spinnerSize}>
      <Button disabled={loading || disabled} {...rest} />
      {loading && <CircularProgress size={spinnerSize} {...spinnerProps} className="spinner" />}
    </Wrapper>
  );
};
