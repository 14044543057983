import { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';

const StyledSpan = styled.span`
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

const StyledCounterSpan = styled.span<{ warning?: boolean }>`
  color: ${({ warning, theme }) => (warning ? theme.palette.warning.main : theme.palette.text.secondary)};
`;

interface CounterHelperTextProps {
  message?: ReactNode;
  currentLength: number;
  maxLength: number | null;
}

export const CounterHelperText: FunctionComponent<CounterHelperTextProps> = ({ currentLength, maxLength, message }) => (
  <StyledSpan>
    <span>{message}</span>
    {maxLength && (
      <StyledCounterSpan warning={currentLength > maxLength}>{`${currentLength}/${maxLength}`}</StyledCounterSpan>
    )}
  </StyledSpan>
);
