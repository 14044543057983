import { LicenseInfo } from '@mui/x-license-pro';
import { StrictMode } from 'react';
import { render } from 'react-dom';

import { config } from 'appConfig';
import { App } from 'components/App/App';
import reportWebVitals from './reportWebVitals';
import { configureSentry } from 'utils/sentry';
import { initTracking } from 'userTracking/useUserTracking';

import './index.css';

/*
 * * * * *
 * FONTS *
 * * * * *
 *
 * MUI default typography configuration only relies on 300, 400, 500, and 700 font weights.
 * https://mui.com/components/typography/
 *
 * These should probably be lazy imports.
 */

// MONTSERRAT
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

// KRUB
import '@fontsource/krub/300.css';
import '@fontsource/krub/400.css';
import '@fontsource/krub/500.css';
import '@fontsource/krub/700.css';

// ROBOTO
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// LATO
// FIXME: Lato does not support font-weight: 500. The app will fallback to 400 when used.
// We may need to change our FCR mui theme font weights. Speak with Diana.
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';

// must be as early as possible
initTracking();
configureSentry();

LicenseInfo.setLicenseKey(config.muiXProLicenseKey);

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
