import { gql } from '@apollo/client';

import { Node } from 'typeDeclarations/graphql/base-types';

export interface CachedBillingProfileSessionTeamData {
  sessionTeam: Node & {
    billingProfile: Node;
  };
}

export const CACHED_BILLING_PROFILE_QUERY = gql`
  query cachedBillingProfileQuery {
    sessionTeam {
      id
      billingProfile {
        id
      }
    }
  }
`;
