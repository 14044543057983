import { gql } from '@apollo/client';
import { BillingProfileNode, CountryNode } from 'typeDeclarations/graphql/nodes';
import { NonPaginatedConnection } from 'typeDeclarations/graphql/base-types';

export type VatFormBillingProfileData = Pick<BillingProfileNode, 'country' | 'vatNumber'>;

export const VAT_FORM_BILLING_PROFILE_FRAGMENT = gql`
  fragment vatFormBillingProfileFragment on BillingProfileNode {
    country
    vatNumber
  }
`;

export type VatFormAllCountriesData = {
  allVatModalCountries: NonPaginatedConnection<Pick<CountryNode, '__typename' | 'id' | '_id' | 'name' | 'selectable'>>;
};

export const VAT_FORM_ALL_COUNTRIES_FRAGMENT = gql`
  fragment vatFormAllCountriesFragment on Query {
    allVatModalCountries: allCountries(orderBy: "name") @connection(key: "allVatModalCountries") {
      edges {
        node {
          id
          _id
          name
          selectable
        }
      }
    }
  }
`;
