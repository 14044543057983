import { gql } from '@apollo/client';

import { CampaignNode } from 'typeDeclarations/graphql/nodes';
import { DRAFT_ISSUE_FRAGMENT } from 'graphql/fragments';

export const LAUNCH_CAMPAIGN_BUTTON_FRAGMENT = gql`
  fragment launchCampaignButtonFragment on CampaignNode {
    id
    isDraft
    draftIssues {
      ...draftIssueFragment
    }
  }
  ${DRAFT_ISSUE_FRAGMENT}
`;

export type LaunchCampaignButtonData = Pick<CampaignNode, 'id' | 'isDraft' | 'draftIssues'>;
