import { CampaignSettingsMap } from './types';

export enum CampaignSetting {
  TouchedSteps = 'touchedSteps',
}

export const DEFAULT_CAMPAIGN_SETTINGS: CampaignSettingsMap = {
  [CampaignSetting.TouchedSteps]: {
    ads: false,
    budget: false,
    summary: false,
    keywords: false,
    audiences: false,
    locations: false,
  },
};
