import { useMutation, MutationHookOptions, gql, MutationTuple } from '@apollo/client';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

const CREATE_OR_UPDATE_FRONTEND_SETTING_MUTATION = gql`
  mutation createOrUpdateFrontendSetting($input: CreateOrUpdateFrontendSettingInput!) {
    createOrUpdateFrontendSetting(input: $input) {
      clientMutationId
      frontendSetting {
        id
        modified
        name
        value
      }
    }
  }
`;

interface CreateOrUpdateFrontendSettingMutationVariables {
  input: {
    name: string;
    value: string;
    frontend: string;
  };
}

export function useCreateOrUpdateFrontendSetting(
  options?: MutationHookOptions<null, CreateOrUpdateFrontendSettingMutationVariables>,
): MutationTuple<null, CreateOrUpdateFrontendSettingMutationVariables> {
  const onError = useDefaultOnError();

  return useMutation(CREATE_OR_UPDATE_FRONTEND_SETTING_MUTATION, {
    onError: (err) => onError(err),
    ...options,
  });
}
