import { gql } from '@apollo/client';

import { BillingProfileNode } from 'typeDeclarations/graphql/nodes';

export type AutoTopUpSwitchBillingProfileData = Pick<BillingProfileNode, '__typename' | 'id' | 'isAutoTopupEnabled'>;

export const AUTO_TOP_UP_SWITCH_BILLING_PROFILE_FRAGMENT = gql`
  fragment autoTopUpSwitchBillingProfileFragment on BillingProfileNode {
    __typename
    id
    isAutoTopupEnabled
  }
`;
