import { ReasonForRejection } from 'utils/facebookSDK';
import { IntlPrimitiveType } from './intl';

export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object';
}

export function objectHasKey<T extends Record<string, unknown>>(obj: T, key: string | number | symbol): key is keyof T {
  return key in obj;
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !isNaN(value);
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isUndefined(value: unknown): value is undefined {
  return typeof value === 'undefined';
}

export function exists<T>(value: T | null | undefined): value is NonNullable<T> {
  return !isUndefined(value) && !isNull(value);
}

export function isIntlPrimitiveType(value: unknown): value is IntlPrimitiveType {
  return isString(value) || isNumber(value) || isBoolean(value) || isUndefined(value) || isDate(value) || isNull(value);
}

export function isError(object: unknown): object is Error {
  if (!isObject(object)) {
    return false;
  }

  // test if object has 'Error' properties
  const errorProperties = ['message', 'name'];
  const hasErrorProperties = errorProperties.every((property) => property in object);

  return hasErrorProperties;
}

export function isFacebookPromiseError(error: unknown): error is FacebookPromiseError {
  if (!isObject(error)) {
    return false;
  }

  // test if object has 'FacebookPromiseError' properties
  const errorProperties = ['message', 'status'];
  const hasErrorProperties = errorProperties.every((property) => property in error);
  return hasErrorProperties;
}

export function isReasonForRejection(obj: unknown): obj is ReasonForRejection {
  return typeof obj === 'object' && obj !== null && 'pageId' in obj && 'errorCode' in obj && 'errorMessage' in obj;
}
