import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
} from '@mui/icons-material';
import { IconButton, styled, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { SnackbarAction, SnackbarKey, SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { createRef, FunctionComponent } from 'react';

const StyledNotistackSnackbarProvider = styled(NotistackSnackbarProvider)`
  &.SnackbarContent-root {
    max-width: 25rem;
    display: flex;
    flex-wrap: nowrap;
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.common.black};

    .SnackbarItem-message {
      svg {
        margin-right: 1rem;
      }
    }
  }

  &.SnackbarItem-variantError {
    background-color: ${({ theme }) => theme.palette.common.black};
    color: ${({ theme }) => theme.palette.common.white};
  }

  &.SnackbarItem-variantInfo {
    background-color: ${({ theme }) => theme.palette.common.black};
    color: ${({ theme }) => theme.palette.common.white};
  }

  &.SnackbarItem-variantWarning {
    background-color: ${({ theme }) => theme.palette.common.black};
    color: ${({ theme }) => theme.palette.common.white};
  }

  &.SnackbarItem-variantSuccess {
    background-color: ${({ theme }) => theme.palette.common.black};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const SnackbarProvider: FunctionComponent = ({ children }) => {
  const notistackRef = createRef<NotistackSnackbarProvider>();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));

  function handleClose(key: SnackbarKey) {
    if (!notistackRef.current) return;

    notistackRef.current.closeSnackbar(key);
  }

  const dismissAction: SnackbarAction = (key) => {
    return (
      <IconButton size="large" color="inherit" aria-label="close" onClick={() => handleClose(key)}>
        <CloseIcon />
      </IconButton>
    );
  };

  return (
    <StyledNotistackSnackbarProvider
      ref={notistackRef}
      action={dismissAction}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isMobile ? 'center' : 'right',
      }}
      iconVariant={{
        info: <InfoOutlinedIcon color="info" />,
        error: <ErrorOutlineIcon color="error" />,
        warning: <ReportProblemOutlinedIcon color="warning" />,
        success: <CheckCircleOutlineIcon color="success" />,
      }}
    >
      {children}
    </StyledNotistackSnackbarProvider>
  );
};
