import { useState, forwardRef } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { TeamsList } from './TeamsList';
import { TeamsSearchForm } from './TeamsSearchForm';

import { Grid, Stack, useTheme, Typography, IconButton } from '@mui/material';

interface TeamsSubmenuProps {
  onBackClick: () => void;
  onTeamClick: (teamId: string) => void;
}

export const TeamsSubmenu = forwardRef<HTMLDivElement, TeamsSubmenuProps>(({ onBackClick, onTeamClick }, ref) => {
  const theme = useTheme();
  const { formatMessage } = useExtendedIntl();
  const [search, setSearch] = useState<string | null>(null);

  return (
    <Stack
      ref={ref}
      rowGap={1}
      direction="column"
      sx={{
        pt: 1,
        pb: 2,
        width: '18rem',
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton onClick={onBackClick} size="large">
            <ArrowBackIcon htmlColor={theme.palette.text.primary} />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <Typography align="center" variant="h6">
            {formatMessage({ id: 'teams-submenu.title' })}
          </Typography>
        </Grid>
      </Grid>
      <TeamsSearchForm onSubmit={(value) => setSearch(value || null)} />
      <TeamsList search={search} onTeamClick={onTeamClick} />
    </Stack>
  );
});
