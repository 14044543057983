import {
  Info as InfoIcon,
  MoreVert as MoreVertIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  Typography,
  Paper,
  Stack,
  IconButton,
  Collapse,
  Button,
  ButtonTypeMap,
  TypographyProps,
  PaperProps,
  StackProps,
  ButtonProps,
  Tooltip,
} from '@mui/material';
import { ReactNode, useState, ElementType } from 'react';

import { ActionsMenu } from 'shared/ActionsMenu/ActionsMenu';
import { Option } from 'shared/ActionsMenu/ActionsMenuItem';

export interface SectionProps<BT extends ElementType = ButtonTypeMap['defaultComponent']> extends PaperProps {
  extra?: ReactNode;
  status?: ReactNode;
  actions?: Option[];
  expandable?: boolean;
  tooltipText?: string;
  defaultExpanded?: boolean;
  contentProps?: StackProps;
  headingProps?: TypographyProps;
  headingEndAdornment?: ReactNode;
  headingStartAdornment?: ReactNode;
  subHeadingProps?: TypographyProps;
  CTAButton?: ButtonProps['children'];
  heading?: TypographyProps['children'];
  subHeading?: TypographyProps['children'];
  mobileButtonsContainerProps?: StackProps;
  mobileButtonsContainer?: 'top' | 'bottom';
  /**
   * This property extends the `ButtonProps` type with the `component` prop typed as `BT` to retain type
   * information of the component Button will act as, ensuring recognition of component-specific props.
   *
   * Example:
   * ```typescript jsx
   * <Section
   *  CTAButtonProps={{
   *    component: RouterLink,
   *    to: "/route",
   *  }}
   * />
   * ```
   *
   * This ensures correct typing and validation for `RouterLink` specific props.
   * The `component` can be replaced with any valid React component.
   *
   * @see {@link https://mui.com/material-ui/guides/composition/#with-typescript}
   */
  CTAButtonProps?: ButtonProps<
    BT,
    {
      component?: BT;
      'data-testid'?: string;
    }
  >;
}

export function Section<BT extends ElementType = ButtonTypeMap['defaultComponent']>({
  sx,
  extra,
  status,
  actions,
  heading,
  children,
  CTAButton,
  subHeading,
  tooltipText,
  headingProps,
  contentProps,
  CTAButtonProps,
  subHeadingProps,
  expandable = false,
  headingEndAdornment,
  headingStartAdornment,
  defaultExpanded = true,
  mobileButtonsContainerProps,
  mobileButtonsContainer = 'top',
  ...rest
}: SectionProps<BT>) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const hasActions = actions && actions.length > 0;

  const headerComponents = [
    status,
    heading,
    headingStartAdornment,
    headingEndAdornment,
    hasActions,
    CTAButton,
    expandable,
    extra,
  ];
  const hasHeader = headerComponents.some(Boolean);

  const mobileButtonsContainerOnBottom = mobileButtonsContainer === 'bottom';

  const tooltip = tooltipText && (
    <Tooltip placement="right-start" title={tooltipText}>
      <IconButton size="small" sx={{ color: 'info.light' }}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  let content: ReactNode;

  if (children) {
    const wrappedChildren = (
      <Stack
        {...contentProps}
        sx={{
          pt: hasActions ? 3 : hasHeader ? 2 : 0,
          pl: {
            xs: 0,
            sm: status ? 5 : 0,
          },
          pr: {
            xs: 0,
            sm: expandable ? 3 : 0,
          },
          ...contentProps?.sx,
        }}
      >
        {children}
      </Stack>
    );

    if (!expandable) {
      content = wrappedChildren;
    } else {
      content = (
        <Collapse in={isExpanded} mountOnEnter>
          {wrappedChildren}
        </Collapse>
      );
    }
  }

  return (
    <Paper
      component="section"
      sx={{
        py: 3,
        px: 3,
        boxShadow: '2px 8px 16px 0px rgba(24, 50, 115, 0.06)',
        ...sx,
      }}
      {...rest}
    >
      {hasHeader && (
        <Stack spacing={2.5}>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={{ xs: 1.5, sm: 2 }}>
              {status}
              {heading && (
                <Stack rowGap={{ xs: 0.5, sm: 1 }}>
                  <Stack direction="row" columnGap={1} alignItems="center">
                    {headingStartAdornment}
                    <Typography variant="h6" data-testid="section.heading" {...headingProps}>
                      {heading}
                    </Typography>
                    {tooltip}
                    {headingEndAdornment}
                  </Stack>
                  {subHeading && (
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      data-testid="section.subHeading"
                      {...subHeadingProps}
                    >
                      {subHeading}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>
            <Stack direction="row" alignItems="center">
              <Stack alignItems="center" direction="row" spacing={{ xs: 1, sm: 2 }}>
                {extra}
                {CTAButton && (
                  <Button
                    size="small"
                    {...CTAButtonProps}
                    sx={{
                      whiteSpace: 'nowrap',
                      ...CTAButtonProps?.sx,
                      display: {
                        xs: 'none',
                        sm: 'flex',
                      },
                    }}
                  >
                    {CTAButton}
                  </Button>
                )}
                {hasActions && (
                  <>
                    <IconButton edge="end" size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
                      <MoreVertIcon />
                    </IconButton>
                    <ActionsMenu options={actions} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
                  </>
                )}
                {expandable && (
                  <IconButton edge="end" size="small" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Stack>
          {CTAButton && !mobileButtonsContainerOnBottom && (
            <Stack
              {...mobileButtonsContainerProps}
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'none',
                },
                ...mobileButtonsContainerProps?.sx,
              }}
            >
              <Button size="small" {...CTAButtonProps} sx={{ whiteSpace: 'nowrap', ...CTAButtonProps?.sx }}>
                {CTAButton}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
      {content}
      {CTAButton && mobileButtonsContainerOnBottom && (
        <Stack
          {...mobileButtonsContainerProps}
          sx={{
            mt: 2.5,
            display: {
              xs: 'flex',
              sm: 'none',
            },
            ...mobileButtonsContainerProps?.sx,
          }}
        >
          <Button size="small" {...CTAButtonProps} sx={{ whiteSpace: 'nowrap', ...CTAButtonProps?.sx }}>
            {CTAButton}
          </Button>
        </Stack>
      )}
    </Paper>
  );
}
