import { config, ThemeName } from 'appConfig';
import { FunctionComponent, ReactElement } from 'react';
import { isUndefined } from 'typeDeclarations/typeGuards';

interface PartnersThemeDeciderProps extends PartialRecord<ThemeName, ReactElement | null> {
  fallback: ReactElement | null;
}

export const PartnersThemeDecider: FunctionComponent<PartnersThemeDeciderProps> = ({
  fallback,
  children, // not used
  ...themeNames
}) => {
  const element = themeNames[config.theme];

  if (isUndefined(element)) {
    return fallback;
  }

  return element;
};
