import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ErrorLoadingPage } from 'shared/ErrorLoadingPage';
import { LocalizedIntlProvider } from 'i18n/LocalizedIntlProvider';
import { LocaleProvider } from 'i18n/LocaleProvider';
import { client } from 'client/client';
import { ThemeProvider } from 'theme/ThemeProvider';
import { AppRouting } from './AppRouting';
import { SnackbarProvider } from './SnackbarProvider';
import { IntercomProvider } from 'react-use-intercom';
import { config } from 'appConfig';

export const App: FunctionComponent = () => (
  <ApolloProvider client={client}>
    <LocaleProvider>
      <ThemeProvider>
        <IntercomProvider appId={config.intercomAppId}>
          <CssBaseline />
          <ErrorBoundary fallback={<ErrorLoadingPage />}>
            <LocalizedIntlProvider>
              <SnackbarProvider>
                <BrowserRouter>
                  <AppRouting />
                </BrowserRouter>
              </SnackbarProvider>
            </LocalizedIntlProvider>
          </ErrorBoundary>
        </IntercomProvider>
      </ThemeProvider>
    </LocaleProvider>
  </ApolloProvider>
);
