import { isCurrentAppTheme } from 'appConfig';

/**
 * @param {[string | null]} userTheme - Comes from the API. It's the app theme that the user has set on their settings.
 */
export function shouldEnableLeadzaiBoot(userTheme?: string | null) {
  if (userTheme) {
    return (
      isCurrentAppTheme('default') && (userTheme === 'default' || userTheme === 'advertio' || userTheme === 'leadzai')
    );
  }

  return isCurrentAppTheme('default');
}
