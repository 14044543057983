import { useState, KeyboardEventHandler, FunctionComponent } from 'react';
import styled from '@emotion/styled';

import { config, themeNames } from 'appConfig';
import { UserTeamAvatar } from 'shared/UserTeamAvatar';

import { HEADER_NAVIGATION_USER_BUTTON_ID } from './constants';
import { HeaderMenuContent } from './HeaderMenuContent/HeaderMenuContent';
import { Popover } from '@mui/material';

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  :focus {
    outline: none;
    :focus-visible {
      outline: 1px solid black;
    }
  }
`;

const StyledUserTeamAvatar = styled(UserTeamAvatar)`
  .team-avatar {
    // FIXME when we implement secondary colors properly
    border-color: ${({ theme }) =>
      config.theme === themeNames.default ? theme.palette.common.black : theme.palette.primary.main};
  }
`;

type Content = 'navigation' | 'teams';

export const HeaderMenuToggle: FunctionComponent = () => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [content, setContent] = useState<Content>('navigation');

  const openPopover = () => setPopoverOpen(true);
  const closePopover = () => setPopoverOpen(false);

  const onUsernameKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      openPopover();
    }
  };

  return (
    <>
      <StyledButton
        id={HEADER_NAVIGATION_USER_BUTTON_ID}
        role="button"
        tabIndex={0}
        onClick={openPopover}
        onKeyPress={onUsernameKeyPress}
        className="header__user-wrapper"
      >
        <StyledUserTeamAvatar />
        <span>&nbsp;&#9660;</span>
      </StyledButton>
      <Popover
        open={isPopoverOpen}
        onClose={closePopover}
        anchorEl={document.getElementById(HEADER_NAVIGATION_USER_BUTTON_ID)}
        anchorOrigin={{
          vertical: 55,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionProps={{
          mountOnEnter: true,
          onExited: () => {
            if (content !== 'navigation') {
              setContent('navigation');
            }
          },
        }}
      >
        <HeaderMenuContent content={content} onClose={closePopover} setContent={setContent} />
      </Popover>
    </>
  );
};
