import { gql, useApolloClient } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

import { UserNode } from 'typeDeclarations/graphql/nodes';
import { isSupportedLocale } from 'i18n/constants';
import { LocaleContext } from 'i18n/LocaleContext';

export interface AppSessionLocaleQueryFragmentData {
  sessionUser: Pick<UserNode, 'id' | 'modified' | 'ddtId' | 'locale'>;
}

export const APP_SESSION_LOCALE_QUERY_FRAGMENT = gql`
  fragment appSessionLocaleQueryFragment on Query {
    sessionUser {
      id
      ddtId
      modified
      locale
    }
  }
`;

type AppSessionLocaleQueryData = AppSessionLocaleQueryFragmentData;

const APP_SESSION_LOCALE_QUERY = gql`
  query appSessionLocaleQuery {
    ...appSessionLocaleQueryFragment
  }
  ${APP_SESSION_LOCALE_QUERY_FRAGMENT}
`;

interface UseAppSessionLocaleReturnValue {
  loading: boolean;
}

export function useAppSessionLocale(): UseAppSessionLocaleReturnValue {
  const client = useApolloClient();

  const { appLocale, setAppLocale } = useContext(LocaleContext);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function handleAppLocale() {
      const { data } = await client.query<AppSessionLocaleQueryData>({
        query: APP_SESSION_LOCALE_QUERY,
      });

      const {
        sessionUser: { locale },
      } = data;

      // It should be safe to return and ignore it, since we never overwrite the default app language
      if (!locale) return;

      const userLocale = locale.replace('_', '-');

      if (userLocale !== appLocale && isSupportedLocale(userLocale)) {
        setAppLocale(userLocale);
      }

      setLoading(false);
    }

    handleAppLocale();
  }, [client, appLocale, setAppLocale]);

  return { loading };
}
