import { gql } from '@apollo/client';

import { StripePaymentSourceNode } from 'typeDeclarations/graphql/nodes';

export type DeletePaymentSourceStripePaymentSourceData = Pick<StripePaymentSourceNode, 'id'>;

export const DELETE_PAYMENT_SOURCE_STRIPE_PAYMENT_SOURCE_FRAGMENT = gql`
  fragment deletePaymentSourceStripePaymentSourceFragment on StripePaymentSourceNode {
    id
  }
`;
