import Cookies from 'js-cookie';
import { isError } from 'typeDeclarations/typeGuards';

import { config } from 'appConfig';
import { logError } from 'utils/logging';

export interface FetchAuthOptions extends RequestInit {
  params?: Record<string, string>;
}

/**
 * fetchAuth is a fetch with our own configs by default.
 * It should be used for simple requests.
 */
export function fetchAuth(endpoint: string, options: FetchAuthOptions): Promise<Response> {
  return new Promise<Response>((resolve, reject) => {
    const url = new URL(endpoint, window.location.href);
    const { params, ...remainingOptions } = options;
    const fetchOptions: RequestInit = { ...remainingOptions };

    if (params) {
      Object.keys(params)
        .filter((name) => params[name] !== undefined)
        .forEach((name) => url.searchParams.append(name, params[name]));
    }

    if (fetchOptions.body !== undefined && !fetchOptions.method) {
      fetchOptions.method = 'POST';
    }

    const headers: HeadersInit = { ...fetchOptions.headers };

    if (!('X-CSRFToken' in headers)) {
      const csrfToken = Cookies.get(config.cookies.csrf);
      if (csrfToken) {
        (headers as Record<string, string>)['X-CSRFToken'] = csrfToken;
      }
    }

    fetch(url.href, {
      credentials: 'include',
      ...fetchOptions,
      headers,
    })
      .then((response) => {
        if (response.ok) {
          resolve(response);
        } else if (response.type === 'opaqueredirect' || response.status === 403) {
          window.location.href = config.routes.login;
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        if (isError(err)) {
          logError(err);
        }
        reject(err);
      });
  });
}
