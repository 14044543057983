import { gql, useQuery, useMutation, useLazyQuery, isApolloError } from '@apollo/client';
import { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useUserTracking } from 'userTracking/useUserTracking';
import { FormValues } from 'hooks/useFormUtils';
import { LoadingBlock } from 'shared/LoadingBlock';
import { ErrorMessage } from 'shared/ErrorMessage';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { Node } from 'typeDeclarations/graphql/base-types';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { APIErrorCode } from 'utils/APIErrorCodes/APIErrorCode';
import { WalletManualTopUpForm } from './WalletManualTopUpForm/WalletManualTopUpForm';
import { BillingProfileNode, StripePaymentSourceNode } from 'typeDeclarations/graphql/nodes';
import {
  WalletFormBillingProfileNode,
  WalletFormCurrencies,
  WALLET_FORM_CURRENCIES_FRAGMENT,
  WALLET_FORM_BILLING_PROFILE_FRAGMENT,
} from './fragments';
import { Typography, Alert, Stack } from '@mui/material';
import { isError } from 'typeDeclarations/typeGuards';
import { APIErrorCodesCatalog } from 'utils/APIErrorCodes/APIErrorCodesCatalog';
import { useStripe } from '@stripe/react-stripe-js';

interface WalletFormValues extends FormValues {
  currency: string;
  topupAmount: string;
}

interface IsPrimaryPaymentSourceChargeableQueryData {
  sessionTeam: Node & {
    modified: string;
    billingProfile: Pick<BillingProfileNode, 'id' | 'modified'> &
      Pick<StripePaymentSourceNode, 'id' | 'modified' | 'isChargeable'>;
  };
}

const IS_PRIMARY_PAYMENT_SOURCE_CHARGEABLE_QUERY = gql`
  query isPrimaryPaymentSourceChargeableQuery {
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        primaryPaymentSource {
          id
          modified
          isChargeable
        }
      }
    }
  }
`;

interface WalletFormQueryData {
  allCurrencies: WalletFormCurrencies;
  sessionTeam: Node & {
    modified: string;
    billingProfile: Node & { modified: string } & WalletFormBillingProfileNode;
  };
}

const WALLET_FORM_QUERY = gql`
  query walletFormQuery {
    ...walletFormCurrenciesFragment
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        ...walletFormBillingProfileFragment
      }
    }
  }
  ${WALLET_FORM_CURRENCIES_FRAGMENT}
  ${WALLET_FORM_BILLING_PROFILE_FRAGMENT}
`;

interface TopupBillingProfileVariables {
  input: {
    amount: string;
    paymentSourceId: string;
    paymentIntentId?: string;
  };
}

const TOPUP_BILLING_PROFILE = gql`
  mutation topupBillingProfile($input: TopupBillingProfileInput!) {
    topupBillingProfile(input: $input) {
      billingProfile {
        id
        balance
        modified
      }
    }
  }
`;

interface WalletManualTopUpProps {
  disabled: boolean;
}

// FIXME
const MAXIMUM_AMOUNT_DEFAULT = '5000';

export const WalletManualTopUp: FunctionComponent<WalletManualTopUpProps> = ({ disabled }) => {
  const stripe = useStripe();
  const onError = useDefaultOnError();
  const { enqueueSnackbar } = useSnackbar();
  const { captureEvent } = useUserTracking();
  const { formatMessage, formatNumber } = useExtendedIntl();

  const [getIsChargeablePrimaryPaymentSource] = useLazyQuery<IsPrimaryPaymentSourceChargeableQueryData>(
    IS_PRIMARY_PAYMENT_SOURCE_CHARGEABLE_QUERY,
  );

  const { data, error, loading } = useQuery<WalletFormQueryData>(WALLET_FORM_QUERY, { onError });

  const [topUpBalance, { loading: mutationLoading }] = useMutation<unknown, TopupBillingProfileVariables>(
    TOPUP_BILLING_PROFILE,
    {
      onError: (errors) => {
        onError(errors);
        getIsChargeablePrimaryPaymentSource();
      },
      onCompleted: () => {
        enqueueSnackbar(formatMessage({ id: 'wallet-manual-top-up.success-message' }), { variant: 'success' });
      },
    },
  );

  if (loading) return <LoadingBlock />;
  if (error || !data) return <ErrorMessage />;

  const {
    allCurrencies,
    sessionTeam: { billingProfile },
  } = data;

  const { currency, primaryPaymentSource, topupAmountLimits } = billingProfile;

  const onSubmitHandler = async (values: WalletFormValues) => {
    if (!primaryPaymentSource) return;

    const { topupAmount } = values;

    await topUpBalance({
      variables: {
        input: {
          amount: topupAmount,
          paymentSourceId: primaryPaymentSource.id,
        },
      },
      onError: (errors) => {
        const { graphQLErrors } = errors;
        const errorsCatalog = new APIErrorCodesCatalog(graphQLErrors);
        const actionErrorData = errorsCatalog.getErrorData(APIErrorCode.ChargeRequiresActions);

        if (actionErrorData) {
          if (!stripe) {
            throw new Error('No stripe');
          }

          captureEvent({ eventName: 'cardAction3dSecureRequest' });

          stripe.handleCardAction(actionErrorData.payment_intent_client_secret).then(() => {
            captureEvent({ eventName: 'cardAction3dSecureConfirmation' });
            topUpBalance({
              variables: {
                input: {
                  amount: topupAmount,
                  paymentSourceId: primaryPaymentSource.id,
                  paymentIntentId: actionErrorData.payment_intent_id,
                },
              },
            });
          });
        }

        if (errors && !actionErrorData) {
          onError(errors);
          getIsChargeablePrimaryPaymentSource();
        }

        if (!graphQLErrors?.length && (!isError(graphQLErrors) || !isApolloError(graphQLErrors))) {
          captureEvent({
            eventName: 'topUp',
            data: {
              currency,
              amount: topupAmount,
            },
          });
        }
      },
    });
  };

  const { minimumAmount, maximumAmount } = topupAmountLimits ?? {
    minimumAmount: '0',
    maximumAmount: MAXIMUM_AMOUNT_DEFAULT,
  };

  const validate = (values: WalletFormValues) => {
    const { currency: selectedCurrency, topupAmount } = values;

    if (Number(topupAmount) === 0) {
      return { topupAmount: formatMessage({ id: 'wallet-manual-top-up.provide-amount' }) };
    }

    if (Number(topupAmount) < Number(minimumAmount) || Number(topupAmount) > Number(maximumAmount)) {
      const minimum = formatNumber(Number(minimumAmount), {
        style: 'currency',
        currency: selectedCurrency,
      });

      const maximum = formatNumber(Number(maximumAmount), {
        style: 'currency',
        currency: selectedCurrency,
      });

      return { topupAmount: formatMessage({ id: 'topup-amount.error' }, { minimum, maximum }) };
    }

    return {};
  };

  const initialValues: WalletFormValues = {
    currency,
    topupAmount: '',
  };

  return (
    <Stack rowGap={2}>
      <Typography variant="subtitle2" fontWeight="bold">
        {formatMessage({ id: 'wallet.top-up' })}
      </Typography>
      <Formik<WalletFormValues> validate={validate} onSubmit={onSubmitHandler} initialValues={initialValues}>
        {({ handleSubmit, errors }) => (
          <>
            {errors.generic && <Alert severity="error">{errors.generic}</Alert>}
            <WalletManualTopUpForm
              disabled={disabled}
              loading={mutationLoading}
              handleSubmit={handleSubmit}
              allCurrencies={allCurrencies}
              billingProfile={billingProfile}
            />
          </>
        )}
      </Formik>
    </Stack>
  );
};
