import { FunctionComponent, MouseEvent, useState } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import styled from '@emotion/styled';
import { Stars as StarsIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useIsMobileResolution } from 'hooks/useIsMobileResolution';

import { PermissionsViewDecider } from 'shared/PermissionsViewDecider';
import { APIPermission } from 'typeDeclarations/enums';
import { TeamUser } from 'shared/TeamForm/types';

import {
  Menu,
  Chip,
  Avatar,
  ListItem,
  MenuItem,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@mui/material';

const StyledTeamOwnerChip = styled(Chip)`
  background-color: rgb(61, 58, 189);
  margin-right: 1rem;

  .MuiChip-label {
    color: ${({ theme }) => theme.palette.common.white};
  }

  .MuiChip-icon {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

const StyledListItemAvatar = styled(ListItemAvatar)`
  display: flex;
  justify-content: center;
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  right: 1rem;
`;

interface TeamUserListItemProps {
  canEdit?: boolean;
  isAdmin?: boolean;
  teamUser: TeamUser;
  handleMakeAdmin: (owner: TeamUser) => void;
  handleDeleteTeamUser: (owner: TeamUser) => void;
}

export const TeamUserListItem: FunctionComponent<TeamUserListItemProps> = ({
  teamUser,
  canEdit = false,
  isAdmin = false,
  handleMakeAdmin,
  handleDeleteTeamUser,
}) => {
  const { formatMessage } = useExtendedIntl();
  const isMobile = useIsMobileResolution();

  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(userMenuAnchor);

  const handleCloseUserMenu = () => setUserMenuAnchor(null);

  const handleOpenUserMenu = (e: MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(e.currentTarget);
  };

  return (
    <ListItem disableGutters>
      <StyledListItemAvatar>
        <Avatar src={teamUser.picture?.url} imgProps={{ loading: 'lazy' }}>
          {teamUser.picture ? teamUser.name[0]?.toUpperCase() || teamUser.email[0] || 'U' : null}
        </Avatar>
      </StyledListItemAvatar>
      <ListItemText
        primary={teamUser.name}
        secondary={teamUser.email}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />
      <PermissionsViewDecider requiredPermissions={[APIPermission.PerformChanges]}>
        {isAdmin ? (
          <StyledTeamOwnerChip
            icon={<StarsIcon />}
            size={isMobile ? 'small' : 'medium'}
            label={formatMessage({ id: 'shared.admin' })}
          />
        ) : (
          canEdit && (
            <StyledListItemSecondaryAction>
              <IconButton onClick={handleOpenUserMenu} size="large">
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                open={menuOpen}
                anchorEl={userMenuAnchor}
                onClose={handleCloseUserMenu}
                TransitionProps={{ mountOnEnter: true }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => handleMakeAdmin(teamUser)}>
                  {formatMessage({ id: 'team-form.make-admin' })}
                </MenuItem>
                <MenuItem onClick={() => handleDeleteTeamUser(teamUser)}>
                  {formatMessage({ id: 'team-form.delete-user' })}
                </MenuItem>
              </Menu>
            </StyledListItemSecondaryAction>
          )
        )}
      </PermissionsViewDecider>
    </ListItem>
  );
};
