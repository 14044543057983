import { CampaignContext } from 'pages/Campaign/CampaignContext/CampaignContext';
import { STEP_MATCH_PARAM } from 'pages/Campaign/CampaignRouter/constants';
import { CampaignStep, useGetOrderedCampaignsSteps } from 'pages/Campaign/useGetOrderedCampaignsSteps';
import { getCampaignStepURL } from 'pages/Campaign/utils/getCampaignStepURL';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

interface RouteParams {
  step: CampaignStep;
  campaignPrettyId: string;
}

interface Step {
  url: string;
  name: CampaignStep;
}

export function useGetCurrentCampaignStep(): Step {
  const { campaignPrettyId, campaignId } = useContext(CampaignContext);
  const orderedSteps = useGetOrderedCampaignsSteps({ campaignUglyId: campaignId, campaignPrettyId });

  const routeMatch = useRouteMatch<RouteParams>({
    path: `/campaign/:campaignPrettyId/${STEP_MATCH_PARAM}`,
  });

  let step: Step;

  if (!routeMatch) {
    const name = orderedSteps[0];

    step = {
      name,
      url: getCampaignStepURL(name, campaignPrettyId),
    };
  } else {
    const { step: name } = routeMatch.params;
    const url = getCampaignStepURL(name, campaignPrettyId);

    step = {
      url,
      name,
    };
  }

  return step;
}
