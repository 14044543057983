import { FunctionComponent, lazy, Suspense } from 'react';

import styled from '@emotion/styled';
import { Header } from 'components/Header/Header';

import { useUtilsBoot } from 'hooks/useUtilsBoot';
import { PrivateRoute } from 'shared/PrivateRoute';
import { APIPermission } from 'typeDeclarations/enums';
import { Redirect, Switch, useParams } from 'react-router-dom';
import { Route } from 'shared/Route';
import { useAppSessionLocale } from 'hooks/useAppSessionLocale';
import { ReferralAnnouncement } from 'shared/ReferralAnnouncement';
import { PermissionsViewDecider } from 'shared/PermissionsViewDecider';
import { useRedirectUserThemePartner } from 'hooks/useRedirectUserThemePartner';
import { useShouldShowFirstBusinessPrompt } from 'hooks/useShouldShowFirstBusinessPrompt';
import { CircularProgress } from '@mui/material';
import { useShouldShowACS } from 'components/Campaign/useShouldShowACS';
import { WalletDialog } from 'shared/Wallet/WalletDialog';
import { CampaignProvider } from 'pages/Campaign/CampaignContext/CampaignProvider';
import { SessionContext } from './SessionContext';
import { useSessionQuery } from '../../../hooks/queries/useSessionQuery';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const LazyExpiredLinkPage = lazy(() =>
  import('components/pages/ExpiredLink').then(({ ExpiredLink }) => ({ default: ExpiredLink })),
);

const LazyFirstBusinessPage = lazy(() =>
  import('pages/FirstBusiness/FirstBusinessPage/FirstBusinessPage').then(({ FirstBusinessPage }) => ({
    default: FirstBusinessPage,
  })),
);

const LazyAllCampaignsPage = lazy(() =>
  import('pages/AdvertisersAndCampaigns/Campaigns/CampaignsPage/CampaignsPage').then(({ CampaignsPage }) => ({
    default: CampaignsPage,
  })),
);

const LazyAllAdvertisersPage = lazy(() =>
  import('pages/AdvertisersAndCampaigns/Advertisers/AdvertisersPage/AdvertisersPage').then(({ AdvertisersPage }) => ({
    default: AdvertisersPage,
  })),
);

const LazyAccountSettingsPage = lazy(() =>
  import('pages/AccountSettings/AccountSettings').then(({ AccountSettingsPage }) => ({ default: AccountSettingsPage })),
);

const LazyTeamManagementPage = lazy(() =>
  import('pages/TeamManagement/TeamManagement').then(({ TeamManagementPage }) => ({ default: TeamManagementPage })),
);

const LazyTeamDashboard = lazy(() =>
  import('pages/TeamDashboard/TeamDashboard').then(({ TeamDashboard }) => ({ default: TeamDashboard })),
);

const LazyCampaignPage = lazy(() =>
  import('pages/Campaign/Campaign').then(({ CampaignPage }) => ({ default: CampaignPage })),
);

const LazyBudgetEstimatorPage = lazy(() =>
  import('pages/BudgetEstimator/BudgetEstimator').then(({ BudgetEstimator }) => ({ default: BudgetEstimator })),
);

const LazyNotFoundPage = lazy(() => import('components/NotFound').then(({ NotFound }) => ({ default: NotFound })));

const LazyCampaignDashboardReport = lazy(() =>
  import('components/print/CampaignDashboard/CampaignDashboardReport').then(({ CampaignDashboardReport }) => ({
    default: CampaignDashboardReport,
  })),
);

const LazyLegacyCampaignSummaryPrint = lazy(() =>
  import('components/print/LegacyCampaignSummary/CampaignSummaryPrint').then(({ CampaignSummaryPrint }) => ({
    default: CampaignSummaryPrint,
  })),
);

const LazyCampaignSummaryPrint = lazy(() =>
  import('components/print/CampaignSummary/CampaignSummaryPrint').then(({ CampaignSummaryPrint }) => ({
    default: CampaignSummaryPrint,
  })),
);

const Loading: FunctionComponent = () => (
  <StyledDiv>
    <CircularProgress size="5rem" thickness={2.5} />
  </StyledDiv>
);

const CampaignSummaryPrint = () => {
  const { campaignPrettyId } = useParams<{ campaignPrettyId: string }>();

  const shouldShowACS = useShouldShowACS({ campaignPrettyId });

  return (
    <CampaignProvider>
      {shouldShowACS ? <LazyLegacyCampaignSummaryPrint /> : <LazyCampaignSummaryPrint />}
    </CampaignProvider>
  );
};

export const PrivateAppRouting: FunctionComponent = () => {
  useUtilsBoot();

  const { data: sessionData, loading: loadingSessionData, error: sessionDataError } = useSessionQuery();

  const { loading: useRedirectUserThemePartnerLoading, error: useRedirectUserThemePartnerError } =
    useRedirectUserThemePartner();

  const { loading: shouldShowFirstBusinessLoading, error: shouldShowFirstBusinessError } =
    useShouldShowFirstBusinessPrompt();

  const { loading: gettingAppLocale } = useAppSessionLocale();

  if (loadingSessionData || useRedirectUserThemePartnerLoading || shouldShowFirstBusinessLoading || gettingAppLocale)
    return <Loading />;

  // Handled by the error boundary in Providers.tsx
  if (useRedirectUserThemePartnerError) throw useRedirectUserThemePartnerError;
  if (shouldShowFirstBusinessError) throw shouldShowFirstBusinessError;

  if (sessionDataError) throw sessionDataError;
  if (!sessionData) return <Redirect to="/auth/login" />;

  const { sessionTeam, sessionUser } = sessionData;

  return (
    <SessionContext.Provider value={{ team: sessionTeam, user: sessionUser }}>
      <Switch>
        <PrivateRoute path="/print/campaign-dashboard/:campaignPrettyId">
          <CampaignProvider>
            <LazyCampaignDashboardReport />
          </CampaignProvider>
        </PrivateRoute>
        <PrivateRoute component={CampaignSummaryPrint} path="/print/campaign-summary/:campaignPrettyId" />
        <Route path="*">
          <Header />
          <Suspense fallback={<Loading />}>
            <Switch>
              <PrivateRoute path="/link-has-expired" component={LazyExpiredLinkPage} />
              <PrivateRoute path="/first-business" component={LazyFirstBusinessPage} />
              <PrivateRoute path="/campaigns" component={LazyAllCampaignsPage} />
              <PrivateRoute path="/businesses" component={LazyAllAdvertisersPage} />
              <PrivateRoute path="/account-settings" component={LazyAccountSettingsPage} />
              <PrivateRoute path="/team/billing" component={LazyTeamManagementPage} />
              <PrivateRoute path="/team/dashboard" component={LazyTeamDashboard} />
              <PrivateRoute path="/campaign/:campaignPrettyId" component={LazyCampaignPage} />
              <PrivateRoute
                path="/budget-estimator"
                children={
                  <PermissionsViewDecider
                    fallback={<Redirect to="/not-found" />}
                    requiredPermissions={[APIPermission.EstimatedCostPerGoalWithUrl]}
                  >
                    <LazyBudgetEstimatorPage />
                  </PermissionsViewDecider>
                }
              />
              <Route path="*" component={LazyNotFoundPage} />
            </Switch>
          </Suspense>
        </Route>
      </Switch>
      {/* TODO: REMOVE ReferralAnnouncement IN THE FUTURE??? */}
      <ReferralAnnouncement />
      <WalletDialog />
    </SessionContext.Provider>
  );
};
