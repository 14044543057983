import { gql } from '@apollo/client';
import { DraftIssueFragmentData, DRAFT_ISSUE_FRAGMENT } from 'graphql/fragments';
import { PageInfo, NonPaginatedConnection } from 'typeDeclarations/graphql/base-types';
import {
  AdInterfaceNode,
  CampaignNode,
  AdvertiserNode,
  BudgetNode,
  BingResponsiveSearchAdHeadlineNode,
  BingResponsiveSearchAdDescriptionNode,
  FacebookCarouselAdCardInterface,
  InstagramCarouselAdCardInterface,
} from 'typeDeclarations/graphql/nodes';

export type CampaignContextProviderLocationsCampaignFragmentData = {
  draftIssues: DraftIssueFragmentData[];
  zeroNormalLocations: {
    pageInfo: Pick<PageInfo, 'hasNextPage'>;
  };
};

export const CAMPAIGN_CONTEXT_PROVIDER_LOCATIONS_CAMPAIGN_FRAGMENT = gql`
  fragment campaignContextProviderLocationsCampaignFragment on CampaignNode {
    draftIssues {
      ...draftIssueFragment
    }
    zeroNormalLocations: targetingLocations(first: 0) {
      pageInfo {
        hasNextPage
      }
    }
  }
  ${DRAFT_ISSUE_FRAGMENT}
`;

type AdInterface = Pick<AdInterfaceNode, 'id' | 'modified' | '__typename' | 'draftIssues'>;

export type BingResponsiveSearchAdIssuesData = AdInterface & {
  headlines: NonPaginatedConnection<
    Pick<BingResponsiveSearchAdHeadlineNode, '__typename' | 'id'> & {
      draftIssues: DraftIssueFragmentData[];
    }
  >;
  descriptions: NonPaginatedConnection<
    Pick<BingResponsiveSearchAdDescriptionNode, '__typename' | 'id'> & {
      draftIssues: DraftIssueFragmentData[];
    }
  >;
};

export type GoogleResponsiveSearchAdIssuesData = BingResponsiveSearchAdIssuesData;

export type InstagramCarouselAdIssuesData = AdInterface & {
  cards: NonPaginatedConnection<
    Pick<InstagramCarouselAdCardInterface, 'id' | '__typename'> & { draftIssues: DraftIssueFragmentData[] }
  >;
};

export type FacebookCarouselAdIssuesData = AdInterface & {
  cards: NonPaginatedConnection<
    Pick<FacebookCarouselAdCardInterface, 'id' | '__typename'> & { draftIssues: DraftIssueFragmentData[] }
  >;
};

export type CampaignContextProviderAdsCampaignFragmentData = {
  draftIssues: DraftIssueFragmentData[];
  campaignProviderAds: NonPaginatedConnection<
    AdInterface | FacebookCarouselAdIssuesData | BingResponsiveSearchAdIssuesData | GoogleResponsiveSearchAdIssuesData
  >;
};

export const CAMPAIGN_CONTEXT_PROVIDER_ADS_CAMPAIGN_FRAGMENT = gql`
  fragment campaignContextProviderAdsCampaignFragment on CampaignNode {
    draftIssues {
      ...draftIssueFragment
    }
    campaignProviderAds: ads(first: null) {
      edges {
        node {
          id
          modified
          draftIssues {
            ...draftIssueFragment
          }
          ... on FacebookCarouselAdNode {
            cards(orderBy: "position") {
              edges {
                node {
                  id
                  modified
                  draftIssues {
                    ...draftIssueFragment
                  }
                }
              }
            }
          }
          ... on InstagramCarouselAdNode {
            cards(orderBy: "position") {
              edges {
                node {
                  id
                  modified
                  draftIssues {
                    ...draftIssueFragment
                  }
                }
              }
            }
          }
          ... on BingResponsiveSearchAdNode {
            headlines(first: null) {
              edges {
                node {
                  id
                  modified
                  draftIssues {
                    ...draftIssueFragment
                  }
                }
              }
            }
            descriptions(first: null) {
              edges {
                node {
                  id
                  modified
                  draftIssues {
                    ...draftIssueFragment
                  }
                }
              }
            }
          }
          ... on GoogleResponsiveSearchAdNode {
            headlines(first: null) {
              edges {
                node {
                  id
                  modified
                  draftIssues {
                    ...draftIssueFragment
                  }
                }
              }
            }
            descriptions(first: null) {
              edges {
                node {
                  id
                  modified
                  draftIssues {
                    ...draftIssueFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${DRAFT_ISSUE_FRAGMENT}
`;

export type CampaignContextProviderTGsCampaignFragmentData = {
  draftIssues: DraftIssueFragmentData[];
  zeroTargetingGroups: {
    pageInfo: Pick<PageInfo, 'hasNextPage'>;
  };
};

export const CAMPAIGN_CONTEXT_PROVIDER_TGS_CAMPAIGN_FRAGMENT = gql`
  fragment campaignContextProviderTGsCampaignFragment on CampaignNode {
    draftIssues {
      ...draftIssueFragment
    }
    zeroTargetingGroups: targetingGroups(first: 0) {
      pageInfo {
        hasNextPage
      }
    }
  }
  ${DRAFT_ISSUE_FRAGMENT}
`;

export type CampaignContextProviderKwsCampaignFragmentData = {
  draftIssues: DraftIssueFragmentData[];
  zeroKeywords: {
    pageInfo: Pick<PageInfo, 'hasNextPage'>;
  };
};

export const CAMPAIGN_CONTEXT_PROVIDER_KWS_CAMPAIGN_FRAGMENT = gql`
  fragment campaignContextProviderKwsCampaignFragment on CampaignNode {
    draftIssues {
      ...draftIssueFragment
    }
    zeroKeywords: keywordRelations(first: 0) {
      pageInfo {
        hasNextPage
      }
    }
  }
  ${DRAFT_ISSUE_FRAGMENT}
`;

export type CampaignContextProviderBudgetCampaignFragmentData = {
  draftIssues: DraftIssueFragmentData[];
  budget:
    | null
    | (Pick<BudgetNode, 'id' | 'modified' | 'amount'> & {
        draftIssues: DraftIssueFragmentData[];
      });
};

export const CAMPAIGN_CONTEXT_PROVIDER_BUDGET_CAMPAIGN_FRAGMENT = gql`
  fragment campaignContextProviderBudgetCampaignFragment on CampaignNode {
    draftIssues {
      ...draftIssueFragment
    }
    budget {
      id
      modified
      amount
      draftIssues {
        ...draftIssueFragment
      }
    }
  }
  ${DRAFT_ISSUE_FRAGMENT}
`;

export type CampaignContextProviderCampaignFragmentData = Pick<
  CampaignNode,
  | 'id'
  | '_id'
  | 'goal'
  | 'isDraft'
  | 'modified'
  | 'isPerformance'
  | 'externalCampaignId'
  | 'draftStepsCompleted'
  | 'trackerInstallStatus'
  | 'shouldUseGoogleTagManager'
> &
  CampaignContextProviderAdsCampaignFragmentData &
  CampaignContextProviderTGsCampaignFragmentData &
  CampaignContextProviderKwsCampaignFragmentData &
  CampaignContextProviderBudgetCampaignFragmentData &
  CampaignContextProviderLocationsCampaignFragmentData & {
    draftIssues: DraftIssueFragmentData[];
    advertiser:
      | null
      | (Pick<AdvertiserNode, 'id' | 'modified'> & {
          draftIssues: DraftIssueFragmentData[];
        });
  };

export const CAMPAIGN_CONTEXT_PROVIDER_CAMPAIGN_FRAGMENT = gql`
  fragment campaignContextProviderCampaignFragment on CampaignNode {
    id
    _id
    goal
    isDraft
    modified
    isPerformance
    externalCampaignId
    draftStepsCompleted
    trackerInstallStatus
    shouldUseGoogleTagManager
    ...campaignContextProviderAdsCampaignFragment
    ...campaignContextProviderTGsCampaignFragment
    ...campaignContextProviderKwsCampaignFragment
    ...campaignContextProviderBudgetCampaignFragment
    ...campaignContextProviderLocationsCampaignFragment
    draftIssues {
      ...draftIssueFragment
    }
    advertiser {
      id
      modified
      draftIssues {
        ...draftIssueFragment
      }
    }
  }
  ${DRAFT_ISSUE_FRAGMENT}
  ${CAMPAIGN_CONTEXT_PROVIDER_ADS_CAMPAIGN_FRAGMENT}
  ${CAMPAIGN_CONTEXT_PROVIDER_TGS_CAMPAIGN_FRAGMENT}
  ${CAMPAIGN_CONTEXT_PROVIDER_KWS_CAMPAIGN_FRAGMENT}
  ${CAMPAIGN_CONTEXT_PROVIDER_BUDGET_CAMPAIGN_FRAGMENT}
  ${CAMPAIGN_CONTEXT_PROVIDER_LOCATIONS_CAMPAIGN_FRAGMENT}
`;
