import { useCallback, useContext } from 'react';
import { DataObject } from 'utils/APIErrorCodes/types';
import { IntlPrimitiveType } from 'typeDeclarations/intl';
import { isAPIErrorCode } from 'utils/APIErrorCodes/utils';
import { APIErrorCode } from 'utils/APIErrorCodes/APIErrorCode';
import { APIErrorCodesCatalog } from 'utils/APIErrorCodes/APIErrorCodesCatalog';
import { useIntl, FormatNumberOptions, IntlShape, FormatDateOptions, IntlFormatters } from 'react-intl';
import {
  DisplayPlatformPrettyId,
  AdvertisingPlatformPrettyId,
  FacebookCallToActionPrettyId,
  AdsState,
  CurrencyPrettyId,
} from 'typeDeclarations/graphql/nodes';
import { isIntlPrimitiveType, isNull, isObject, isUndefined } from 'typeDeclarations/typeGuards';
import { logError } from 'utils/logging';
import { SessionContext } from 'components/App/session/SessionContext';
import { BASE_ADS_STATE_TO_VARIANT_MAP, NON_STAFF_ADS_STATE_TO_VARIANT_MAP } from 'utils/adStates';
import { useFeatureFlags } from 'featureFlags/useFeatureFlags';

const DEFAULT_PLACEHOLDER_STRING = '--';
export const DEFAULT_MISSING_TRANSLATION = 'MISSING_TRANSLATION';

export function isRecordOfIntlPrimitiveTypes(obj: unknown): obj is Record<string, IntlPrimitiveType> {
  if (isObject(obj) && !isNull(obj)) {
    return Object.values(obj).every((value) => isIntlPrimitiveType(value));
  }

  return false;
}

export interface FormatNullableNumberOptions extends FormatNumberOptions {
  placeholderString?: string;
}

export interface FormatInvalidValueArgs {
  fieldName: string;
  value: IntlPrimitiveType;
}

export type ErrorFormatterFunction<E extends APIErrorCode> = (data: DataObject<E>) => string;

export type ErrorFormattersMap = {
  [K in APIErrorCode]?: ErrorFormatterFunction<K>;
};

export type ParamsFormatMessage = Parameters<IntlFormatters['formatMessage']>;

export interface ExtendedIntlShape extends IntlShape {
  formatAdState: (state: AdsState) => string;
  formatBoolean: (bool: boolean) => string;
  formatModelType: (modelType: string) => string;
  formatFieldName: (fieldName: string) => string;
  formatGender: (genderPrettyId: string) => string;
  formatCountry: (countryPrettyId: string) => string;
  formatSocialProviders: (provider: string) => string;
  formatLanguage: (languagePrettyId: string) => string;
  formatVertical: (verticalPrettyId: string) => string;
  formatMessage: (...args: ParamsFormatMessage) => string;
  formatAoICategory: (categoryPrettyId: string) => string;
  formatDeviceType: (deviceTypePrettyId: string) => string;
  formatCurrency: (currencyPrettyId: CurrencyPrettyId) => string;
  formatChildVertical: (childVerticalPrettyId: string) => string;
  formatDisplayPlatform: (displayPlatformPrettyId: DisplayPlatformPrettyId) => string;
  formatFacebookCallToAction: (callToActionPrettyId: FacebookCallToActionPrettyId) => string;
  formatAdvertisingPlatform: (advertisingPlatformPrettyId: AdvertisingPlatformPrettyId) => string;
  formatPercentageValue: (value: string | number, opts?: Omit<FormatNumberOptions, 'unit'>) => string;
  formatMoneyValue: (value: string | number | null, currency: string | null) => string;
  formatNullableNumber: (value: number | null, opts?: FormatNullableNumberOptions) => string;
  formatCampaignGoal: (goalPrettyId: string, options?: { plural?: boolean }) => string;
  formatTimeRange: (from: string | number | Date, to: string | number | Date, opts?: FormatDateOptions) => string;
  formatScheduleDateRange: (args: {
    opts?: FormatDateOptions;
    endDate: string | number | Date | null;
    startDate: string | number | Date | null;
  }) => string;
  formatAPIErrorCode: <E extends APIErrorCode>(args: {
    errorCode: E;
    data: DataObject<E>;
    fallbackMessage?: string;
  }) => string;
  formatTimeDuration: (value: number) => string;
}

export function useExtendedIntl(): ExtendedIntlShape {
  const intl = useIntl();
  const { isFeatureEnabled } = useFeatureFlags();
  const {
    user: { isStaff },
  } = useContext(SessionContext);
  const { formatDate, formatTime, formatNumber, formatMessage: intlFormatMessage } = intl;

  const formatMessage: ExtendedIntlShape['formatMessage'] = useCallback(
    (descriptor, ...args) => {
      return intlFormatMessage(
        {
          defaultMessage: DEFAULT_MISSING_TRANSLATION,
          ...descriptor,
        },
        ...args,
      );
    },
    [intlFormatMessage],
  );

  const formatBoolean: ExtendedIntlShape['formatBoolean'] = useCallback(
    (bool) => formatMessage({ id: `shared.${bool ? 'yes' : 'no'}` }),
    [formatMessage],
  );

  const formatFieldName: ExtendedIntlShape['formatFieldName'] = useCallback(
    (fieldName) => formatMessage({ id: `field-name.${fieldName}` }),
    [formatMessage],
  );

  const formatModelType: ExtendedIntlShape['formatModelType'] = useCallback(
    (modelType) => formatMessage({ id: `model-type.${modelType}`, defaultMessage: 'undefined' }),
    [formatMessage],
  );

  const formatAdState: ExtendedIntlShape['formatAdState'] = useCallback(
    (state) => {
      const stateVariant = isStaff ? BASE_ADS_STATE_TO_VARIANT_MAP[state] : NON_STAFF_ADS_STATE_TO_VARIANT_MAP[state];

      return formatMessage({ id: `state-variant.${stateVariant}` });
    },
    [formatMessage, isStaff],
  );

  const formatAPIErrorCode: ExtendedIntlShape['formatAPIErrorCode'] = useCallback(
    ({ data, errorCode, fallbackMessage = formatMessage({ id: 'shared.default-error-message' }) }) => {
      // helper function for formatting an error code and validating the intl values object.
      // this is to avoid code repetition.
      function formatErrorCode(errCode: APIErrorCode, values?: Record<string, unknown>): string {
        if (isUndefined(values)) {
          return formatMessage({
            id: `error.api.${errCode}`,
            defaultMessage: fallbackMessage,
          });
        }

        if (isRecordOfIntlPrimitiveTypes(values)) {
          return formatMessage(
            {
              id: `error.api.${errCode}`,
              defaultMessage: fallbackMessage,
            },
            values,
          );
        }

        logError(
          new Error(`The following provided values are not of type 'IntlPrimitiveType': ${JSON.stringify(values)}`),
        );

        return fallbackMessage;
      }

      // This map creates relations between error codes and formatting functions specific for each
      // error code
      const errorFormattersMap: ErrorFormattersMap = {
        [APIErrorCode.ModelOperationFailed]: (errorData) => {
          const errorsCatalog = new APIErrorCodesCatalog([{ extensions: errorData.error }]);
          const catalogedErrorCodes = errorsCatalog.getErrorCodes();

          // values do not matter in this case, the keys are the translated errors that will be
          // displayed to the user
          const errorList: { [key: string]: undefined } = {};

          catalogedErrorCodes.forEach((errCode) => {
            if (!isAPIErrorCode(errCode)) {
              errorList[fallbackMessage] = undefined;

              logError(new Error(`Unknown error code '${errCode}'`));
            } else {
              const errData = errorsCatalog.getErrorData(errCode);

              if (!errData) {
                errorList[fallbackMessage] = undefined;
              } else {
                errorList[
                  formatAPIErrorCode({
                    data: errData,
                    fallbackMessage,
                    errorCode: errCode,
                  })
                ] = undefined;
              }
            }
          });

          return Object.keys(errorList).join('; ');
        },
        [APIErrorCode.InvalidValueStringContainsInvalidCharacters]: (errorData) => {
          const { invalid_characters: invalidCharacters, name } = errorData;

          const joinedInvalidCharacters = invalidCharacters.map((char) => `"${char}"`).join(', ');

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueStringContainsInvalidCharacters, {
            fieldName,
            joinedInvalidCharacters,
          });
        },
        [APIErrorCode.InvalidValueWordStartsWithInvalidCharacters]: (errorData) => {
          const { invalid_characters: invalidCharacters, name } = errorData;

          const joinedInvalidCharacters = invalidCharacters.map((char) => `"${char}"`).join(', ');

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueWordStartsWithInvalidCharacters, {
            fieldName,
            joinedInvalidCharacters,
          });
        },
        [APIErrorCode.InvalidValueStringTooLong]: (errorData) => {
          const { name, max_length: maxLength } = errorData;

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueStringTooLong, {
            fieldName,
            maxLength,
          });
        },
        [APIErrorCode.InvalidValueStringTooShort]: (errorData) => {
          const { name, min_length: minLength } = errorData;

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueStringTooShort, {
            fieldName,
            minLength,
          });
        },
        [APIErrorCode.UnfulfilledPinningPositions]: (errorData) => {
          const { field_name: name, empty_positions: emptyPositions, num_missing_pins: numMissingPins } = errorData;

          const fieldName = formatFieldName(name);
          const pluralEmptyPositions = emptyPositions.length > 1;
          const pluralNumMissingPins = numMissingPins > 1;

          const joinedEmptyPositions = emptyPositions.map((pos) => `"${pos}"`).join(', ');

          return formatErrorCode(APIErrorCode.UnfulfilledPinningPositions, {
            fieldName,
            numMissingPins,
            joinedEmptyPositions,
            pluralEmptyPositions,
            pluralNumMissingPins,
          });
        },
        [APIErrorCode.InvalidValueOutsideBounds]: (errorData) => {
          const {
            value,
            name: fieldName,
            min_value: minValue,
            max_value: maxValue,
            max_comparator: maxComparator,
            min_comparator: minComparator,
          } = errorData;

          const conditions: string[] = [];

          if (!isNull(minValue)) {
            conditions.push(formatMessage({ id: `comparator.${minComparator}` }, { value: minValue }));
          }

          if (!isNull(maxValue)) {
            conditions.push(formatMessage({ id: `comparator.${maxComparator}` }, { value: maxValue }));
          }

          let condition: string | null = null;

          if (conditions.length) {
            condition = conditions.join(` ${formatMessage({ id: 'shared.and' })} `);
          }

          const formattedFieldName = formatFieldName(fieldName);

          return formatErrorCode(APIErrorCode.InvalidValueOutsideBounds, {
            value,
            condition,
            fieldName: formattedFieldName,
          });
        },
        [APIErrorCode.MaxEntriesExceeded]: (errorData) => {
          const { field_name: fieldName, model_type: modelType, max_entries: maxEntries } = errorData;

          let type = 'none';
          const hasMaxEntries = maxEntries ?? false;

          const formattedFieldName = formatFieldName(fieldName);
          const formattedModelType = formatModelType(modelType);

          if (!isUndefined(formattedFieldName) && !isUndefined(formattedModelType)) {
            type = 'full';
          } else if (!isUndefined(formattedFieldName)) {
            type = 'fieldName';
          } else if (!isUndefined(formattedModelType)) {
            type = 'model';
          }

          return formatErrorCode(APIErrorCode.MaxEntriesExceeded, {
            type,
            maxEntries,
            hasMaxEntries,
            modelType: formattedModelType,
            fieldName: formattedFieldName,
          });
        },
        [APIErrorCode.InvalidValue]: (errorData) => {
          const { name, value } = errorData;

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValue, { fieldName, value });
        },
        [APIErrorCode.DuplicateValue]: (errorData) => {
          const { field_name: fieldName, model_type: modelType, container_type: containerType } = errorData;

          let type;
          let hasFieldName = fieldName !== 'id';
          const formattedModelType = formatModelType(modelType);
          const formattedContainerType = formatModelType(containerType);
          const formattedFieldName = formatFieldName(fieldName);

          if (formattedFieldName === 'undefined') {
            hasFieldName = false;
          }

          if (formattedModelType !== 'undefined') {
            type = 'model';
          }

          if (formattedContainerType !== 'undefined') {
            type = 'container';
          }

          if (formattedModelType !== 'undefined' && formattedContainerType !== 'undefined') {
            type = 'full';
          }

          return formatErrorCode(APIErrorCode.DuplicateValue, {
            type,
            hasFieldName: hasFieldName,
            fieldName: formattedFieldName,
            modelType: formattedModelType,
            containerType: formattedContainerType,
          });
        },
        [APIErrorCode.IncompatibleValues]: (errorData) => {
          const { field_names: fieldNames, field_values: fieldValues } = errorData;

          const formattedString = fieldNames
            .map((fieldName, i) => {
              const formattedFieldName = formatFieldName(fieldName);
              const fieldValue = fieldValues[i];
              return `${formattedFieldName}=${fieldValue}`;
            })
            .join(', ');

          return formatErrorCode(APIErrorCode.IncompatibleValues, { fieldsAndValues: formattedString });
        },
        [APIErrorCode.InvalidSymbolRepetition]: (errorData) => {
          const { invalid_substring: invalidSubstring } = errorData;

          return formatErrorCode(APIErrorCode.InvalidSymbolRepetition, {
            invalidSubstring,
          });
        },
      };

      // the cast is necessary otherwise the type of the data argument in the format function gets
      // butchered as an intersection of DataObject's
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const format = errorFormattersMap[errorCode] as ErrorFormatterFunction<typeof errorCode> | undefined;

      if (format) {
        return format(data);
      }

      // if a specific format function doesn't exist for the error code, fallback to the
      // formatErrorCode() function
      return formatErrorCode(errorCode, data as Record<string, unknown>);
    },
    [formatFieldName, formatMessage, formatModelType],
  );

  const formatNullableNumber: ExtendedIntlShape['formatNullableNumber'] = useCallback(
    (value, opts) => {
      if (isNull(value)) {
        return opts?.placeholderString ?? DEFAULT_PLACEHOLDER_STRING;
      }

      return formatNumber(value, opts);
    },
    [formatNumber],
  );

  const formatGender: ExtendedIntlShape['formatGender'] = useCallback(
    (genderPrettyId) => formatMessage({ id: `gender.${genderPrettyId.toLowerCase()}` }),
    [formatMessage],
  );

  const formatCountry: ExtendedIntlShape['formatCountry'] = useCallback(
    (countryPrettyId) => formatMessage({ id: `country.${countryPrettyId}` }),
    [formatMessage],
  );

  const formatDeviceType: ExtendedIntlShape['formatDeviceType'] = useCallback(
    (deviceTypePrettyId) => formatMessage({ id: `device-type.${deviceTypePrettyId}` }),
    [formatMessage],
  );

  const formatAoICategory: ExtendedIntlShape['formatAoICategory'] = useCallback(
    (categoryPrettyId) => formatMessage({ id: `area-of-interest-category.${categoryPrettyId}` }),
    [formatMessage],
  );

  const formatLanguage: ExtendedIntlShape['formatLanguage'] = useCallback(
    (languagePrettyId) => formatMessage({ id: `language.${languagePrettyId}` }),
    [formatMessage],
  );

  const formatCurrency: ExtendedIntlShape['formatCurrency'] = useCallback(
    (currencyPrettyId) => formatMessage({ id: `currency.${currencyPrettyId}` }),
    [formatMessage],
  );

  const formatVertical: ExtendedIntlShape['formatVertical'] = useCallback(
    (verticalPrettyId) => formatMessage({ id: `vertical.${verticalPrettyId}` }),
    [formatMessage],
  );

  const formatChildVertical: ExtendedIntlShape['formatChildVertical'] = useCallback(
    (childVerticalPrettyId) => formatMessage({ id: `child-vertical.${childVerticalPrettyId}` }),
    [formatMessage],
  );

  const formatCampaignGoal: ExtendedIntlShape['formatCampaignGoal'] = useCallback(
    (goalPrettyId, options) => {
      // not plural by default
      const plural = Boolean(options?.plural);
      return formatMessage(
        {
          id: `campaign-goal.${goalPrettyId !== 'clicks' && isFeatureEnabled('leads') ? 'leads' : goalPrettyId}`,
        },
        { plural },
      );
    },
    [formatMessage, isFeatureEnabled],
  );

  const formatTimeRange: ExtendedIntlShape['formatTimeRange'] = useCallback(
    (to, from, opts) => {
      const start = formatTime(to, opts);
      const end = formatTime(from, opts);

      return `${start} - ${end}`;
    },
    [formatTime],
  );

  const formatScheduleDateRange: ExtendedIntlShape['formatScheduleDateRange'] = useCallback(
    ({ startDate, endDate, opts }) => {
      const start = startDate ? formatDate(startDate, opts) : null;
      const end = endDate ? formatDate(endDate, opts) : null;
      const dateRangeString = formatMessage(
        { id: 'schedule.date-range' },
        {
          start,
          end,
        },
      );

      return dateRangeString;
    },
    [formatDate, formatMessage],
  );

  const formatAdvertisingPlatform: ExtendedIntlShape['formatAdvertisingPlatform'] = useCallback(
    (advertisingPlatformPrettyId) =>
      formatMessage({
        id: `advertisingPlatform.${advertisingPlatformPrettyId}`,
      }),
    [formatMessage],
  );

  const formatFacebookCallToAction: ExtendedIntlShape['formatFacebookCallToAction'] = useCallback(
    (callToActionPrettyId) =>
      formatMessage({
        id: `facebook-call-to-action.${callToActionPrettyId}`,
      }),
    [formatMessage],
  );

  const formatSocialProviders: ExtendedIntlShape['formatSocialProviders'] = useCallback(
    (provider) =>
      formatMessage({
        id: `social-providers.${provider}`,
      }),
    [formatMessage],
  );

  const formatDisplayPlatform: ExtendedIntlShape['formatDisplayPlatform'] = useCallback(
    (displayPlatformPrettyId) =>
      formatMessage({
        id: `displayPlatform.${displayPlatformPrettyId}`,
      }),
    [formatMessage],
  );

  const formatPercentageValue: ExtendedIntlShape['formatPercentageValue'] = useCallback(
    (value, opts) =>
      formatNumber(Number(value), {
        style: 'unit',
        unit: 'percent',
        maximumFractionDigits: 2,
        ...opts,
      }),
    [formatNumber],
  );

  const formatMoneyValue: ExtendedIntlShape['formatMoneyValue'] = useCallback(
    (value, currency) => {
      if (isNull(value) || isNull(currency)) return DEFAULT_PLACEHOLDER_STRING;

      return formatNumber(Number(value), {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2,
      });
    },
    [formatNumber],
  );

  // Value is in miliseconds
  const formatTimeDuration: ExtendedIntlShape['formatTimeDuration'] = useCallback((value) => {
    const totalSeconds = Math.floor(value / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const ms = value % 1000;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}:${ms.toString().padStart(3, '0')}`;
  }, []);

  return {
    ...intl,
    formatGender,
    formatAdState,
    formatBoolean,
    formatMessage,
    formatCountry,
    formatVertical,
    formatLanguage,
    formatCurrency,
    formatFieldName,
    formatModelType,
    formatTimeRange,
    formatMoneyValue,
    formatDeviceType,
    formatAoICategory,
    formatAPIErrorCode,
    formatTimeDuration,
    formatCampaignGoal,
    formatChildVertical,
    formatNullableNumber,
    formatSocialProviders,
    formatDisplayPlatform,
    formatPercentageValue,
    formatScheduleDateRange,
    formatAdvertisingPlatform,
    formatFacebookCallToAction,
  };
}
