import { FunctionComponent, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ErrorMessage } from 'shared/ErrorMessage';
import { TeamNode, BillingProfileNode } from 'typeDeclarations/graphql/nodes';
import { WALLET_AUTO_TOPUP_BILLING_PROFILE_FRAGMENT, WalletAutoTopupBillingProfileData } from './fragments';
import { AutoTopUpAccordionForm } from './AutoTopUpAccordionForm/AutoTopUpAccordionForm';
import { AutoTopUpSwitch } from './AutoTopUpSwitch/AutoTopUpSwitch';
import { Accordion, AccordionSummary, AccordionDetails, LinearProgress } from '@mui/material';

interface WalletAutoTopupQueryData {
  sessionTeam: Pick<TeamNode, 'id' | 'modified'> & {
    billingProfile: WalletAutoTopupBillingProfileData & Pick<BillingProfileNode, 'id' | 'modified'>;
  };
}

const WALLET_AUTO_TOPUP_QUERY = gql`
  query walletAutoTopupQuery {
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        ...walletAutoTopUpAccordionBillingProfileFragment
      }
    }
  }
  ${WALLET_AUTO_TOPUP_BILLING_PROFILE_FRAGMENT}
`;

interface WalletAutoTopupProps {
  disabled: boolean;
}

export const WalletAutoTopup: FunctionComponent<WalletAutoTopupProps> = ({ disabled }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { data, loading, error } = useQuery<WalletAutoTopupQueryData>(WALLET_AUTO_TOPUP_QUERY);

  if (loading) return <LinearProgress />;
  if (error || !data) return <ErrorMessage />;

  const { billingProfile } = data.sessionTeam;

  const toggleExpanded = () => setExpanded((prevState) => !prevState);

  const closePanel = () => setExpanded(false);

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      disabled={disabled}
      onChange={toggleExpanded}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary id="recurring-payment-header" aria-controls="recurring-payment-content" sx={{ pl: 0 }}>
        <AutoTopUpSwitch disabled={disabled} expanded={expanded} billingProfile={billingProfile} />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, flexDirection: 'column' }}>
        <AutoTopUpAccordionForm onClose={closePanel} billingProfile={billingProfile} />
      </AccordionDetails>
    </Accordion>
  );
};
