import { gql } from '@apollo/client';
import { BillingProfileNode } from 'typeDeclarations/graphql/nodes';

export type VatBillingProfileData = Pick<BillingProfileNode, 'currency' | 'hasConfirmedVatNumber'>;

export const VAT_BILLING_PROFILE_FRAGMENT = gql`
  fragment vatBillingProfileFragment on BillingProfileNode {
    currency
    hasConfirmedVatNumber
  }
`;
