import { FunctionComponent } from 'react';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: max-content;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
        width: 100%;
        max-width: 100%;
    }
  `}
`;

interface FetchMoreWrapperProps {
  className?: string;
}

export const FetchMoreWrapper: FunctionComponent<FetchMoreWrapperProps> = ({ className, children }) => (
  <StyledDiv className={className}>{children}</StyledDiv>
);
