import { gql } from '@apollo/client';

import { BillingProfileNode, TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';

export type NavigationMenuHeaderBillingProfileData = Pick<
  BillingProfileNode,
  'balance' | 'currency' | 'hasCreditLine' | 'creditAvailable'
>;

export const NAVIGATION_MENU_HEADER_BILLING_PROFILE_FRAGMENT = gql`
  fragment navigationMenuHeaderBillingProfileFragment on BillingProfileNode {
    balance
    currency
    hasCreditLine
    creditAvailable
  }
`;

/**
 * Fragment and type created for the optimization purpose during the login process
 */
export interface NavigationMenuHeaderQueryFragmentData {
  sessionUser: Pick<UserNode, 'id' | 'modified'>;
  sessionTeam: Pick<TeamNode, 'id' | 'modified' | 'name'> & {
    billingProfile: Pick<BillingProfileNode, 'id' | 'modified'> & NavigationMenuHeaderBillingProfileData;
  };
}

export const NAVIGATION_MENU_HEADER_QUERY_FRAGMENT = gql`
  fragment navigationMenuHeaderQueryFragment on Query {
    sessionUser {
      id
      modified
    }
    sessionTeam {
      id
      modified
      name
      billingProfile {
        id
        modified
        ...navigationMenuHeaderBillingProfileFragment
      }
    }
  }
  ${NAVIGATION_MENU_HEADER_BILLING_PROFILE_FRAGMENT}
`;
