import { gql } from '@apollo/client';

import {
  BillingProfileNode,
  PaymentSourceNode,
  BillingProfileTopupAmountLimitsNode,
} from 'typeDeclarations/graphql/nodes';

import {
  TopupInputBillingProfileNode,
  TopupInputCurrencies,
  TOPUP_INPUT_BILLING_PROFILE_FRAGMENT,
  TOPUP_INPUT_CURRENCIES_FRAGMENT,
} from './WalletManualTopUpForm/fragments';

export const WALLET_FORM_BILLING_PROFILE_FRAGMENT = gql`
  fragment walletFormBillingProfileFragment on BillingProfileNode {
    ...topupInputBillingProfileFragment
    currency
    primaryPaymentSource {
      id
      modified
    }
    topupAmountLimits {
      minimumAmount
      maximumAmount
    }
  }
  ${TOPUP_INPUT_BILLING_PROFILE_FRAGMENT}
`;

export type WalletFormBillingProfileNode = TopupInputBillingProfileNode &
  Pick<BillingProfileNode, 'currency'> & {
    primaryPaymentSource: null | Pick<PaymentSourceNode, 'id' | 'modified'>;
    topupAmountLimits: null | Pick<BillingProfileTopupAmountLimitsNode, 'minimumAmount' | 'maximumAmount'>;
  };

export const WALLET_FORM_CURRENCIES_FRAGMENT = gql`
  fragment walletFormCurrenciesFragment on Query {
    ...topupInputCurrenciesFragment
  }
  ${TOPUP_INPUT_CURRENCIES_FRAGMENT}
`;

export type WalletFormCurrencies = TopupInputCurrencies;
