import { CSSProperties, ReactNode } from 'react';
import styled from '@emotion/styled';

interface WrapperProps {
  spinnerSize: number;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

export const Wrapper = styled(({ spinnerSize, ...rest }: WrapperProps) => <div {...rest} />)`
  width: fit-content;
  position: relative;

  .spinner {
    position: absolute;
    top: calc(50% - ${({ spinnerSize }) => spinnerSize / 2}px);
    left: calc(50% - ${({ spinnerSize }) => spinnerSize / 2}px);
  }

  button {
    width: 100%;
    height: 100%;
  }

  & path {
    fill: currentColor;
  }
`;
