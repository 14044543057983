import { FunctionComponent } from 'react';
import { CircularProgress, SxProps, Box } from '@mui/material';

interface LoadingBlockProps {
  sx?: SxProps;
  className?: string;
}

export const LoadingBlock: FunctionComponent<LoadingBlockProps> = ({ sx, className }) => (
  <Box
    className={className}
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx,
    }}
  >
    <CircularProgress />
  </Box>
);
