import { partnerThemeVar, permissionsVar, settingsVar } from 'client/cache';
import { FunctionComponent, useEffect } from 'react';
import { RouteProps, Redirect } from 'react-router-dom';
import { Route } from 'shared/Route';
import { APIPermission } from 'typeDeclarations/enums';
import { isNull, isString } from 'typeDeclarations/typeGuards';
import { logError } from 'utils/logging';
import { parseJSONScalar } from 'utils/parseJsonScalar';
import { MAX_SETTINGS } from 'utils/Settings/constants';
import { AppSettingsMap } from 'utils/Settings/types';
import { isAppSetting } from 'utils/Settings/utils';
import { useSessionQuery } from 'hooks/queries/useSessionQuery';
import { ThemeName, config, themeNames } from 'appConfig';
import { useSearchParamsDictionary } from 'utils/searchParams';

export const PrivateRoute: FunctionComponent<RouteProps> = (props) => {
  const searchParams = useSearchParamsDictionary();

  const { data, loading, error } = useSessionQuery();

  // FIXME This useEffect should be removed in the future.
  useEffect(() => {
    if (!data) return;

    /**
     * @author bra
     * FIXME
     * App permissions are calculated only when a user is logged-in and they haven't
     * been set up yet.
     * For now i'm leaving this code here given the presence of the session query
     * but its location must be discussed in a near future because this code
     * might be running unnecessarily every time when all private routes are rendered
     */
    const { getContextPermissions, sessionUserTeamRelation } = data;

    // Changes the theme in case the user has a different theme
    const newTheme = data.sessionUser.theme;
    if (newTheme && (newTheme as ThemeName) in themeNames) {
      partnerThemeVar(newTheme as ThemeName);
    } else {
      partnerThemeVar(config.theme);
    }

    /* PERMISSIONS */
    const permissions: PartialRecord<APIPermission, boolean> = {};

    getContextPermissions.forEach((permissionNode) => {
      permissions[permissionNode._id] = true;
    });

    permissionsVar(permissions);

    /* SETTINGS */

    if (!isNull(sessionUserTeamRelation)) {
      const {
        frontendSettings: {
          edges: settingEdges,
          pageInfo: { hasNextPage: hasMoreSettings },
        },
      } = sessionUserTeamRelation;

      const settings: AppSettingsMap = {};

      settingEdges.forEach(({ node: { name: settingId, value: settingValue } }) => {
        if (isAppSetting(settingId)) {
          settings[settingId] = parseJSONScalar(settingValue);
        }
      });

      settingsVar(settings);

      if (hasMoreSettings) {
        logError(new Error(`More than ${MAX_SETTINGS} settings are currently saved`));
      }
    }
  }, [data]);

  if (loading) {
    return null;
  }

  if (error || !data?.isLoggedIn) return <Redirect to="/auth/login" />;
  if (searchParams.redirect && isString(searchParams.redirect)) return <Redirect to={searchParams.redirect} />;
  if (data.sessionUser.ddtId) return <Redirect to="/direct" />;

  return <Route {...props} />;
};
