const nlNLMessages: Record<string, string> = {
  'error-loading-page.text':
    'Uh-oh, het lijkt erop dat we niet in staat zijn om deze sectie te laden. Het spijt ons, kunt u het alsjeblieft nog eens proberen?',
  'error-loading-page.button': 'Probeer het nog eens.',
  'shared.something-went-wrong-message': 'Ooops!{br}Something went wrong!',
  'shared.something-went-wrong-description':
    'We konden die link niet vinden op de app.{br}Controleer op typefouten of ga terug naar het dashboard van de campagne.',
};

export default nlNLMessages;
