import { gql } from '@apollo/client';
import { createContext } from 'react';
import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';
import { DEFAULT_LANGUAGE } from '../../../i18n/constants';

export const SESSION_CONTEXT_QUERY_FRAGMENT = gql`
  fragment sessionContextQueryFragment on Query {
    sessionUser {
      id
      modified
      ddtId
      theme
      locale
      isStaff
    }
    sessionTeam {
      id
      modified
    }
  }
`;

export interface SessionContextQueryFragmentData {
  user: Pick<UserNode, 'id' | 'ddtId' | 'theme' | 'locale' | 'isStaff'>;
  team: Pick<TeamNode, 'id'>;
}

export const SessionContext = createContext<SessionContextQueryFragmentData>({
  team: {
    id: 'default',
  },
  user: {
    id: 'default',
    ddtId: 'default',
    isStaff: false,
    locale: DEFAULT_LANGUAGE,
    theme: 'default',
  },
});
