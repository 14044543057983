import { FunctionComponent } from 'react';
import { Alert, AlertProps, Box, Typography } from '@mui/material';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { walletVar } from 'shared/Wallet/WalletDialog';
import { BudgetIssue } from './hooks/useCampaignBudgetWalletIssues';

interface InsufficientFundsWarningAlertProps extends AlertProps {
  issue: BudgetIssue;
  showLink?: boolean;
  showDescription?: boolean;
}

export const InsufficientFundsWarningAlert: FunctionComponent<InsufficientFundsWarningAlertProps> = ({
  issue,
  showLink = true,
  showDescription = true,
  ...props
}) => {
  const { formatMessage, formatMoneyValue } = useExtendedIntl();

  const { id, balance, currency, minimumAmount } = issue;

  return (
    <Alert {...props} severity="error">
      <Typography fontWeight={700}>
        {formatMessage(
          { id: `budget.${id}` },
          {
            amount: formatMoneyValue(minimumAmount - balance, currency),
            span: (...chunks: unknown[]) =>
              showLink ? (
                <Box
                  component="span"
                  onClick={() => walletVar({ isOpen: true, issue })}
                  sx={{
                    cursor: 'pointer',
                    color: 'primary.main',
                  }}
                >
                  {chunks}
                </Box>
              ) : (
                <span>{chunks}</span>
              ),
          },
        )}
      </Typography>
      {showDescription && formatMessage({ id: 'budget.charging-info' })}
    </Alert>
  );
};
