import { gql } from '@apollo/client';

import { BillingProfileNode } from 'typeDeclarations/graphql/nodes';

export type CreditLineCardBillingProfileData = Pick<BillingProfileNode, 'currency' | 'creditUsed' | 'creditAvailable'>;

export const CREDIT_LINE_CARD_BILLING_PROFILE_FRAGMENT = gql`
  fragment creditLineCardBillingProfileFragment on BillingProfileNode {
    currency
    creditUsed
    creditAvailable
  }
`;
