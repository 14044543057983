import { onError } from '@apollo/client/link/error';
import { withScope } from '@sentry/react';
import Cookies from 'js-cookie';

import { APIErrorCode } from 'utils/APIErrorCodes/APIErrorCode';
import { config } from 'appConfig';
import { APIErrorCodesCatalog } from 'utils/APIErrorCodes/APIErrorCodesCatalog';
import { logError } from 'utils/logging';
import { HttpLink, ApolloLink, from as apolloLinkFrom, ServerError, ServerParseError } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { RetryLink } from '@apollo/client/link/retry';
import { OperationDefinitionNode } from 'graphql';
import { isError, isNull } from 'typeDeclarations/typeGuards';
import { isDate } from 'date-fns';
import { resetTracking } from 'userTracking/useUserTracking';

const customFetch: WindowOrWorkerGlobalScope['fetch'] = (uri, options) => {
  const result = fetch(uri, options);
  result.then((response) => {
    const contentType = response.headers.get('content-type');

    let errorMessage: undefined | string;

    if (isNull(contentType)) {
      errorMessage = 'Instead of JSON, request has no content type/response data';
    } else if (contentType !== 'application/json') {
      response
        .clone()
        .text()
        .then((text) => {
          errorMessage = `Instead of JSON, received request whose content is of type '${contentType}': ${text}`;
        });
    }

    if (errorMessage) {
      logError(new Error(errorMessage));
    }
  });

  return result;
};

const httpLink = new HttpLink({
  fetch: customFetch,
  uri: config.routes.api,
  credentials: 'include',
  headers: {
    accept: 'application/json',
    'X-CSRFToken': Cookies.get(config.cookies.csrf) ?? '',
  },
});

/**
 * see {@link https://www.apollographql.com/docs/react/api/link/introduction#handling-a-response  }
 */
const roundTripLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  operation.setContext((prevContext: Record<string, unknown>) => ({
    ...prevContext,
    startTs: new Date(),
  }));

  return forward(operation).map((data) => {
    // Called after server responds
    operation.setContext((prevContext: Record<string, unknown>) => ({
      ...prevContext,
      endTs: new Date(),
    }));

    return data;
  });
});

/**
 * see {@link https://www.apollographql.com/docs/react/api/link/apollo-link-retry/}
 * RetryLink does not (currently) handle retries for GraphQL errors in the response, only for network errors.
 */
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      if (!isError(error)) {
        return false;
      }

      if (!error || !('statusCode' in error)) {
        return false;
      }

      const { statusCode } = error as ServerParseError | ServerError;

      // these status codes are handled properly on the onError link
      if (statusCode === 401 || statusCode === 403) {
        return false;
      }

      // time to discover the operation type (query, mutation, subscription)
      const {
        getContext,
        query: { definitions },
      } = operation;

      if (!definitions.length) {
        return false;
      }

      const operationDefinition = definitions.find(({ kind }) => kind === 'OperationDefinition');

      if (!operationDefinition) {
        return false;
      }

      const opDef = operationDefinition as OperationDefinitionNode;

      // only graphql query (non-idempotent) operations should be retried
      if (opDef.operation !== 'query') {
        return false;
      }

      // all 4.x.x error codes should be immediately retried
      if (statusCode >= 400 && statusCode < 500) {
        return true;
      }

      if (statusCode >= 500 && statusCode < 600) {
        const opContext = getContext();

        const { startTs, endTs } = opContext;

        if (isDate(startTs) && isDate(endTs)) {
          const elapsedTimeInSec = Math.floor((endTs - startTs) / 1000);

          // only retry requests that took up to 1min
          if (elapsedTimeInSec <= 60) {
            return true;
          }
        }
      }

      return false;
    },
  },
});

function loginRedirect() {
  window.location.href = config.routes.login;
}

const errorLink = onError(({ response, operation, networkError, graphQLErrors }) => {
  // handle redirect if it's an auth fail
  if (graphQLErrors) {
    const errorsCatalog = new APIErrorCodesCatalog(graphQLErrors);

    /**
     * @author bra
     * The exception 'operation.operationName !== "loginMutation"' is for now needed because
     * the login mutation can bring more data which can only be retrieved if the user is logged-in
     * otherwise, the backend sends a NotLoggedIn error for instance if wrong credentials were
     * inserted. This optimization will be debated soon™️ because exceptions likes this will
     * inevitably degrade the code quality
     */
    if (
      errorsCatalog.hasErrorCode(APIErrorCode.CSRFFailed) ||
      (errorsCatalog.hasErrorCode(APIErrorCode.NotLoggedIn) && operation.operationName !== 'loginMutation')
    ) {
      resetTracking();
      loginRedirect();
      return;
    }
  }

  if (networkError && 'statusCode' in networkError) {
    const { statusCode } = networkError;

    if (statusCode === 401 || statusCode === 403) {
      loginRedirect();
      return;
    }
  }

  // if it's not an auth fail, handle error log
  const { operationName, query, variables } = operation;

  // filter out user errors
  const filteredGQLErrors = graphQLErrors?.filter(({ extensions }) => !extensions?.is_user_error);

  //HACK for prepareCampaignTreeMutation error handling
  if (
    operationName === 'prepareCampaignTreeMutation' &&
    response?.errors?.length &&
    response?.errors?.every((err) => err.extensions?.error_code === 'operation_error')
  ) {
    return;
  }

  withScope((scope) => {
    scope.setLevel('fatal');
    scope.setTag('context', 'GraphQL Operation');
    scope.setExtras({
      operationName,
      operationVariables: JSON.stringify(variables),
    });

    if (response) {
      scope.setExtra('operationResponse', JSON.stringify(response));
    }

    const operationBody = query.loc?.source.body;

    if (operationBody) {
      scope.setExtra('operationBody', JSON.stringify(operationBody));
    }

    scope.setFingerprint(['{{ default }}', operationName]);

    // in the case of a networkError, graphQLErrors is an alias for networkError.result.errors
    // https://www.apollographql.com/docs/link/links/error/#error-categorization
    if (filteredGQLErrors?.length) {
      scope.setExtra('errors', JSON.stringify(filteredGQLErrors));
    }

    if (networkError) {
      scope.setExtra('networkErrorStack', networkError.stack);

      if ('statusCode' in networkError) {
        scope.setExtra('statusCode', networkError.statusCode);
      }

      if ('bodyText' in networkError) {
        scope.setExtra('bodyText', networkError.bodyText);
      }

      const error = new ApolloError({ networkError });

      error.name = 'Network error';

      logError(error);
    } else if (filteredGQLErrors?.length) {
      const error = new ApolloError({ graphQLErrors: filteredGQLErrors });

      error.name = 'GraphQL error';

      logError(error);
    }
  });
});

export const link = apolloLinkFrom([errorLink, retryLink, roundTripLink, httpLink]);
