import { StripeError, StripeErrorType, PaymentIntent, PaymentMethod, SetupIntent, Source } from '@stripe/stripe-js';

export class ThrowableStripeError extends Error implements StripeError {
  name: string;

  type: StripeErrorType;

  charge?: string;

  code?: string;

  declineCode?: string;

  docUrl?: string;

  param?: string;

  paymentIntent?: PaymentIntent;

  paymentMethod?: PaymentMethod;

  setupIntent?: SetupIntent;

  source?: Source;

  constructor(stripeErrorObject: StripeError) {
    super(stripeErrorObject.message);

    this.name = 'ThrowableStripeError';

    this.type = stripeErrorObject.type;
    this.charge = stripeErrorObject.charge;
    this.code = stripeErrorObject.code;
    this.declineCode = stripeErrorObject.decline_code;
    this.docUrl = stripeErrorObject.doc_url;
    this.param = stripeErrorObject.param;
    this.paymentIntent = stripeErrorObject.payment_intent;
    this.paymentMethod = stripeErrorObject.payment_method;
    this.setupIntent = stripeErrorObject.setup_intent;
    this.source = stripeErrorObject.source;
  }
}
