import { createContext } from 'react';
import { DEFAULT_LOCALE, SupportedLocale } from 'i18n/constants';

interface LocaleContextValue {
  appLocale: SupportedLocale;
  setAppLocale: (locale: SupportedLocale) => void;
}

const defaultValue: LocaleContextValue = {
  appLocale: DEFAULT_LOCALE,
  setAppLocale: () => undefined,
};

export const LocaleContext = createContext<LocaleContextValue>(defaultValue);
