export function objectToClassName(classNameObject: PartialRecord<string, boolean>): string {
  let className = '';

  Object.entries(classNameObject).forEach((entry) => {
    const [key, value] = entry;
    if (value) {
      if (className.length > 0) {
        className += ' ';
      }
      className += key;
    }
  });

  return className;
}
