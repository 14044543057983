import { StateHookSetter, useCallback, useEffect, useState } from 'react';
import { logError } from 'utils/logging';
import { parseJSONScalar } from 'utils/parseJsonScalar';
import { isNull, isUndefined } from 'typeDeclarations/typeGuards';
import { useWindowEvent } from './useWindowEvent';

interface UseLocalStorageArgs<T> {
  key: string;
  initialValue: T;
  // needed to overcome the issue of obsolete stored values
  reconcileStoredWithInitialValue?: (storedValue: T) => T;
}

export const useLocalStorage = <T>({
  key,
  initialValue,
  reconcileStoredWithInitialValue,
}: UseLocalStorageArgs<T>): [T, StateHookSetter<T>] => {
  const eventName = 'leadzai-local-storage';

  interface CustomEventDetail {
    value: T;
    key: string;
  }

  const [state, setState] = useState<T>(() => {
    try {
      const storedObject = localStorage.getItem(key);

      if (isNull(storedObject)) {
        return initialValue;
      }

      const parsedStoredValue = parseJSONScalar<T>(storedObject);

      if (isUndefined(parsedStoredValue)) {
        return initialValue;
      }

      return reconcileStoredWithInitialValue?.(parsedStoredValue) ?? parsedStoredValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setStorageValue = useCallback(
    (value: T) => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        logError(new Error(`Something failed when trying to store the key '${key}' into local storage`));
      }
      setState(value);
      // Publishes the event to all subscribers
      dispatchEvent(new CustomEvent<CustomEventDetail>(eventName, { detail: { key, value } }));
    },
    [key],
  );

  // Subscribes to changes between different document
  useWindowEvent('storage', (event) => {
    if (event.key === key) {
      const parsedStoredValue = parseJSONScalar<T>(event.newValue) ?? initialValue;
      setState(parsedStoredValue);
    }
  });

  // Subscribes to changes on the same document
  useWindowEvent(eventName, (event) => {
    const customEvent = event as CustomEvent<CustomEventDetail>;
    if (customEvent.detail.key === key) {
      setState(customEvent.detail.value);
    }
  });

  // Triggers when the state is updated and stores the value in the local storage
  useEffect(() => {
    setStorageValue(state);
  }, [state, setStorageValue]);

  return [state, setState];
};
