export enum APIErrorCode {
  MultiError = 'multi_error',
  CSRFFailed = 'csrf_failed',
  ServerError = 'server_error',
  NotLoggedIn = 'not_logged_in',
  InvalidValue = 'invalid_value',
  InvalidUrl = 'invalid_url_error',
  SignupsClosed = 'signups_closed',
  EmailNotFound = 'email_not_found',
  OperationError = 'operation_error',
  DuplicateValue = 'duplicate_value',
  AlreadyLoggedIn = 'already_logged_in',
  NonMatchingUrls = 'non_matching_urls',
  UnknownDomain = 'unknown_domain_error',
  OperationTimeout = 'operation_timeout',
  PasswordTooShort = 'password_too_short',
  InvalidImageSize = 'invalid_image_size',
  InvalidTextFields = 'invalid_text_fields',
  PasswordTooCommon = 'password_too_common',
  IncompatibleValues = 'incompatible_values',
  InvalidImageFormat = 'invalid_image_format',
  EmailAlreadyExists = 'email_already_exists',
  MaxEntriesExceeded = 'max_entries_exceeded',
  BudgetAmountTooLow = 'budget_amount_too_low',
  ModelOperationFailed = 'model_operation_failed',
  ChargeBillingProfile = 'charge_billing_profile',
  ChargeRequiresActions = 'charge_requires_action',
  MismatchedBrackets = 'mismatched_brackets_error',
  TooManyLoginAttempts = 'too_many_login_attempts',
  InvalidURLForCampaign = 'invalid_url_for_campaign',
  PaymentSourceExpired = 'payment_source_has_expired',
  InvalidImageDimensions = 'invalid_image_dimensions',
  InvalidFacebookPageUrl = 'invalid_facebook_page_url',
  PasswordEntirelyNumeric = 'password_entirely_numeric',
  InvalidLoginCredentials = 'invalid_login_credentials',
  BudgetAmountExceedesMax = 'budget_amount_exceedes_max',
  InvalidImageAspectRatio = 'invalid_image_aspect_ratio',
  InvalidValueOutsideBounds = 'invalid_value_outside_bounds',
  InvalidSymbolRepetition = 'invalid_symbol_repetition_error',
  InvalidValueStringTooLong = 'invalid_value_string_too_long',
  InvalidValueStringTooShort = 'invalid_value_string_too_short',
  InvalidKeywordTooManyWords = 'invalid_keyword_too_many_words',
  UnfulfilledPinningPositions = 'unfulfilled_pinning_positions',
  PasswordSimilarWithUserData = 'password_similar_with_user_data',
  InvalidPasswordRecoveryToken = 'invalid_password_recovery_token',
  PaymentSourceIsNotChargeable = 'payment_source_is_not_chargeable',
  CouldNotFetchFacebookPageError = 'could_not_fetch_facebook_page_error',
  CantRequestPasswordForAnotherUser = 'cant_request_password_for_another_user',
  MissingWhitespaceAfterCharacter = 'missing_whitespace_after_character_error',
  InvalidValueStringContainsInvalidCharacters = 'invalid_value_string_contains_invalid_characters',
  InvalidValueWordStartsWithInvalidCharacters = 'invalid_value_word_starts_with_invalid_characters',
  TrackingIsNotEnabledForTrackingRequiredGoal = 'tracking_is_not_enabled_for_tracking_required_goal',
  InvalidValueStringContainsExcessiveWhitespaceAroundCharacters = 'invalid_value_string_contains_excessive_whitespace_around_characters',
}
