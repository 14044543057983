import { useEffect } from 'react';

/**
 * @link {https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-window-event/use-window-event.ts}
 */
export function useWindowEvent<K extends string | keyof WindowEventMap>(
  type: K extends keyof WindowEventMap ? K : string,
  listener: K extends keyof WindowEventMap
    ? (this: Window, ev: WindowEventMap[K]) => void
    : EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions,
) {
  useEffect(() => {
    window.addEventListener(type, listener, options);
    return () => window.removeEventListener(type, listener, options);
  }, [type, listener, options]);
}
