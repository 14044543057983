export const teamsColorPalette = [
  '#FAA61A',
  '#6200EE',
  '#E78B8B',
  '#FF9B70',
  '#BB55CC',
  '#75A0D2',
  '#965CD1',
  '#4EB3BA',
  '#D26666',
  '#C76E4A',
  '#A7C437',
  '#61AA48',
  '#F55E47',
  '#E35459',
  '#68BED9',
  '#62C9C2',
  '#64866F',
];

// Assigns team color dot based on random hash matched with team database ID
export function hashNumberFromString(str: string): number {
  return parseInt(str, 16);
}
