import { getBreakdown } from 'shared/Dashboard/Breakdown/utils';
import { lazy } from 'react';
import { applyTimeDelta, dateToISO8601Date, getPreviousPeriodStartDate } from 'utils/date';
import {
  Breakdown,
  BudgetDistributionInterval,
  BudgetPerformancePeriod,
  BudgetPerformancePeriodId,
  ReportPeriod,
  Route,
  SupportedReportPeriodID,
} from './types';

const LazyOverviewPage = lazy(() =>
  import(
    /* webpackChunkName: "team-dashboard-overview-page" */
    'pages/TeamDashboard/Overview/Overview'
  ).then(({ Overview }) => ({ default: Overview })),
);

const LazyPerformancePerDayPage = lazy(() =>
  import(
    /* webpackChunkName: "team-dashboard-performance-per-day-page" */
    'pages/TeamDashboard/PerformancePerDay/PerformancePerDay'
  ).then(({ PerformancePerDay }) => ({ default: PerformancePerDay })),
);

const LazyCampaignPerformancePage = lazy(() =>
  import(
    /* webpackChunkName: "team-dashboard-campaign-performance-page" */
    'pages/TeamDashboard/CampaignPerformance/CampaignPerformance'
  ).then(({ CampaignPerformance }) => ({ default: CampaignPerformance })),
);

const LazyBudgetPerformancePage = lazy(() =>
  import(
    /* webpackChunkName: "team-dashboard-campaign-performance-page" */
    'pages/TeamDashboard/BudgetPerformance/BudgetPerformance'
  ).then(({ BudgetPerformance }) => ({ default: BudgetPerformance })),
);

const LazyCampaignSettingsPage = lazy(() =>
  import(
    /* webpackChunkName: "team-dashboard-campaign-settings-page" */
    'pages/TeamDashboard/CampaignSettings/CampaignSettings'
  ).then(({ CampaignSettings }) => ({ default: CampaignSettings })),
);

// Order matters!
export const ROUTES: Route[] = [
  {
    slug: 'overview',
    component: LazyOverviewPage,
    intlId: 'shared.overview',
  },
  {
    slug: 'performance-per-day',
    component: LazyPerformancePerDayPage,
    intlId: 'team-dashboard.performance-per-day',
  },
  {
    slug: 'campaign-performance',
    component: LazyCampaignPerformancePage,
    intlId: 'team-dashboard.campaign-performance',
  },
  {
    slug: 'budget-performance',
    component: LazyBudgetPerformancePage,
    intlId: 'team-dashboard.budget-performance',
  },
  {
    slug: 'campaign-settings',
    component: LazyCampaignSettingsPage,
    intlId: 'team-dashboard.campaign-settings',
  },
];

export const DEFAULT_BUDGET_IDS: null | string[] = null;
export const DEFAULT_SEARCH_INPUT_VALUE: string | null = null;

const today = new Date();
const currentMonth = today.getMonth();
const currentYear = today.getFullYear();
const yesterday = applyTimeDelta(today, 1);

export const REPORT_PERIODS: Record<SupportedReportPeriodID, ReportPeriod> = {
  yesterday: {
    id: 'yesterday',
    endDate: yesterday,
    startDate: yesterday,
    get previousPeriod() {
      return {
        endDate: applyTimeDelta(this.startDate, 1),
        startDate: getPreviousPeriodStartDate(this.startDate, this.endDate),
      };
    },
  },
  sevenDays: {
    id: 'sevenDays',
    endDate: yesterday,
    startDate: applyTimeDelta(today, 7),
    get previousPeriod() {
      return {
        endDate: applyTimeDelta(this.startDate, 1),
        startDate: getPreviousPeriodStartDate(this.startDate, this.endDate),
      };
    },
  },
  fourteenDays: {
    id: 'fourteenDays',
    endDate: yesterday,
    startDate: applyTimeDelta(today, 14),
    get previousPeriod() {
      return {
        endDate: applyTimeDelta(this.startDate, 1),
        startDate: getPreviousPeriodStartDate(this.startDate, this.endDate),
      };
    },
  },
  thirtyDays: {
    id: 'thirtyDays',
    endDate: yesterday,
    startDate: applyTimeDelta(today, 30),
    get previousPeriod() {
      return {
        endDate: applyTimeDelta(this.startDate, 1),
        startDate: getPreviousPeriodStartDate(this.startDate, this.endDate),
      };
    },
  },
  ninetyDays: {
    id: 'ninetyDays',
    endDate: yesterday,
    startDate: applyTimeDelta(today, 90),
    get previousPeriod() {
      return {
        endDate: applyTimeDelta(this.startDate, 1),
        startDate: getPreviousPeriodStartDate(this.startDate, this.endDate),
      };
    },
  },
  lastMonth: {
    id: 'lastMonth',
    endDate: new Date(currentYear, currentMonth, 0),
    startDate: new Date(currentYear, currentMonth - 1, 1),
    get previousPeriod() {
      return {
        endDate: applyTimeDelta(this.startDate, 1),
        startDate: getPreviousPeriodStartDate(this.startDate, this.endDate),
      };
    },
  },
  month: {
    id: 'month',
    endDate: today,
    startDate: new Date(currentYear, currentMonth, 1),
    get previousPeriod() {
      return {
        endDate: applyTimeDelta(this.startDate, 1),
        startDate: getPreviousPeriodStartDate(this.startDate, this.endDate),
      };
    },
  },
  allTimeBeforeToday: {
    id: 'allTimeBeforeToday',
    endDate: yesterday,
    startDate: null,
    get previousPeriod() {
      // backend's blessing/trick
      return {
        endDate: yesterday,
        startDate: today,
      };
    },
  },
  custom: {
    id: 'custom',
    endDate: null,
    startDate: null,
    get previousPeriod() {
      return {
        endDate: null,
        startDate: null,
      };
    },
  },
};

export const BUDGET_PERFORMANCE_PERIODS: Record<BudgetPerformancePeriodId, BudgetPerformancePeriod> = {
  current: {
    id: 'current',
    value: null,
  },
  previous: {
    id: 'previous',
    value: '0',
  },
  'two-periods-ago': {
    id: 'two-periods-ago',
    value: '1',
  },
  'three-periods-ago': {
    id: 'three-periods-ago',
    value: '2',
  },
};

export const DEFAULT_BUDGET_PERFORMANCE_PERIOD_ID: BudgetPerformancePeriodId = 'current';

export const BUDGET_FF_DISTRIBUTION_PERIODS: Record<BudgetDistributionInterval, { end: string; start: string }> = {
  last30Days: {
    end: dateToISO8601Date(today),
    start: dateToISO8601Date(applyTimeDelta(today, 30)),
  },
  last60Days: {
    end: dateToISO8601Date(today),
    start: dateToISO8601Date(applyTimeDelta(today, 60)),
  },
  last90Days: {
    end: dateToISO8601Date(today),
    start: dateToISO8601Date(applyTimeDelta(today, 90)),
  },
  last120Days: {
    end: dateToISO8601Date(today),
    start: dateToISO8601Date(applyTimeDelta(today, 120)),
  },
  last180Days: {
    end: dateToISO8601Date(today),
    start: dateToISO8601Date(applyTimeDelta(today, 180)),
  },
};

export const BUDGETS_AMOUNT = 100;
export const CAMPAIGNS_AMOUNT = 100;
export const DEFAULT_REPORT_PERIOD = REPORT_PERIODS.thirtyDays;
export const DEFAULT_BREAKDOWN: Breakdown = getBreakdown(
  DEFAULT_REPORT_PERIOD.startDate,
  DEFAULT_REPORT_PERIOD.endDate,
);
