import { ReactNode, useCallback, useContext } from 'react';
import { BetaTag } from './BetaTag';
import { AlphaTag } from './AlphaTag';
import { PublicBetaTag } from './PublicBeta';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { SessionContext } from 'components/App/session/SessionContext';
import { Feature, FEATURE_FLAGS, FeatureMap, isAlphaFeature, isBetaFeature, isPublicBetaFeature } from './constants';
import { getObjectKeys } from 'utils/getObjectKeys';

export const useFeatureFlags = () => {
  const {
    user: { isStaff },
  } = useContext(SessionContext);

  const publicBetaFeatureMap: Partial<FeatureMap> = {};

  for (const key in FEATURE_FLAGS) {
    const featureKey = key as keyof FeatureMap;
    if (isPublicBetaFeature(featureKey)) {
      publicBetaFeatureMap[featureKey] = FEATURE_FLAGS[featureKey];
    }
  }

  // The features to be shown depend if the user is staff or not
  // If the user is staff, he can see them all, if not, the user can only see public betas.
  const filteredFeatureFlags = isStaff ? FEATURE_FLAGS : publicBetaFeatureMap;

  const [featureFlags, setFeatureFlags] = useLocalStorage({
    initialValue: filteredFeatureFlags,
    key: isStaff ? 'staff-featureFlags' : 'featureFlags',
    reconcileStoredWithInitialValue: (storedValue) => {
      const mergedFeatureFlags = {} as Partial<FeatureMap>;

      getObjectKeys(FEATURE_FLAGS).forEach((feature) => {
        if (feature in filteredFeatureFlags) {
          mergedFeatureFlags[feature] = storedValue[feature] ?? filteredFeatureFlags[feature];
        }
      });

      return mergedFeatureFlags;
    },
  });

  const toggleFeature = useCallback(
    (feature: Feature) => {
      setFeatureFlags({
        ...featureFlags,
        [feature]: !featureFlags[feature],
      });
    },
    [featureFlags, setFeatureFlags],
  );

  const toggleFeatures = useCallback(
    (features: PartialRecord<Feature, boolean>) => {
      setFeatureFlags({
        ...featureFlags,
        ...features,
      });
    },
    [featureFlags, setFeatureFlags],
  );

  const isFeatureEnabled = useCallback(
    (feature: Feature) => {
      return isStaff ? featureFlags[feature] : isPublicBetaFeature(feature) && featureFlags[feature];
    },
    [featureFlags, isStaff],
  );

  const getFeatureTag = useCallback(
    (feature: Feature): ReactNode => {
      if (isBetaFeature(feature)) return <BetaTag />;
      if (isAlphaFeature(feature)) return <AlphaTag />;
      if (isPublicBetaFeature(feature)) {
        return isStaff ? <PublicBetaTag /> : <BetaTag />;
      }

      return null;
    },
    [isStaff],
  );

  return {
    featureFlags,
    getFeatureTag,
    toggleFeature,
    toggleFeatures,
    isFeatureEnabled,
  };
};
