import {
  addDays,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  formatISO,
  isAfter,
  subDays,
} from 'date-fns';
import { isNull } from 'typeDeclarations/typeGuards';

export const ISO_8601_DATE_FORMAT = 'yyyy-MM-dd';
export const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;
export const DAYS_PER_MONTH = 30;
export const DAYS_PER_YEAR = 365;

export function dateToISO8601Date(date: Date): string {
  return formatISO(date, { representation: 'date' });
}

export function addDaysToDate(date: Date, days: number): Date {
  return addDays(date, days);
}

type DateOrNull<T extends null | Date> = T extends null ? null : Date;

export function applyTimeDelta<T extends Date | null>(date: T, days: number): DateOrNull<T> {
  if (!date) return null as DateOrNull<T>;

  return subDays(date, days) as DateOrNull<T>;
}

export function getNumberOfDaysInBetweenDates(args: {
  end: Date | null;
  start: Date | null;
  includeEnd?: boolean;
}): number {
  const { end, start, includeEnd = false } = args;

  if (isNull(start) || isNull(end)) return Infinity;

  if (isAfter(start, end)) throw new Error('start must be before end');

  let result = differenceInCalendarDays(end, start);

  if (includeEnd) result++;

  return result;
}

export function getNumberOfYearsBetweenDates(start: Date | null, end: Date | null): number {
  if (isNull(start) || isNull(end)) return Infinity;
  if (isAfter(start, end)) throw new Error('start must be before end');

  return differenceInCalendarYears(end, start);
}

export function getNumberOfMonthsBetweenDates(start: Date | null, end: Date | null): number {
  if (isNull(start) || isNull(end)) return Infinity;
  if (isAfter(start, end)) throw new Error('start must be before end');

  return differenceInCalendarMonths(end, start);
}

export function getPreviousPeriodStartDate(
  currPeriodStartDate: Date | null,
  currPeriodEndDate: Date | null,
): Date | null {
  const numberOfDays = getNumberOfDaysInBetweenDates({
    includeEnd: true,
    end: currPeriodEndDate,
    start: currPeriodStartDate,
  });

  if (!isFinite(numberOfDays) || !currPeriodStartDate) {
    return null;
  }

  return applyTimeDelta(currPeriodStartDate, numberOfDays);
}
