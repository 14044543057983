import { useMutation, MutationHookOptions, gql, MutationTuple } from '@apollo/client';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

const SET_SESSION_TEAM_MUTATION = gql`
  mutation setSessionTeam($input: SetSessionTeamInput!) {
    setSessionTeam(input: $input) {
      clientMutationId
    }
  }
`;

interface SetSessionTeamMutationVariables {
  input: {
    id: string;
  };
}

export function useSetSessionTeam(
  options?: MutationHookOptions<unknown, SetSessionTeamMutationVariables>,
): MutationTuple<unknown, SetSessionTeamMutationVariables> {
  const onError = useDefaultOnError();

  return useMutation(SET_SESSION_TEAM_MUTATION, {
    onError: (err) => onError(err),
    ...options,
  });
}
