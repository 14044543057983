import { gql } from '@apollo/client';

import { ALL_CURRENCIES_FRAGMENT } from 'graphql/fragments';
import { NonPaginatedConnection } from 'typeDeclarations/graphql/base-types';
import { CurrencyNode, BillingProfileNode } from 'typeDeclarations/graphql/nodes';

export const TOPUP_INPUT_BILLING_PROFILE_FRAGMENT = gql`
  fragment topupInputBillingProfileFragment on BillingProfileNode {
    autoTopupAmount
    autoTopupThresholdAmount
    balance
    currency
    isCurrencyMutable
  }
`;

export type TopupInputBillingProfileNode = Pick<
  BillingProfileNode,
  'autoTopupAmount' | 'autoTopupThresholdAmount' | 'balance' | 'currency' | 'isCurrencyMutable'
>;

export const TOPUP_INPUT_CURRENCIES_FRAGMENT = gql`
  fragment topupInputCurrenciesFragment on Query {
    ...allCurrenciesFragment
  }
  ${ALL_CURRENCIES_FRAGMENT}
`;

export type TopupInputCurrencies = NonPaginatedConnection<
  Pick<CurrencyNode, '__typename' | 'id' | '_id' | 'selectable' | 'symbol' | 'decimalPlaces'>
>;
