import { SupportedLocale } from 'i18n/constants';

import enUSMessages from './en-US';
import frBEMessages from './fr-BE';
import nlNLMessages from './nl-NL';
import ptPTMessages from './pt-PT';
import esESMessages from './es-ES';
import deDEMessages from './de-DE';

export const i18n: Record<SupportedLocale, Record<string, string>> = {
  'en-US': enUSMessages,
  'fr-BE': frBEMessages,
  'nl-NL': nlNLMessages,
  'pt-PT': ptPTMessages,
  'es-ES': esESMessages,
  'de-DE': deDEMessages,
};
