import { QueryHookOptions, QueryResult, useQuery, gql } from '@apollo/client';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

const GET_AMOUNT_WITH_VAT = gql`
  query getAmountWithVat($amount: DecimalScalar) {
    getVatAmount(amount: $amount) {
      amountWithVat
    }
  }
`;

interface GetAmountWithVatData {
  getVatAmount: {
    amountWithVat: string;
  };
}

interface GetAmountWithVatVariables {
  amount?: string;
}

export function useGetAmountWithVat(
  options?: QueryHookOptions<GetAmountWithVatData, GetAmountWithVatVariables>,
): QueryResult<GetAmountWithVatData, GetAmountWithVatVariables> {
  const onError = useDefaultOnError();

  return useQuery<GetAmountWithVatData, GetAmountWithVatVariables>(GET_AMOUNT_WITH_VAT, {
    onError,
    ...options,
  });
}
