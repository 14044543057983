import { isString } from 'typeDeclarations/typeGuards';
import { logError } from './logging';

// FIXME: The errors thrown in this file mention "setting value". However, this method isn't used for app settings
//  only, and it might be misleading.

// The JsonScalar scalar type represents any JSON-serializable object as a string.
export function parseJSONScalar<T>(value: unknown): T | undefined {
  if (!isString(value)) {
    logError(new Error(`Setting value '${JSON.stringify(value)}' must be of type string`));
    return undefined;
  }

  try {
    return JSON.parse(value) as T;
  } catch (e) {
    logError(new Error(`Could not parse setting value '${value}'`));
    return undefined;
  }
}
