const ptPTMessages: Record<string, string> = {
  'error-loading-page.text':
    'Uh-oh, parece que não conseguimos carregar esta secção. Pedimos desculpa, pode tentar novamente?',
  'error-loading-page.button': 'Tente novamente',
  'shared.something-went-wrong-message': 'Ooops!{br}Something went wrong!',
  'shared.something-went-wrong-description':
    'Não conseguimos encontrar essa ligação na aplicação.{br}Por favor, verifique se existem erros de digitação ou volte ao painel de controlo da campanha.',
};

export default ptPTMessages;
