import { AdvertisersLayoutValue, AdvertisersSortValue, AdvertisersTableRowsPerPageValue } from './types';

export const ADVERTISERS_MINIMUM_TO_TABLE_LAYOUT = 10;
export const ADVERTISERS_URL_DESTINATON = '/businesses';

export const ADVERTISERS_DEFAULT_LABEL_VALUES: string[] = [];
export const ADVERTISERS_DEFAULT_SEARCH_VALUE: string | null = null;
export const ADVERTISERS_DEFAULT_ORDER: AdvertisersSortValue = '-created';
export const ADVERTISERS_DEFAULT_LAYOUT: AdvertisersLayoutValue | null = null;

export const ADVERTISERS_TABLE_DEFAULT_ROWS_PER_PAGE: AdvertisersTableRowsPerPageValue = 10;
export const ADVERTISERS_TABLE_ROWS_PER_PAGE_OPTIONS: AdvertisersTableRowsPerPageValue[] = [10, 20, 30];
export const ADVERTISERS_PAGE_SIZE =
  ADVERTISERS_TABLE_ROWS_PER_PAGE_OPTIONS[ADVERTISERS_TABLE_ROWS_PER_PAGE_OPTIONS.length - 1];

export const ADVERTISERS_SORT_VALUES: AdvertisersSortValue[] = ['name', '-name', 'created', '-created'];
