import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { FunctionComponent, useState, useCallback, ChangeEvent, useEffect, KeyboardEventHandler } from 'react';

import { InputAdornment, IconButton, TextField, TextFieldProps } from '@mui/material';

interface SearchInputProps extends Omit<TextFieldProps, 'type' | 'onChange' | 'value'> {
  value?: string;
  onClear?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const SearchInput: FunctionComponent<SearchInputProps> = ({
  value,
  onClear,
  onChange,
  onKeyDown,
  sx,
  ...rest
}) => {
  const [innerValue, setInnerValue] = useState<string>(value || '');

  useEffect(() => {
    setInnerValue(value ?? '');
  }, [value, setInnerValue]);

  const clear = useCallback(() => {
    setInnerValue('');

    if (onClear) {
      onClear();
    }
  }, [onClear]);

  const handleInnerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: eventValue } = event.target;
      setInnerValue(eventValue);

      if (onChange) {
        onChange(event, eventValue);
      }
    },
    [onChange],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const currentTarget = event.currentTarget;

    currentTarget.value = innerValue;

    if (onKeyDown) {
      onKeyDown({
        ...event,
        currentTarget,
      });
    }
  };

  return (
    <TextField
      fullWidth
      value={innerValue}
      variant="outlined"
      onKeyDown={handleKeyDown}
      onChange={handleInnerChange}
      sx={{ backgroundColor: 'background.paper', ...sx }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: innerValue && (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              size="small"
              onClick={clear}
              aria-label="clear search"
              onMouseDown={(e) => e.preventDefault()}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
