import {
  CampaignsSortValue,
  CampaignsLayoutValue,
  CampaignsFilterLabel,
  CampaignsTableRowsPerPageValue,
} from './types';

export const CAMPAIGNS_MINIMUM_TO_OVERVIEW = 2;
export const CAMPAIGNS_MINIMUM_TO_TABLE_LAYOUT = 10;
export const CAMPAIGNS_URL_DESTINATON = '/campaigns';

export const CAMPAIGNS_DEFAULT_LABEL_VALUES: string[] = [];
export const CAMPAIGNS_DEFAULT_SEARCH_VALUE: string | null = null;
export const CAMPAIGNS_DEFAULT_ADVERTISER_ID: string | null = null;
export const CAMPAIGNS_DEFAULT_ORDER: CampaignsSortValue = '-created';
export const CAMPAIGNS_DEFAULT_LAYOUT: CampaignsLayoutValue | null = null;
export const CAMPAIGNS_DEFAULT_FILTER_VALUES: CampaignsFilterLabel[] = [];

export const CAMPAIGNS_TABLE_DEFAULT_ROWS_PER_PAGE: CampaignsTableRowsPerPageValue = 10;
export const CAMPAIGNS_TABLE_ROWS_PER_PAGE_OPTIONS: CampaignsTableRowsPerPageValue[] = [10, 20, 30];
export const CAMPAIGNS_PAGE_SIZE =
  CAMPAIGNS_TABLE_ROWS_PER_PAGE_OPTIONS[CAMPAIGNS_TABLE_ROWS_PER_PAGE_OPTIONS.length - 1];

export const CAMPAIGNS_SORT_VALUES: CampaignsSortValue[] = ['name', '-name', 'created', '-created'];
